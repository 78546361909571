const CONST_VALIABLE = {
  WSUS_ORG: '7xxx2x'
}

function GetConstValue (arg) {
  return CONST_VALIABLE[arg]
}

export {
  GetConstValue
}
