<template>
  <v-app id="inspire">
    <!--v-main-->
      <!--div>
        <h1>TOPページ</h1>
      </div-->

      <!--
      <v-card width="400px" class="mx-auto mt-5">
        <v-card-text>
          <v-form>
            <v-card-actions>
              <v-btn class="info" @click="submit">リロード</v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
      -->
      <!--div>
        <h2>お知らせ</h2>
        <div class='maintenance'>
          hoge<br>
          hoge
        </div>
      </div-->

      <div v-show='isTopInfo'>

        <v-divider class='my-6' />

        <div>
          <h2>{{ title_saf_err }}
            <span><v-icon color='red'>mdi-close-circle</v-icon></span>
            <v-btn class='ml-5' color="normal" :disabled='isLoading_saf_err' v-on:click="getRestApiDevice('Saf')">更新</v-btn>
          </h2>
        </div>
        <div class='err_msg' v-show='error_message_saf_err'>
          {{ error_message_saf_err }}
        </div>

        <div v-show="!isLoading_saf_err & error_message_saf_err==''">
          <div class="device_list">
            <v-card-title>
              <v-text-field
                v-model="search1"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                dense
                ></v-text-field>
            </v-card-title>
            <v-data-table
              dense
              :headers="t_header_saf_err"
              :items="device_saf_err"
              :search="search1"
              @click:row="onClickDeviceCell"
            >
            </v-data-table>
          </div>
        </div>

        <!--br v-for='n of 5' :key='`saf-${n}`' v-show="isLoading_saf_err" /-->
        <loading ref="load_saf_err" v-show="isLoading_saf_err" />

        <v-divider class='my-6' />

        <div>
          <h2>{{ title_his_err }}
            <span><v-icon color='red'>mdi-close-circle</v-icon><v-icon color='yellow'>mdi-alert</v-icon></span>
            <v-btn class='ml-5' color="normal" :disabled='isLoading_his_err' v-on:click="getRestApiDevice('His')">更新</v-btn>
          </h2>
        </div>
        <div class='err_msg' v-show='error_message_his_err'>
          {{ error_message_his_err }}
        </div>

        <div v-show="!isLoading_his_err & error_message_his_err==''">
          <div class="device_list">
            <v-card-title>
              <v-text-field
                v-model="search2"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                dense
                ></v-text-field>
            </v-card-title>
            <v-data-table
              dense
              :headers="t_header_his_err"
              :items="device_his_err"
              :search="search2"
              @click:row="onClickDeviceCell"
            >
            </v-data-table>
          </div>
        </div>

        <!--br v-for='n of 5' :key='`his-${n}`' v-show="isLoading_his_err" /-->
        <loading ref="load_his_err" v-show="isLoading_his_err" />

        <v-divider class='my-6' />

        <div>
          <h2>{{ title_pkg_err }}
            <span><v-icon color='red'>mdi-close-circle</v-icon><v-icon color='yellow'>mdi-alert</v-icon></span>
            <v-btn class='ml-5' color="normal" :disabled='isLoading_pkg_err' v-on:click="getRestApiPackage()">更新</v-btn>
          </h2>
        </div>
        <div class='err_msg' v-show='error_message_pkg_err'>
          {{ error_message_pkg_err }}
        </div>

        <div v-show="!isLoading_pkg_err & error_message_pkg_err==''">
          <div class="device_list">
            <v-card-title>
              <v-text-field
                v-model="search3"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                dense
                ></v-text-field>
            </v-card-title>
            <v-data-table
              dense
              :headers="t_header_pkg_err"
              :items="package_err"
              :search="search3"
              @click:row="onClickPackageCell"
            >
            </v-data-table>
          </div>
        </div>

        <!--br v-for='n of 5' :key='`pkg-${n}`' v-show="isLoading_pkg_err" /-->
        <loading ref="load_pkg_err" v-show="isLoading_pkg_err" />

      </div>

    <!--/v-main-->
  </v-app>
</template>

<script>
import axios from 'axios'
import Loading from '@/components/Loading-Rel'
import { getPhsInfo } from '@/components/common/PackageUtility.js'
import rmtlib from '@/plugins/class/rmtlib'

export default {
  components: {
    Loading
  },
  data: () => ({
    isTopInfo: true,
    title_saf_err: '機器一覧(死活ステータス：異常)',
    title_his_err: '機器一覧(パッケージログ：異常／警告)',
    title_pkg_err: 'パッケージ一覧(処理ステータス：異常／警告)',
    isLoading_saf_err: true,
    isLoading_his_err: true,
    isLoading_pkg_err: true,
    search1: '',
    search2: '',
    search3: '',
    t_header_saf_err: [
      { text: 'サーバ種別', value: 'host_guest' },
      { text: '契約番号', value: 'contract_name' },
      { text: '型番', value: 'model_number' },
      { text: 'シリアル番号', value: 'serial_number' },
      { text: 'ホスト名', value: 'hostname' },
      { text: '死活ステータス', value: 'saf' }
    ],
    device_saf_err: [],
    t_header_his_err: [
      { text: 'サーバ種別', value: 'host_guest' },
      { text: '契約番号', value: 'contract_name' },
      { text: '型番', value: 'model_number' },
      { text: 'シリアル番号', value: 'serial_number' },
      { text: 'ホスト名', value: 'hostname' },
      { text: 'ID', value: 'pkg_id' },
      { text: '説明', value: 'message' }
    ],
    device_his_err: [],
    t_header_pkg_err: [
      { text: 'ID', value: 'pkg_id' },
      { text: '名称', value: 'pkg_name' },
      { text: '登録日', value: 'registration_datetime' },
      { text: '処理ステータス', value: 'phs' }
    ],
    package_err: [],

    error_message_saf_err: '',
    error_message_his_err: '',
    error_message_pkg_err: ''
  }),
  mounted: function () {
    // console.log('Top:mounted')
    // console.log(this.$store.getters.user.orgid)

    // WSUS組織ではTOPを表示しない
    if (rmtlib.isWsusOrg(this.$store.getters.user.orgid)) {
      this.$router.replace('/Wsus')
      return
    }
    // スーパーユーザー組織の場合は表示しない
    if (this.$store.getters.user.role[0] && (this.$store.getters.user.orgid.slice(0, 2) === '80')) {
      console.log('list is nothing.')
      this.isTopInfo = false
      this.isLoading_saf_err = false
      this.isLoading_his_err = false
      this.isLoading_pkg_err = false
      return
    }

    this.$nextTick(function () {
      // this.getRestApiDeviceErr()
      setTimeout(this.getRestApiDevice, 500, 'Saf')
      setTimeout(this.getRestApiDevice, 500, 'His')
      setTimeout(this.getRestApiPackage, 500)
    })
  },
  methods: {

    onClickDeviceCell: function (data) {
      this.$router.push({ name: 'DeviceDetail', params: { id: data.machine_id } })
    },
    onClickPackageCell: function (data) {
      this.$router.push({ name: 'PackageDetail', params: { id: data.pkg_id } })
    },

    getRestApiDevice: function (List) {
      let safParam = ''
      let pkgParam = ''
      if (List === 'Saf') {
        this.error_message_saf_err = ''
        this.isLoading_saf_err = true
        safParam = 'abnormal'
      } else if (List === 'His') {
        this.error_message_his_err = ''
        this.isLoading_his_err = true
        pkgParam = 'error'
      }

      axios.get('/machines',
        {
          headers: { Authorization: this.$store.getters.user.token },
          params: { org: this.$store.getters.user.orgid, saf: safParam, package: pkgParam }
        }
      ).then((res) => {
        // console.log(res)
        // console.log(res.data)
        if (List === 'Saf') {
          this.device_saf_err = res.data
          this.isLoading_saf_err = false
        } else if (List === 'His') {
          this.device_his_err = res.data
          this.isLoading_his_err = false
        }
      }).catch((error) => {
        console.log(error)
        // alert(error)
        // alert('情報の取得に失敗しました。')
        if (List === 'Saf') {
          this.error_message_saf_err = '情報の取得に失敗しました。'
          this.isLoading_saf_err = false
        } else if (List === 'His') {
          this.error_message_his_err = '情報の取得に失敗しました。'
          this.isLoading_his_err = false
        }
      })
    },
    getRestApiPackage: function () {
      this.isLoading_pkg_err = true
      this.error_message_pkg_err = ''

      axios.get('/packages',
        {
          headers: { Authorization: this.$store.getters.user.token },
          params: { org: this.$store.getters.user.orgid, filter: 'error' }
        }
      ).then((res) => {
        // console.log(res)
        // console.log(res.data)
        this.package_err = res.data
        this.package_err.forEach(err => {
          // console.log(err)
          // console.log(err.phs)
          // console.log(getPhsInfo(err.phs).phsInfo.action_status)
          err.phs = getPhsInfo(err.phs).phsInfo.action_status
        })
        this.isLoading_pkg_err = false
      }).catch((error) => {
        console.log(error)
        // alert(error)
        // alert('情報の取得に失敗しました。')
        this.error_message_pkg_err = '情報の取得に失敗しました。'
        this.isLoading_pkg_err = false
      })
    }
    // submit () {
    //   // console.log('reload')
    //   location.reload()
    // }
  }
}
</script>
<style scoped>
.maintenance { background-color:#d7d8e2; border: 0px #808080 solid; margin:10px; padding: 5px;}
.err_msg { background-color:#d7d8e2; border: 0px #808080 solid; margin:10px; padding: 5px; color: red}
</style>
