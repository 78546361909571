var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}]},[_c('div',[_c('h1',{attrs:{"id":"id_title1"}},[_vm._v(_vm._s(_vm.title))])]),_c('div',[_c('div',[_c('v-simple-table',{attrs:{"id":"user_l1"}},[_c('tbody',{attrs:{"id":"id_tbody_user_notedit"}},[_c('tr',[_c('th',{attrs:{"id":"id_th_user"}},[_vm._v("ユーザID")]),_c('td',{attrs:{"id":"id_td_user"}},[_vm._v(" "+_vm._s(_vm.user_info.user_id)+" ")])]),_c('tr',[_c('th',{attrs:{"id":"id_th_role"}},[_vm._v("変更前ロール")]),_c('td',{attrs:{"id":"id_td_role"}},[_vm._v(" "+_vm._s(_vm.save_superuser ? 'スーパーユーザ' : '')+" "+_vm._s((_vm.save_superuser && _vm.save_admin) ? ' ' : '')+" "+_vm._s(_vm.save_admin ? '管理者' : '')+" "+_vm._s(((_vm.save_superuser || _vm.save_admin) && _vm.save_operator) ? ' ' : '')+" "+_vm._s(_vm.save_operator ? '作業者' : '')+" ")])])])]),_c('br'),_c('v-simple-table',{attrs:{"id":"user_l2"}},[_c('tbody',{attrs:{"id":"id_tbody_user_edit"}},[_c('tr',[_c('th',{attrs:{"id":"id_th_name"}},[_vm._v("ユーザ名")]),_c('td',{attrs:{"id":"id_td_name"}},[_c('v-text-field',{attrs:{"placeholder":"","label":"ユーザの姓名を入力","rules":[
                    _vm.required,
                    _vm.limit_length100
                  ]},model:{value:(_vm.user_info.name),callback:function ($$v) {_vm.$set(_vm.user_info, "name", $$v)},expression:"user_info.name"}})],1)]),_c('tr',[_c('th',{attrs:{"id":"id_th_department"}},[_vm._v("所属")]),_c('td',{attrs:{"id":"id_td_department"}},[_c('v-text-field',{attrs:{"placeholder":"","label":"所属を入力","rules":[
                    _vm.limit_length300
                  ]},model:{value:(_vm.user_info.department),callback:function ($$v) {_vm.$set(_vm.user_info, "department", $$v)},expression:"user_info.department"}})],1)]),_c('tr',[_c('th',{attrs:{"id":"id_th_email"}},[_vm._v("メールアドレス")]),_c('td',{attrs:{"id":"id_td_email"}},[_c('v-text-field',{attrs:{"placeholder":"","label":"メールアドレスを入力","rules":[
                    _vm.required,
                    _vm.limit_length254,
                    _vm.emailRules
                  ]},model:{value:(_vm.user_info.email),callback:function ($$v) {_vm.$set(_vm.user_info, "email", $$v)},expression:"user_info.email"}})],1)]),(!_vm.is_superuser_orgid_80xxxx && !_vm.is_wsususer_orgid_7xxx2x)?_c('tr',[_c('th',[_vm._v("ロール")]),_c('td',[_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"label":"管理者"},model:{value:(_vm.user_info.admin),callback:function ($$v) {_vm.$set(_vm.user_info, "admin", $$v)},expression:"user_info.admin"}})],1),_c('v-col',[_c('v-checkbox',{attrs:{"label":"作業者"},model:{value:(_vm.user_info.operator),callback:function ($$v) {_vm.$set(_vm.user_info, "operator", $$v)},expression:"user_info.operator"}})],1),_c('v-col',{attrs:{"align-self":"center"}},[_vm._v("いずれかまたは"),_c('br'),_vm._v("両方にチェック")]),_c('v-col')],1)],1)]):_vm._e()])])],1),_c('div',[_c('br'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.edit_regist_}},[_vm._v("変更")]),_c('a',[_vm._v(" ")]),_c('v-btn',{attrs:{"color":"normal"},on:{"click":_vm.back_}},[_vm._v("中止")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }