<template>
  <div>

    <loading v-show="isLoading" />
    <div v-show="!isLoading">
      <div>
        <h1 v-if="isEditPage">{{ title_edit }}</h1>
        <h1 v-else>{{ title_reg }}</h1>
      </div>
      <!--v-divider /-->

      <div>
        <v-form ref="contract_form">
        <v-simple-table id="contract_edit" dense>
        <tbody>
          <tr v-if='false'><th>契約ステータス</th><td>
          <v-switch
            v-model="contract_info.enable"
            inset
            color="indigo"
            :label="`${valid_status[contract_info.enable].toString()}`">
            </v-switch>
          </td></tr>
          <tr><th>契約番号</th>
            <td v-bind:class="{ 'not_editable' : isEditPage }">
            <v-text-field
              v-model="contract_info.name"
              placeholder=""
              label=""
              :rules="validate_contract_name"
              :readonly="isEditPage"
              />
            </td>
          </tr>
          <tr><th>契約種別</th><td v-bind:class="{ 'not_editable' : isEditPage }">
          <v-select
            v-model="type"
            :items="selectable_type"
            :reduce="selectable_type => selectable_type.type"
            item-text="type_name"
            item-value="type"
            label=""
            :rules="[required]"
            dense
            :readonly="isEditPage"
          />
          </td></tr>
          <tr v-if="(type === 'patch')"><th>エッジサーバ管理テナント(テナントID)</th><td class="not_editable">
          <v-select
            v-model="contract_info.host_organization_id"
            :items="organizations"
            :reduce="organizations => organizations.organization_id"
            item-text="organization_name"
            item-value="organization_id"
            label=""
            :rules="[required]"
            dense
            readonly
          />
          </td></tr>
          <tr v-if="(type === 'patch')"><th>仮想化基盤管理テナント(テナントID)</th><td v-bind:class="{ 'not_editable' : isEditPage }">
          <v-select
            v-model="contract_info.guest_organization_id"
            :items="selectable_organizations"
            :reduce="selectable_organizations => selectable_organizations.organization_id"
            item-text="organization_name"
            item-value="organization_id"
            label=""
            :rules="[required]"
            dense
            :readonly="isEditPage"
          />
          </td></tr>
          <tr v-if="(type === 'patch')"><th>エッジサーバ通報先メールアドレス</th><td>
            <v-text-field
              name="note"
              v-model="contract_info.host_email"
              placeholder=""
              label=""
              :rules="rules_email"
              />
          </td></tr>
          <tr v-if="(type === 'patch')"><th>仮想化基盤通報先メールアドレス</th><td>
            <v-text-field
              name="note"
              v-model="contract_info.guest_email"
              placeholder=""
              label=""
              :rules="rules_email"
              />
          </td></tr>
          <tr v-if="(type === 'appliance')"><th>管理テナント(テナントID)</th><td class="not_editable">
          <v-select
            v-model="contract_info.host_organization_id"
            :items="organizations"
            :reduce="organizations => organizations.organization_id"
            item-text="organization_name"
            item-value="organization_id"
            label=""
            :rules="[required]"
            dense
            readonly
          />
          </td></tr>
          <tr v-if="(type === 'appliance')"><th>連絡先メールアドレス</th><td>
            <v-text-field
              name="note"
              v-model="contract_info.host_email"
              placeholder=""
              label=""
              :rules="rules_email"
              />
          </td></tr>
        </tbody>
        </v-simple-table>
        </v-form>
      </div>
      <div>
        <v-btn v-if="btns.update && isEditPage" color="primary" v-on:click="update_reg_">変更</v-btn>
        <v-btn v-if="btns.reg && !isEditPage && this.$store.getters.user.role[0]" color="primary" v-on:click="update_reg_">登録</v-btn>
        <a>&nbsp;</a>
        <v-btn v-show="btns.back" color="normal" v-on:click="back_">中止</v-btn>
      </div>
      <div>
        {{ error_message }}
      </div>
    </div>

  </div>
</template>

<script>

import axios from 'axios'
import Loading from '@/components/Loading'

export default {
  components: {
    Loading
  },
  name: 'ContractEditView',
  data: () => ({
    title_edit: '契約詳細(編集)',
    title_reg: '契約(登録)',
    isLoading: true,
    error_message: '',

    valid_status: { true: '有効', false: '無効', undefined: '不明' },
    // API /contracts/{id}
    contract_info: {},
    // API /organizations
    organizations: [],
    // 選択可能な組織(スーパーユーザ組織/選択中の組織を除外)
    selectable_organizations: [],
    // 選択可能な契約種別
    type: 'patch',
    selectable_type: [
      { type_name: 'シングルサイトマネジメント版', type: 'patch' },
      { type_name: 'マルチサイトマネジメント版', type: 'appliance' }
    ],
    btns: {
      back: true,
      reg: false,
      update: false
    },

    required: value => !!value || '入力してください',
    validate_contract_name: [
      value => !!value || '入力してください',
      value => (value || '').length <= 50 || '50文字まで'
    ],
    rules_email: [
      value => !!value || '入力してください',
      value => (value || '').length <= 254 || '254文字まで',
      value => {
        // const pattern = /^([A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,})(\s*[;]\s*([A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,})){0,2}\s*[;]*\s*$/
        const pattern = /^((([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))(\s*[;]\s*((([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))){0,2}\s*[;]*\s*$/
        return pattern.test(value) || '入力が正しくありません'
      }
    ],
    isSelectedOrg: true
  }),
  props: {
    isEditPage: {
      type: Boolean,
      default: true
    }
  },

  beforeCreate: function () {
  },

  created: function () {
    this.permission = (((this.$store.getters.user.role[0]) && (this.$store.getters.user.orgid.slice(0, 2) !== '80')) || this.$store.getters.user.role[1])
  },

  beforeMount: function () {
  },

  mounted: function () {
    if (!this.permission) {
      console.log('contract permission denied.')
      return
    }
    this.$nextTick(function () {
      this.getRestApiContract()
      this.getRestApiOrganization()
    })
  },

  beforeUpdate: function () {
  },

  updated: function () {
  },

  beforeDestroy: function () {
  },

  destroyed: function () {
  },

  watch: {
    contract_info: {
      handler: function () {
        // console.log('watch contract_info')
        // ホスト契約のみ編集可能とする
        if (this.$store.getters.user.role[0] &&
            (this.$store.getters.user.orgid === this.contract_info.host_organization_id)) {
          this.btns.update = true
          this.btns.reg = true
        }
      },
      deep: true
    },
    organizations: {
      handler: function () {
        // console.log('watch organization')
      },
      deep: true
    }
  },

  methods: {
    setType: function () {
      this.contract_info.contract_type = this.type
    },
    getRestApiContract: function () {
      if (this.isEditPage) {
        axios.get('/contracts/' + `${this.$route.params.id}`,
          {
            headers: { Authorization: this.$store.getters.user.token },
            params: { id: this.$store.getters.user.orgid }
          }
        ).then((res) => {
          this.contract_info = res.data
          // 契約種別を設定
          this.type = this.contract_info.contract_type
          this.isLoading = false
        }).catch((error) => {
          console.log(error)
          // alert(error)
          alert('契約情報の取得に失敗しました。')
          // this.error_message += '[GET /contracts/id]: Failed, ' + `${this.$route.params.id}` + '\n'
          this.isLoading = false
        })
      } else {
        this.contract_info = {
          contract_number: '',
          name: '',
          host_organization_id: '',
          guest_organization_id: '',
          enable: true,
          model: '',
          serial: '',
          update_datetime: '',
          update_user_id: '',
          host_email: '',
          guest_email: '',
          seq_id: ''
        }
        if (this.$store.getters.user.orgid === undefined) {
          this.isSelectedOrg = false
          this.error_message = '操作者の組織が設定されていません。' + '\n'
        } else {
          this.contract_info.host_organization_id = this.$store.getters.user.orgid
        }
        this.isLoading = false
      }
    },
    getRestApiOrganization: function () {
      // 組織情報はスーパーユーザのみ取得
      if (!this.$store.getters.user.role[0]) {
        return
      }
      axios.get('/organizations',
        {
          headers: { Authorization: this.$store.getters.user.token }
        }
      ).then((res) => {
        this.organizations = res.data
        this.organizations.forEach(org => {
          org.organization_name = `${org.organization_name}(${org.organization_id})`
        })
        // 選択中の組織とユーパーユーザ組織を除外
        this.selectable_organizations = this.organizations
        this.selectable_organizations = this.selectable_organizations.filter((elm) => {
          return (elm.organization_id.slice(0, 2) !== '80')
        })
        if (!this.isEditPage) {
          this.selectable_organizations = this.selectable_organizations.filter((elm) => {
            return (elm.organization_id !== this.$store.getters.user.orgid)
          })
        }
        /*
        if (!this.isEditPage) {
          return
        }
        var err = false
        if (!this.organizations.some(elm => elm.organization_id === this.contract_info.host_organization_id)) {
          err = true
          this.organizations.push({ organization_id: this.contract_info.host_organization_id, organization_name: this.contract_info.host_organization_id, seq_id: '' })
        }
        if (!this.organizations.some(elm => elm.organization_id === this.contract_info.guest_organization_id)) {
          err = true
          this.organizations.push({ organization_id: this.contract_info.guest_organization_id, organization_name: this.contract_info.guest_organization_id, seq_id: '' })
        }
        if (err) {
          this.error_message = '存在しない組織が設定されてます\n'
        }
        */
      }).catch((error) => {
        console.log(error)
        // alert(error)
        alert('組織情報の取得に失敗しました。')
        // this.error_message = '[GET /organizations]: Failed' + '\n'
      })
    },

    // 契約の操作、戻る、登録、編集
    back_: function () {
      if (this.isEditPage) {
        this.$router.push({ name: 'ContractDetail', params: { id: this.$route.params.id } })
      } else {
        this.$router.push({ name: 'Contract' })
      }
    },
    update_reg_: function () {
      if (this.type === 'appliance') {
        // 仮想アプラでは表示しない項目にダミーを設定する。
        // バックエンドでguest_organization_idを強制的に'None'と設定している。
        this.contract_info.guest_organization_id = 'None'
        this.contract_info.guest_email = 'a@b.com'
      }
      if (!this.$refs.contract_form.validate()) {
        return
      }
      const msg = this.isEditPage ? '変更しますか？' : '登録しますか？'
      if (!confirm(msg)) {
        return
      }
      this.isLoading = true
      this.setType()
      this.setRestApiContract()
    },
    setRestApiContract: function () {
      let url = '/contracts'
      let method = 'post'
      if (this.isEditPage) {
        url += '/' + `${this.$route.params.id}`
        method = 'put'
      }
      axios.request({
        method: method,
        url: url,
        headers: { Authorization: this.$store.getters.user.token },
        data: this.contract_info
      }).then((res) => {
        // alert('登録しました。：契約番号(' + res.data.name + ')')
        this.$router.push({ name: 'ContractDetail', params: { id: res.data.contract_number } })
        this.isLoading = false
      }).catch((error) => {
        const postStatus = error.response.status
        if (postStatus === 400) {
          const postError = error.response.data
          if (postError.status === -2) {
            alert('既に存在する契約番号です。')
          } else {
            console.log(error)
            alert('組織情報の設定に失敗しました。')
          }
        } else {
          console.log(error)
          alert('組織情報の設定に失敗しました。')
          // this.error_message = '[' + method.toUpperCase() + ' /contracts/id]: Failed, ' + this.contract_info.contract_number + '\n'
        }
        this.isLoading = false
      })
    }

  }

}
</script>

<style scoped>
#contract_edit table{ width:100%; }
#contract_edit th{ min-width:180px; max-width:50px; background-color:#dcdff1; border: 1px #808080 solid; }
#contract_edit td{ border: 1px #808080 solid; text-align:left;}

.not_editable{ background-color: #cccccc !important;}

</style>
