import { GetConstValue } from '@/plugins/const.js'

export default class rmtlib {
  static isWsusOrg (orgid) {
    var tmpOrgid = orgid.slice(0, 1) + 'xxx' + orgid.slice(4, 5) + 'x'
    // console.log(GetConstValue('WSUS_ORG'))
    if (tmpOrgid === GetConstValue('WSUS_ORG')) {
      return true
    }
    return false
  }
}
