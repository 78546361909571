<template>
  <div>

    <div v-show="!isLoading">

      <div class="sublist_window">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          dense
          :headers="t_headers"
          :items="t_data"
          :search="search"
          :custom-sort="custom_sort_pkg_history_importance"
          :sort-by="sort_by"
          :sort-desc="[true]"
        >
          <!--template v-slot:item.phs="{ item }">
            <span v-if='item.phs=="パッケージ作成完了"'><v-icon color='gray'>mdi-cloud-upload</v-icon></span>
            <span v-else-if='item.phs=="パッケージ転送開始待ち"'><v-icon color='gray'>mdi-cloud-upload</v-icon></span>
            <span v-else-if='item.phs=="パッケージ転送中"'><v-icon color='gray'>mdi-transfer</v-icon></span>
            <span v-else-if='item.phs=="パッケージ転送成功"'><v-icon color='green'>mdi-check-circle</v-icon></span>
            <span v-else-if='item.phs=="パッケージ転送失敗"'><v-icon color='red'>mdi-close-circle</v-icon></span>
            <span v-else><v-icon color='gray'>mdi-dots-horizontal-circle</v-icon></span>
            {{ item.phs }}
          </template-->

          <template v-slot:item.importance="{ item }">
            <span v-if='item.importance=="エラー"'><v-icon color='red'>mdi-close-circle</v-icon></span>
            <span v-else-if='item.importance=="警告"'><v-icon color='yellow'>mdi-alert</v-icon></span>
            <span v-else-if='item.importance=="情報"'><v-icon color='blue'>mdi-information</v-icon></span>
            <span v-else><v-icon color='gray'>mdi-dots-horizontal-circle</v-icon></span>
            {{ item.importance}}
          </template>

          <template v-slot:item.category="{ item }">
            <span v-if='item.category=="" || item.category==undefined'>-</span>
            <span v-else>{{ item.category }}</span>
          </template>

        </v-data-table>
      </div>

    </div>

    <br v-for='n of 5' :key='n' v-show="isLoading" />
    <loading v-show="isLoading" />

  </div>
</template>

<script>
import axios from 'axios'
import Loading from '@/components/Loading'

export default {
  components: {
    Loading
  },
  name: 'WsusDeviceDetailSubView',
  data: () => ({
    title: '',
    isLoading: true,
    error_message: '',
    search: '',
    sort_by: ['pkg_id'], // pkg_id or datetime
    sort_desc: [true], // 降順
    t_headers: [
      {
        align: 'start',
        filterable: true,
        text: 'ID',
        value: 'id'
      },
      { text: 'データ1', value: 'data1' },
      { text: 'データ2', value: 'data2' }
    ],
    t_data: [
      // { id: '1', data1: 'name', data2: 'value' }
    ],

    pkg_header: [
      { text: 'ID', value: 'pkg_id' },
      { text: '名称', value: 'pkg_name' },
      { text: '登録日時', value: 'registration_datetime' }
    ],
    pkg_data: [
      // { category: 'QU', pkg_id: '00000482', pkg_name: '2021年09月QU', registration_datetime: '2021-09-21T13:21:55+09:00', state: 'PHS-20', phs: 'パッケージ作成完了' }, { category: '日時', pkg_id: '00000483', pkg_name: '2021年10月QU', registration_datetime: '2022-09-21T13:21:55+09:00', state: 'PHS-30', phs: 'パッケージ転送開始待ち' }, { category: '不定期', pkg_id: '00000484', pkg_name: '2021年09月QU', registration_datetime: '2024-09-21T13:21:55+09:00', state: 'PHS-40', phs: 'パッケージ転送中' }, { category: '日時', pkg_id: '00000486', pkg_name: '2021年11月QU', registration_datetime: '2023-09-21T13:21:55+09:00', state: 'PHS-51', phs: 'パッケージ転送成功' }, { category: '不定期', pkg_id: '00000485', pkg_name: '2021年13月QU', registration_datetime: '2026-09-21T13:21:55+09:00', state: 'PHS-52', phs: 'パッケージ転送失敗' }, { category: 'QU', pkg_id: '00000487', pkg_name: '2021年13月QU', registration_datetime: '2025-09-21T13:21:55+09:00', state: 'xxx', phs: 'その他' }
    ],
    pkg_history_header: [
      { text: 'レベル', value: 'importance' },
      { text: '日時', value: 'datetime' },
      { text: 'カテゴリ', value: 'category' },
      { text: '説明', value: 'message' },
      { text: 'ID', value: 'pkg_id' },
      { text: '名称', value: 'pkg_name' }
    ],
    pkg_history_data: [
      // { category: 'QU', importance: '情報', message: '[情報]:aaaa', pkg_id: '00000482', pkg_name: '2021年09月QU', datetime: '2025-09-21T13:21:55+09:00' }, { category: '日時', importance: '警告', message: '[警告]:bbbb', pkg_id: '00000483', pkg_name: '2021年09月QU', datetime: '2026-09-21T13:21:55+09:00' }, { category: '不定期', importance: '情報', message: '[情報]:cccc', pkg_id: '00000485', pkg_name: '2021年11月QU', datetime: '2024-09-21T13:21:55+09:00' }, { category: '日時', importance: '警告', message: '[警告]:xxxx', pkg_id: '00001483', pkg_name: '2031年09月QU', datetime: '2126-09-21T13:21:55+09:00' }, { category: '不定期', importance: 'エラー', message: '[エラー]:dddd', pkg_id: '00000484', pkg_name: '2021年12月QU', datetime: '2023-09-21T13:21:55+09:00' }, { category: 'QU', importance: 'その他', message: '[その他]:eeee', pkg_id: '00000487', pkg_name: '2021年10月QU', datetime: '2022-09-21T13:21:55+09:00' }, { category: '', importance: 'エラー', message: '[エラー]:ffff', pkg_id: '00000486', pkg_name: '2021年13月QU', datetime: '2021-09-21T13:21:55+09:00' }
    ]
  }),

  beforeMount: function () {
  },

  mounted: function () {
  },

  watch: {
  },

  methods: {
    custom_sort_pkg_history_importance: function (items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === 'importance') {
          var va = /^エラー/.test(a.importance) ? 4 : /^警告/.test(a.importance) ? 3 : /^情報/.test(a.importance) ? 2 : /^その他/.test(a.importance) ? 1 : 0
          var vb = /^エラー/.test(b.importance) ? 4 : /^警告/.test(b.importance) ? 3 : /^情報/.test(b.importance) ? 2 : /^その他/.test(b.importance) ? 1 : 0
          if (!isDesc[0]) {
            return va - vb
          } else {
            return vb - va
          }
        } else {
          if (!isDesc[0]) {
            return a[index] < b[index] ? -1 : 1
          } else {
            return b[index] < a[index] ? -1 : 1
          }
        }
      })
      return items
    },
    tab_init: function () {
      this.isLoading = true
    },
    update_data: function (title, header, data, sorting) {
      this.title = title
      this.t_headers = header
      this.t_data = data
      this.sort_by = sorting
    },
    selectPkgTab: function () {
      this.tab_init()
      this.getRestApiTabWindow(() => {
        this.update_data('PKG', this.pkg_header, this.pkg_data, ['pkg_id'])
        this.isLoading = false
      }, 'Pkg')
    },
    selectHisTab: function () {
      this.tab_init()
      this.getRestApiTabWindow(() => {
        this.update_data('PKG log', this.pkg_history_header, this.pkg_history_data, ['datetime'])
        this.isLoading = false
      }, 'His')
    },
    getRestApiTabWindow: function (proc, tab) {
      var url = '/machines/' + `${this.$route.params.id}`
      if (tab === 'Pkg') {
        url += '/packages'
      } else if (tab === 'His') {
        url += '/histories'
      } else {
        return false
      }

      axios.get(url,
        {
          headers: { Authorization: this.$store.getters.user.token },
          params: { org: this.$store.getters.user.orgid }
        }
      ).then((res) => {
        if (tab === 'Pkg') {
          this.pkg_data = res.data
        } else if (tab === 'His') {
          this.pkg_history_data = res.data
        }
        // テーブル更新処理
        proc()
      }).catch((error) => {
        console.log(error)
        alert('情報の取得に失敗しました。')
      })
    }
  }
}
</script>
<style scoped>
.sublist_window th{ background-color:#dcdff1; }
</style>
