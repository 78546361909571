<template>
  <div>
    <loading v-show="isLoading"></loading>
    <div v-show="!isLoading">
      <div>
        <h1 id="id_title1">{{ title }}</h1>
      </div>
      <div>
        <div>
          <v-simple-table id="user_l1">
            <tbody id="id_tbody_user_notedit">
              <tr><th id="id_th_user">ユーザID</th>
                <td id="id_td_user">
                  {{  user_info.user_id }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <br>
          <v-simple-table  id="user_l2">
            <tbody id="id_tbody_user_edit">
              <tr><th id="id_th_name">ユーザ名</th>
                <td id="id_td_name">
                  <v-text-field
                    v-model="user_info.name"
                    placeholder=""
                    label="ユーザの姓名を入力"
                    :rules="[
                      required,
                      limit_length100
                    ]"
                    >
                  </v-text-field>
                </td></tr>
              <tr><th id="id_th_department">所属</th>
                <td id="id_td_department">
                  <v-text-field
                    v-model="user_info.department"
                    placeholder=""
                    label="所属を入力"
                    :rules="[
                      limit_length300
                    ]"
                    >
                  </v-text-field>
                </td></tr>
              <tr><th id="id_th_email"     >メールアドレス</th>
                <td id="id_td_email" >
                  <v-text-field
                    v-model="user_info.email"
                    placeholder=""
                    label="メールアドレスを入力"
                    :rules="[
                      required,
                      limit_length254,
                      emailRules
                    ]"
                    >
                  </v-text-field>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
        <div>
          <br>
          <v-btn color="primary" @click="edit_regist_">変更</v-btn>
          <a>&nbsp;</a>
          <v-btn color="normal" @click="back_">中止</v-btn>
        </div>
      </div>
    </div>  <!-- v-show="!isLoading" -->
  </div>
</template>
<script>

import axios from 'axios'
import store from '@/store'
import Loading from '@/components/Loading'

export default {
  components: {
    Loading
  },
  name: 'UserDetail',
  data: () => ({
    title: 'アカウント編集',
    user_info_save: {},
    user_info: {
      user_id: '',
      name: '',
      department: '',
      email: '',
      superuser: false,
      admin: false,
      operator: false,
      add_datetime: ''
    },
    save_superuser: false,
    save_admin: false,
    save_operator: false,
    err_message: '',
    emailRules: value => /^((([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))$/.test(value) || 'メールアドレスの形式が不正です', // メール形式の制約
    required: value => !!value || '必ず入力してください', // 入力必須の制約
    limit_length100: value => value.length <= 100 || '100文字以内で入力してください', // 文字数の制約
    limit_length254: value => value.length <= 254 || '254文字以内で入力してください', // 文字数の制約
    limit_length255: value => value.length <= 255 || '255文字以内で入力してください', // 文字数の制約
    limit_length300: value => value.length <= 300 || '300文字以内で入力してください', // 文字数の制約
    isLoading: true
  }),
  methods: {
    edit_regist_: function () {
      console.log('AccountEdit.vue methods: edit_regist_ start')
      var reg = /^((([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))$/
      if (this.user_info.name === '') {
        alert('ユーザ名を入力してください')
        return
      }
      if (this.user_info.name.length > 100) {
        alert('ユーザ名は100文字以内で入力してください')
        return
      }
      if (this.user_info.department.length > 300) {
        alert('所属は300文字以内で入力してください')
        return
      }
      if (this.user_info.email === '') {
        alert('メールアドレスを入力してください')
        return
      }
      if (this.user_info.email.length > 254) {
        alert('メールアドレスは254文字以内で入力してください')
        return
      }
      if (reg.test(this.user_info.email) === false) {
        alert('メールアドレスの形式が不正です')
        return
      }
      if (!confirm('登録しますか？')) {
        console.log('canceled.')
        return
      }
      this.isLoading = true
      this.putRestApi()
    },
    back_: function () {
      console.log('AccountEdit.vue methods: back_ start')
      this.$router.push({ name: 'AccountDetail', params: { ID: this.user_info.user_id } })
    },
    delete_: function () {
      console.log('AccountEdit.vue methods: pwreset_ start')
      if (!confirm('ユーザを削除しますか？')) {
        console.log('canceled.')
        return
      }
      this.$router.push({ name: 'User', params: {} })
    },
    getRestApi: function () {
      console.log('AccountEdit.vue methods: getRestApi start')
      const idToken = store.getters.user.token
      const headers = { headers: { Authorization: idToken } }
      axios.get('/users/me',
        headers
      ).then((res) => {
        this.user_info = res.data
        this.save_superuser = this.user_info.superuser
        this.save_admin = this.user_info.admin
        this.save_operator = this.user_info.operator
        // console.log('AccountEdit.vue getRestApi: 〇success this.user_info.user_id=' + this.user_info.user_id)
        this.isLoading = false
      }).catch((error) => {
        // console.log('AccountEdit.vue getRestApi: ★catch')
        this.isLoading = false
        alert('ログインユーザ情報取得に失敗\n通信エラーが起きました。リロードするか時間をおいてアクセスしてください。\n' + error)
        this.$router.push({ name: 'Top', params: { } }) // ログインユーザ編集直前にログインユーザ情報にアクセスできない場合は、Topに戻る
      })
    },
    putRestApi: function () {
      // console.log('AccountEdit.vue putRestApi: start')
      const idToken = this.$store.getters.user.token
      const headers = { headers: { Authorization: idToken } }
      axios.put('/users/me',
        {
          user_id: this.user_info.user_id,
          name: this.user_info.name,
          department: this.user_info.department,
          email: this.user_info.email,
          superuser: this.user_info.superuser,
          admin: this.user_info.admin,
          operator: this.user_info.operator,
          enable: true,
          add_datetime: this.user_info.add_datetime,
          add_user_id: this.user_info.add_user_id,
          seq_id: this.user_info.seq_id
        },
        headers
      ).then((res) => {
        // console.log('AccountEdit.vue putRestApi: then 〇success')
        // console.log(res.data)
        this.isLoading = false
        this.user_info = res.data
        this.$router.push({ name: 'AccountDetail', params: { id: this.user_info.user_id } })
      }).catch((error) => {
        // console.log('AccountEdit.vue putRestApi: ★catch')
        this.isLoading = false
        alert('ログインユーザの情報更新に失敗\n通信エラーが起きました。リロードするか時間をおいてアクセスしてください。\n' + error)
      })
    }
  },
  mounted: function () {
    console.log('AccountEdit.vue mounted: start')
    this.getRestApi()
  }
}
</script>
<style scoped>
#user_l1 table{ width:100%; }
#user_l1 th{ width: 150pt; background-color:#dcdff1; border: 1px #808080 solid; user-select: auto;}
#user_l1 td{ border: 1px #808080 solid; text-align:left;}
#user_l2 table{ width:100%; }
#user_l2 th{ width: 150pt; background-color:#dcdff1; border: 1px #808080 solid; user-select: auto;}
#user_l2 td{ border: 1px #808080 solid; text-align:left;}
</style>
