<template>
  <div>
    <loading v-show="isLoading"></loading>
    <div v-show="!isLoading">
      <div>
        <h1 id="id_title" >{{ title }}</h1>
      </div>
      <div>
        <br>
        <v-btn color="primary" @click="add_">登録</v-btn>
        <a>&nbsp;ユーザ詳細を表示するには該当ユーザの行をクリック</a>
      </div>
      <div id="id_table" class="user_list">
        <div id="card_title">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              dense
              >
            </v-text-field>
          </v-card-title>
        </div>
        <div id="data_table">
          <v-data-table
            dense
            :headers="t_headers"
            :items="users"
            :search="search"
            @click:row="onClickUserCell"
            >
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import Loading from '@/components/Loading'

export default {
  name: 'UserListView',
  data: () => ({
    title: 'ユーザ一覧',
    search: '',
    t_headers: [
      { text: 'ユーザID', value: 'user_id' },
      { text: 'ユーザ名', value: 'name' },
      { text: '所属', value: 'department' },
      { text: 'メールアドレス', value: 'email' },
      { text: 'ロール', value: 'role' }
    ],
    users: [],
    role: '',
    isLoading: true
  }),
  components: {
    Loading
  },
  mounted: function () {
    // console.log('User.vue mounted: start')
    // (スーパーユーザまたは管理者)以外か?
    if (!((this.$store.getters.user.role[0]) || (this.$store.getters.user.role[1]))) {
      this.isLoading = false
      alert('ユーザ一覧表示の権限がありません')
      this.$router.push({ name: 'Top', params: { } })
      return false
    }
    this.getRestApi()
  },
  watch: {
    users: {
      handler: function () {
        // console.log('User.vue watch:users start')
        let idx1 = 0
        this.users.forEach(element => {
          element.role = (element.superuser ? 'スーパーユーザ' : '')
          if (element.admin) {
            if (!(element.role.length === 0)) { element.role += ' ' }
            element.role += '管理者'
          }
          if (element.operator) {
            if (!(element.role.length === 0)) { element.role += ' ' }
            element.role += '作業者'
          }
          this.users[idx1].role = element.role
          idx1++
        })
      },
      deep: true
    }
  },

  methods: {
    onClickUserCell: function (data) {
      console.log('User.vue onClickUserCell:' + data.user_id)
      this.$router.push({ name: 'UserDetail', params: { id: data.user_id } })
    },
    add_: function () {
      console.log('User.vue methods: add_ start')
      this.$router.push({ name: 'UserAdd', params: { } })
    },
    getRestApi: function () {
      console.log('User.vue getRestApi:')
      const idToken = store.getters.user.token
      axios.get('/users',
        {
          params: { id: store.getters.user.orgid },
          headers: { Authorization: idToken }
        }
      ).then((res) => {
        // console.log('User.vue getRestApi: then get 〇success')
        // console.log(res.data)
        this.users = res.data
        this.isLoading = false
      }).catch((error) => {
        // console.log('User.vue getRestApi: ★catch error=' + error)
        this.isLoading = false
        alert('ユーザ一覧取得に失敗\n通信エラーが起きました。リロードするか時間をおいてアクセスしてください。\n' + error)
      })
    }
  }

}
</script>

<style scoped>
.user_list th{ background-color:#dcdff1; border: 1px #808080 solid;}
.user_list td{ border: 1px #808080 solid; text-align:left;}
</style>
