<template>
  <div>
    <loading v-show="isLoading"></loading>
    <div v-show="!isLoading">
      <div>
        <h1 id="id_title" >{{ title }}</h1>
      </div>
      <br>
      <div>
        <v-btn color="primary" @click="add_" v-show="!($store.getters.user.role[0])">パッケージ作成
        </v-btn>
        &nbsp;パッケージ詳細を表示するには該当パッケージの行をクリック
      </div>
      <br>
      <div>
        <h2 id="id_title1" >パッケージ一覧(処理中)</h2>
      </div>
      <div id="id_table" class="package_list">
        <div id="card_title">
          <v-card-title>
            <v-text-field
              v-model="search1"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              dense
              >
            </v-text-field>
          </v-card-title>
        </div>
        <div id="data_table">
          <v-data-table
            dense
            :headers="t_headers"
            :items="active_packages"
            :search="search1"
            @click:row="onClickPackageCell"
            >
          </v-data-table>
        </div>
      </div>
      <div>
        <h2 id="id_title2" >全パッケージ一覧</h2>
      </div>
      <div id="id_table2" class="package_list">
        <div id="card_title">
          <v-card-title>
            <v-text-field
              v-model="search2"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              dense
              >
            </v-text-field>
          </v-card-title>
        </div>
        <div id="data_table2">
          <v-data-table
            dense
            :headers="t_headers"
            :items="all_packages"
            :sort-by="['pkg_id']"
            :sort-desc=true
            :search="search2"
            @click:row="onClickPackageCell"
            >
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Loading from '@/components/Loading'
import { getPhsInfo } from '@/components/common/PackageUtility.js'
import rmtlib from '@/plugins/class/rmtlib'

export default {
  components: {
    Loading
  },
  name: 'PackageListView',
  data: () => ({
    title: 'パッケージ一覧',
    search1: '',
    search2: '',
    t_headers: [
      { text: 'ID', value: 'pkg_id', width: '15' },
      { text: '名称', value: 'pkg_name', width: '140' },
      { text: '種別', value: 'task', width: '130' },
      { text: '処理ステータス', value: 'action_status', width: '150' },
      { text: '登録日', value: 'registration_datetime', width: '300' }
    ],
    active_packages: [],
    all_packages: [],
    funcRet: {},
    isLoading: true
  }),
  watch: {
    // *************************************
    // パッケージ一覧の配列変数更新時
    // *************************************
    all_packages: {
      handler: function () {
        // console.log('Package.vue watch:all_packages')
        this.make_active_package() // 全パッケージ一覧からパッケージ一覧(処理中)を作成
      }
    }
  },
  methods: {
    // *************************************
    // 一覧のパッケージ行をクリック時の処理
    // *************************************
    onClickPackageCell: function (data) {
      // console.log('Package.vue onClickPackageCell:' + data.pkg_id)
      this.$router.push({ name: 'PackageDetail', params: { id: data.pkg_id } }) // 「パッケージ詳細」画面に遷移
    },
    // *****************************
    // [パッケージ作成]ボタンの処理
    // *****************************
    add_: function () {
      // console.log('Package.vue methods: add_')
      this.$router.push({ name: 'PackageAdd', params: {} }) // 「パッケージ作成」画面に遷移
      this.isLoading = false
    },
    // *************************************************************
    // 全パッケージ一覧からパッケージ一覧(処理中)を作成
    // *************************************************************
    make_active_package: function (data) {
      // console.log('Package.vue make_active_package:')
      this.active_packages = []
      this.all_packages.forEach(rowPackage => {
        this.funcRet = getPhsInfo(rowPackage.phs)
        const flagExist = this.funcRet.flagExist
        rowPackage.action_status = this.funcRet.phsInfo.action_status
        rowPackage.active = this.funcRet.phsInfo.active
        // 想定外のphsか?
        if (flagExist === false) {
          // 想定外のphsの場合は、処理ステータスにphsの値を表示、処理中には表示しないとする
          rowPackage.action_status = rowPackage.phs
          rowPackage.active = false
        }
        // 処理中に表示する明細か?
        if (rowPackage.active) {
          this.active_packages.push(rowPackage)
        }
      })
    },
    // ***********************
    // 全パッケージ一覧の取得
    // ***********************
    getRestApi: function () {
      // console.log('Package.vue getRestApi:')
      axios.get('/packages',
        {
          params: { org: this.$store.getters.user.orgid },
          headers: { Authorization: this.$store.getters.user.token }
        }
      ).then((res) => {
        // パッケージ一覧を取得が成功した時
        // console.log('Package.vue getRestApi: then get 〇success')
        // console.log(res.data)
        // undefinedリターン対応
        if (res.data.length === undefined) {
          console.log('Package.vue res.data undefined')
          this.all_packages = []
          this.isLoading = false
          return
        }
        this.all_packages = res.data
        this.isLoading = false
      }).catch((error) => {
        // パッケージ一覧を取得が失敗した時
        console.log('Package.vue getRestApi: ★catch error=' + error)
        this.isLoading = false
        alert('パッケージ一覧取得に失敗\n通信エラーが起きました。リロードするか時間をおいてアクセスしてください。\n' + error)
      })
    }
  },
  // *******************************
  // モジュール生成時
  // *******************************
  created: function () {
    // WSUS組織では専用ページに移動させる
    if (rmtlib.isWsusOrg(this.$store.getters.user.orgid)) {
      this.$router.replace('/Wsus/Package')
      return
    }
    // console.log('Package.vue created:')
    this.getRestApi() // 全パッケージ一覧の取得
    this.make_active_package() // 全パッケージ一覧からパッケージ一覧(処理中)を作成
  }
}
</script>

<style scoped>
.user_list th{ background-color:#dcdff1; border: 1px #808080 solid;}
.user_list td{ border: 1px #808080 solid; text-align:left;}
</style>
