<template>
  <div>

    <loading v-show="isLoading" />
    <div v-show="!isLoading">
      <div>
        <h1>{{ title }}</h1>
      </div>
      <!--v-divider /-->
      <div v-show="this.$store.getters.user.role[0]">
        <v-btn color="primary" v-on:click="create_">登録</v-btn>
      </div>
      <v-card-title>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            dense
            ></v-text-field>
      </v-card-title>
      <div class="contract_list">
        <div>
          <h2>{{ subtitle_1 }}</h2>
        </div>
        <v-data-table
            dense
            :headers="t_headers_patch"
            :items="contracts_patch"
            :search="search"
            :sort-by="['contract_number']"
            @click:row="onClickContractCell"
          >
            <template v-slot:item.enable="{ item }">
              {{ item.enable ? '有効':'無効' }}
            </template>
        </v-data-table>
      </div>

      <div class="contract_list">
        <div>
          <h2>{{ subtitle_2 }}</h2>
        </div>
        <v-data-table
            dense
            :headers="t_headers_appliance"
            :items="contracts_appliance"
            :search="search"
            :sort-by="['contract_number']"
            @click:row="onClickContractCell"
          >
            <template v-slot:item.enable="{ item }">
              {{ item.enable ? '有効':'無効' }}
            </template>
        </v-data-table>
      </div>

      <div>
        {{ error_message }}
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import Loading from '@/components/Loading'

export default {
  components: {
    Loading
  },
  name: 'ContractListView',
  data: () => ({
    title: '契約一覧',
    subtitle_1: 'シングルサイトマネジメント版',
    subtitle_2: 'マルチサイトマネジメント版',
    permission: false,
    isLoading: true,
    error_message: '',
    search: '',
    t_headers_patch: [
      { text: '契約番号', value: 'name', align: 'start', filterable: true },
      { text: 'エッジサーバ管理テナント(テナントID)', value: 'host_organization_id' },
      { text: '仮想化基盤管理テナント(テナントID)', value: 'guest_organization_id' }
    //  { text: '契約ステータス', value: 'enable' }
    ],
    t_headers_appliance: [
      { text: '契約番号', value: 'name', align: 'start', filterable: true },
      { text: '管理テナント(テナントID)', value: 'host_organization_id' }
    //  { text: '契約ステータス', value: 'enable' }
    ],

    // API /contracts
    contracts: [],
    contracts_patch: [],
    contracts_appliance: [
      // { name: '仮想アプラ契約番号ダミー', host_organization_id: 'ダミー' }
    ],
    // API /organizations
    organizations: []
  }),

  beforeCreate: function () {
  },

  created: function () {
    this.permission = (((this.$store.getters.user.role[0]) && (this.$store.getters.user.orgid.slice(0, 2) !== '80')) || this.$store.getters.user.role[1])
  },

  beforeMount: function () {
  },

  mounted: function () {
    if (!this.permission) {
      console.log('contract permission denied.')
      return
    }
    this.$nextTick(function () {
      this.getRestApiContract()
    })
  },

  beforeUpdate: function () {
  },

  updated: function () {
  },

  beforeDestroy: function () {
  },

  destroyed: function () {
  },

  watch: {
    contracts: {
      handler: function () {
        this.getRestApiOrganization()
      },
      deep: true
    },
    organizations: {
      handler: function () {
        // console.log('watch')
        for (let i = 0; i < this.contracts.length; i++) {
          for (let j = 0; j < this.organizations.length; j++) {
            if (this.contracts[i].host_organization_id === this.organizations[j].organization_id) {
              this.contracts[i].host_organization_id = `${this.organizations[j].organization_name}(${this.contracts[i].host_organization_id})`
            }
            if (this.contracts[i].guest_organization_id === this.organizations[j].organization_id) {
              this.contracts[i].guest_organization_id = `${this.organizations[j].organization_name}(${this.contracts[i].guest_organization_id})`
            }
          }
        }
      },
      deep: true
    }
  },

  methods: {

    create_: function () {
      this.$router.push({ name: 'ContractCreate' })
    },
    onClickContractCell: function (data) {
      this.$router.push({ name: 'ContractDetail', params: { id: data.contract_number } })
    },
    list: function () {
      for (let i = 0; i < this.contracts.length; i++) {
        if (this.contracts[i].contract_type === 'patch') {
          this.contracts_patch.push(this.contracts[i])
        } else if (this.contracts[i].contract_type === 'appliance') {
          this.contracts_appliance.push(this.contracts[i])
        }
      }
    },
    getRestApiContract: function () {
      axios.get('/contracts',
        {
          headers: { Authorization: this.$store.getters.user.token },
          params: { id: this.$store.getters.user.orgid }
        }
      ).then((res) => {
        this.contracts = res.data
        this.isLoading = false
        this.list()
      }).catch((error) => {
        console.log(error)
        // alert(error)
        alert('契約情報の取得に失敗しました。')
        // this.error_message += '[GET /contracts]: Failed' + '\n'
        this.isLoading = false
      })
    },
    getRestApiOrganization: function () {
      axios.get('/organizations',
        {
          headers: { Authorization: this.$store.getters.user.token }
        }
      ).then((res) => {
        this.organizations = res.data
      }).catch((error) => {
        console.log(error)
        // alert(error)
        alert('組織情報の取得に失敗しました。')
        // this.error_message += '[GET /organizations]: Failed' + '\n'
      })
    }

  }

}
</script>
<style scoped>
.contract_list th{ background-color:#dcdff1; }
</style>
