<template>
  <div>
    <loading v-show="isLoading"></loading>
    <div v-show="!isLoading">
      <div id="id_table" class="user_list">
        <div id="card_title">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              dense
              >
            </v-text-field>
          </v-card-title>
        </div>
        <div id="data_table">
          <v-data-table
            v-model="selectedDevices"
            :headers="t_headers"
            :items="listDevices"
            item-key="machine_id"
            :search="search"
            :sort-by="['contract_number']"
            show-select
            dense
            >
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Loading from '@/components/Loading'

export default {
  name: 'PackageDevice',
  props: {
    base_parm_info: {}
  },
  data: () => ({
    search: '',
    package_info: {},
    t_headers: [
      // { text: 'ID', value: 'machine_id' },
      { text: '契約番号', value: 'contract_name' },
      { text: '型番', value: 'model_number' },
      { text: 'シリアル', value: 'serial_number' },
      { text: 'ホスト名', value: 'hostname' }
    ],
    selectedDevices: [], //       # パッケージ適用 選択機器リストの配列
    listDevices: [], //           # パッケージ適用 表示機器リストの配列
    listEnableDevicesWork: [], // # 有効機器抽出時のワーク配列
    listWsusDevices: [], //       # WSUS機器リストの配列
    category: 'QU',
    environment: 'Pilot',
    parm_info: {},
    isLoading: true
  }),
  components: {
    Loading
  },
  watch: {
    // *******************************
    // 変数selectedDevices更新時
    // *******************************
    selectedDevices: {
      handler: function () {
        this.$emit('SelectDevicesEvent', this.selectedDevices)
      }
    }
  },
  methods: {
    // ************************************************
    // 選択装置の設定 (QU配信のみ機能)
    // ************************************************
    setSelectDevices: function (Pcategory, Penv) {
      this.selectedDevices = []
      this.listDevices.forEach(DeviceRow => {
        this.listWsusDevices.forEach(WsusRow => {
          if ((DeviceRow.model_number === WsusRow.model_number) &&
              (DeviceRow.serial_number === WsusRow.serial_number)) {
            // V2.2対応 forEachの場合returnで次の値。
            if ((WsusRow.contract_type !== 'patch') &&
                (DeviceRow.contract_name !== WsusRow.contract_name)) {
              return
            }
            // *** QU Pilot の時
            if ((Pcategory === 'QU') && (Penv === 'Pilot')) {
              if (WsusRow.qu === 'ON') {
                this.selectedDevices.push(DeviceRow)
              }
            // *** QU Broad の時
            } else if ((Pcategory === 'QU') && (Penv === 'Broad')) {
              if (WsusRow.prod === 'ON') {
                this.selectedDevices.push(DeviceRow)
              }
            }
          }
        })
      })
    },
    // ***********************************************
    // 選択装置のリセット
    // ***********************************************
    renewSelectDevices: function (Pcategory, Penv) {
      // この関数が呼ばれる時は、カテゴリ QU、FUに限られる 基本的に親Vueから呼ばれる
      this.category = Pcategory
      this.environment = Penv
      this.getWsusRestApi() // WSUS機器一覧の取得
    },
    // ***********************************************
    // WSUS機器一覧の取得
    // ***********************************************
    getWsusRestApi: function () {
      this.isLoading = true
      // WSUS設定一覧を取得
      axios.get('/contracts/wsus',
        {
          headers: { Authorization: this.$store.getters.user.token }
        }
      ).then((res3) => {
        this.listWsusDevices = res3.data
        this.setSelectDevices(this.category, this.environment) // *** 選択装置の設定 (QU配信のみ機能)
        this.isLoading = false
      }).catch((error) => {
        // テナント所属機器一覧を取得が失敗した時
        console.log('axios.get /contracts/wsus catch error=' + error)
        this.listDevices = []
        this.isLoading = false
        alert('対象機器一覧取得に失敗\n通信エラーが起きました。リロードするか時間をおいてアクセスしてください。\n' + error)
      })
    },
    // ***********************************************
    // 対象装置一覧の取得、及び選択済の装置一覧の取得
    // ***********************************************
    getRestApi: function () {
      axios.get('/machines',
        {
          params: {
            org: this.$store.getters.user.orgid, //   # スーパーユーザは他テナントの情報を取得可能なため指定が・テナントIDを指定
            filter: 'wsus' //                         # WSUS装置一覧取得を指定
          },
          headers: { Authorization: this.$store.getters.user.token }
        }
      ).then((res1) => {
        // WSUS機器一覧を取得が成功した時
        // ********** 機器一覧データは"有効"状態の機器のみ抽出
        this.listEnableDevicesWork = []
        res1.data.forEach(listDevicesRow => { // ********** "有効"な装置のみを抽出("新規登録"を除外)
          if (listDevicesRow.enable_info === '有効') {
            this.listEnableDevicesWork.push(listDevicesRow)
          }
        })
        // ********** パッケージ作成、流用パッケージ作成(削除パッケージ作成以外)の時は、
        // ********** 一覧データはテナントに属する"有効"状態の装置一覧に設定、選択対象はこの時点で初期化
        // ********** 削除パッケージ作成の時の場合は、後続処理で作成
        this.listDevices = this.listEnableDevicesWork
        this.selectedDevices = []
        // 通常新規パッケージ作成 または メンテナンスパッケージ作成か?    # (選択対象装置に関するI/Oや処理がない時)
        if ((this.parm_info.actionType === 'new') || (this.parm_info.actionType === 'wsus_reuseMW')) {
          this.isLoading = false
        } else if (this.parm_info.actionType === 'wsus_reuseQU') { //     # (WSUS設定に応じて選択対象装置に関するI/Oや処理がある時)
          this.getWsusRestApi() // WSUS機器一覧の取得
        } else if ((this.parm_info.actionType === 'reuse') || //          # (流用元のパッケージの選択対象装置状態やタスク有効状態で選択処理する処理がある時)
                   (this.parm_info.actionType === 'delete')) {
          axios.get('/packages/' + this.parm_info.pkgid + '/machines',
            {
              params: { org: this.$store.getters.user.orgid },
              headers: { Authorization: this.$store.getters.user.token }
            }
          ).then((res2) => {
            var selectDevice = res2.data
            this.selectedDevices = []
            // ********** 削除パッケージ作成か?
            if (this.parm_info.actionType === 'delete') {
              this.listDevices = []
              selectDevice.forEach(selectDeviceRow => { // パッケージ選択対象装置一覧でループ
                this.listEnableDevicesWork.forEach(DeviceRow => { // テナントに属する有効なマシン装置一覧でループ
                  if (DeviceRow.machine_id === selectDeviceRow.machine_id) {
                    if (selectDeviceRow.enable_info === '有効') {
                      if (selectDeviceRow.pks === 'PKS-20') { // タスク登録済みの装置のみ
                        this.listDevices.push(DeviceRow)
                        this.selectedDevices.push(selectDeviceRow)
                      }
                    }
                  }
                })
              })
            // ********** 流用パッケージ作成か?
            } else {
              this.listDevices.forEach(DeviceRow => { // テナントに属するマシン装置一覧でループ
                selectDevice.forEach(selectDeviceRow => { // パッケージ選択対象装置一覧でループ
                  if (DeviceRow.machine_id === selectDeviceRow.machine_id) {
                    this.selectedDevices.push(DeviceRow)
                  }
                })
              })
            }
            this.isLoading = false
          }).catch((error) => {
            // 選択対象装置一覧を取得が失敗した時
            console.log('axios.get /packages/' + this.parm_info.pkgid + '/machines catch error=' + error)
            this.isLoading = false
            alert('対象装置一覧取得に失敗\n通信エラーが起きました。リロードするか時間をおいてアクセスしてください。\n' + error)
          })
        } else {
          // ここにはこないはず
          console.log('想定外 this.parm_info.actionType=' + this.parm_info.actionType)
          this.isLoading = false
        }
      }).catch((error) => {
        // テナント所属装置一覧を取得が失敗した時
        console.log('axios.get /machines catch error=' + error)
        this.listDevices = []
        this.isLoading = false
        alert('対象装置一覧取得に失敗\n通信エラーが起きました。リロードするか時間をおいてアクセスしてください。\n' + error)
      })
    }
  },
  // *******************************
  // モジュール生成時
  // *******************************
  created: function () {
    this.isLoading = true
    this.parm_info = this.base_parm_info
    this.parm_info.pkgid = this.$route.params.id
  },
  // *******************************
  // 画面マウント時
  // *******************************
  mounted: function () {
    this.getRestApi() // 対象装置一覧の取得、及び選択済の装置一覧の取得
  }
}
</script>

<style scoped>
.user_list th{ background-color:#dcdff1; border: 1px #808080 solid;}
.user_list td{ border: 1px #808080 solid; text-align:left;}
</style>
