<template>
  <div>
    <loading v-show="isLoading"></loading>
    <div v-show="!isLoading">
      <div>
        <h1 id="id_title" >{{ title }}</h1>
      </div>
      <div>
        <v-btn color="error" @click="delete_">削除</v-btn>&nbsp;&nbsp;削除する場合は行のチェックボックスを選択し[削除]ボタンを押してください。
      </div>
      <div id="id_table">
        <div id="card_title">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              dense
              >
            </v-text-field>
          </v-card-title>
        </div>
        <div id="data_table">
          <v-data-table
            show-select
            v-model="selected"
            item-key="machine_id"
            :sort-by="['contract_number']"
            dense
            :headers="t_headers"
            :items="wsusDevicesArray"
            :search="search"
            @click:row="onClickDeviceCell"
            class="wsus_list"
          >
          <template v-slot:header>
            <thead>
              <tr>
                <th colspan="4" style="text-align: center"></th>
                <th colspan="2" style="text-align: center">QU</th>
                <th colspan="2" style="text-align: center"></th>
              </tr>
            </thead>
          </template>
          </v-data-table>
        </div>
      </div>
      <div>
        <v-form ref="wsus_form">
        <table id="id_end_code_row" class="sampleTable">
          <tr>
            <td v-bind:colspan="typeSpan" width="250">
              <v-select
                v-model="add_contract_type"
                :items="selectable_type"
                :reduce="selectable_type => selectable_type.add_contract_type"
                item-text="type_name"
                item-value="add_contract_type"
                label="契約種別"
                :rules="[required]"
                dense
              />
            </td>
          </tr>
          <tr>
            <td width="140" v-if="(add_contract_type === 'patch')">
              <v-text-field id="id_kataban"
                name="add_model_number"
                v-model.trim="add_model_number"
                placeholder="型番"
                label="型番"
                :rules="validate_model"
                dense
                />
            </td>
            <a v-if="(add_contract_type === 'patch')">&nbsp;</a>
            <td width="140" v-if="(add_contract_type === 'patch')">
              <v-text-field id="id_serial"
                name="add_serial_number"
                v-model.trim="add_serial_number"
                placeholder="シリアル番号"
                label="シリアル番号"
                :rules="validate_serial"
                dense
                />
            </td>
            <td width="280" v-if="(add_contract_type === 'appliance')">
              <v-text-field id="id_contract_name"
                name="add_contract_name"
                v-model.trim="add_contract_name"
                placeholder="契約番号"
                label="契約番号"
                :rules=[required]
                dense
                />
            </td>
            <a>&nbsp;</a>
            <td width="240">
              <v-select
                v-model="add_qu"
                :items="important_levels"
                item-text="levelname"
                item-value="levelid"
                label="QU Pilot承認"
                placeholder="QU Pilot承認"
                dense
              />
            </td>
            <a>&nbsp;</a>
            <td width="240">
              <v-select
                v-model="add_qu_prod"
                :items="release_selects"
                item-text="releaseSelectName"
                item-value="releaseSelectsId"
                label="QU Broad承認"
                placeholder="QU Broad承認"
                dense
              />
            </td>
            <a>&nbsp;</a>
            <td width="400">
              <v-text-field id="id_email1"
                name="add_email"
                v-model.trim="add_email"
                placeholder="お客様メールアドレス"
                label="お客様メールアドレス"
                :rules="rules_email"
                dense
                />
            </td>
          </tr>
        </table>
        </v-form>
      </div>
      <div>
        <v-btn color="primary" @click="add_wsus_device_">追加更新</v-btn>&nbsp;&nbsp;WSUS機器登録は上記の行を入力後[追加更新]ボタンを押してください。
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Loading from '@/components/Loading'
import validate from '@/plugins/class/validate'
import rmtlib from '@/plugins/class/rmtlib'

export default {
  name: 'WsusSettingView',
  data: () => ({
    title: 'WSUS設定一覧',
    selected: [],
    strMailDelimiter: ';',
    add_machine_id: '',
    add_model_number: '',
    add_serial_number: '',
    add_qu: 'ON',
    add_qu_prod: 'ON',
    add_email: '',
    add_seq_id: '',
    flagExistWsus: false,
    important_levels: [
      { levelname: 'ON(QU Pilot承認する)', levelid: 'ON' },
      { levelname: 'OFF(QU Pilot承認しない)', levelid: 'OFF' }
    ],
    release_selects: [
      { releaseSelectName: 'ON(QU Broad承認する)', releaseSelectsId: 'ON' },
      { releaseSelectName: 'OFF(QU Broad承認しない)', releaseSelectsId: 'OFF' }
    ],
    search: '',
    t_headers: [
      { text: '契約番号', value: 'contract_name', width: '120' },
      { text: '型番', value: 'model_number', width: '120' },
      { text: 'シリアル番号', value: 'serial_number', width: '130' },
      { text: 'Pilot環境 / 更新日時', value: 'qu_pilot_disp', width: '210' },
      { text: 'Broad環境 / 更新日時 ', value: 'qu_prod_disp', width: '210' },
      { text: 'お客様メールアドレス', value: 'email', width: '200' }
    ],
    wsusDevicesArray: [],
    required: validate.required(),
    validate_model: [
      validate.required(),
      validate.limitLength(100),
      validate.modelNumber()
    ],
    validate_serial: [
      validate.required(),
      validate.limitLength(100),
      validate.serialNumber()
    ],
    rules_email: [
      validate.required(),
      validate.limitLength(254),
      validate.emailAddress()
    ],
    add_contract_name: '',
    add_contract_type: 'patch',
    selectable_type: [
      { type_name: 'シングルサイトマネジメント版', add_contract_type: 'patch' },
      { type_name: 'マルチサイトマネジメント版', add_contract_type: 'appliance' }
    ],
    typeSpan: '6',
    isLoading: true
  }),
  components: {
    Loading
  },
  // *******************************
  // 画面モジュール生成時
  // *******************************
  created: function () {
    // WSUS組織以外ではWSUS設定ページを表示しない
    if (!rmtlib.isWsusOrg(this.$store.getters.user.orgid)) {
      this.$router.replace('/')
      return
    }
    this.getRestAllWsusApi() // 全WSUS設定一覧取得 //
  },
  // *******************************
  // 画面マウント時
  // *******************************
  mounted: function () {
  },
  watch: {
    // *******************************
    // WSUS設定一覧配列データ更新時
    // *******************************
    wsusDevicesArray: {
      handler: function () {
        this.wsusDevicesArray.forEach(wsusRow => {
          wsusRow.qu_pilot_disp = (wsusRow.qu + ' ').slice(0, 3) + '　/　' + wsusRow.qu_update_datetime
          wsusRow.qu_prod_disp = (wsusRow.prod + ' ').slice(0, 3) + '　/　' + wsusRow.prod_update_datetime
        })
      },
      deep: true // ネストしたデータも監視するの指定
    },
    add_contract_type: function () {
      // console.log('add_contract_type変更')
      this.changeContractType()
    }
  },

  methods: {
    changeContractType: function () {
      if (this.add_contract_type === 'patch') {
        this.typeSpan = '6'
      } else if (this.add_contract_type === 'appliance') {
        this.typeSpan = '4'
      }
    },
    // *******************************
    // 一覧の行をクリックした時
    // *******************************
    onClickDeviceCell: function (data) {
      this.add_machine_id = data.machine_id
      this.add_model_number = data.model_number
      this.add_serial_number = data.serial_number
      this.add_qu = data.qu
      this.add_qu_prod = data.prod
      this.add_email = data.email
      this.add_seq_id = data.seq_id

      // クリックしたデータのタイプを設定
      if (data.contract_type === undefined) {
        this.add_contract_type = 'patch'
      } else {
        this.add_contract_type = data.contract_type
      }

      // V2.2対応 仮想アプラの場合契約番号を追加する
      if (data.contract_name === undefined) {
        this.add_contract_name = ''
      } else {
        this.add_contract_name = data.contract_name
      }
      // console.log(data)
    },
    // **********************************
    // [追加更新]ボタンを押下げ時の処理
    // **********************************
    add_wsus_device_: function () {
      if (!this.$refs.wsus_form.validate()) {
        return
      }
      // メール入力文字の前後ブランクを削除し最後の文字を取得
      const strLast = this.add_email.trim().slice(this.add_email.length - 1, this.add_email.length)
      // 最後の文字がメール区切り文字か?
      if (strLast === this.strMailDelimiter) {
        // 最後のメール区切り文字を削除 (これを行わないとsplitで配列化した時、最後に空文字があるとみなされる)
        this.add_email = this.add_email.slice(0, this.add_email.length - 1)
      }
      // メール入力文字を配列化
      var mailArray = this.add_email.split(this.strMailDelimiter)
      const countMail = mailArray.length
      // メールアドレス配列の各メールアドレス文字列を前後空白削除
      for (let mailRow of mailArray) {
        mailRow = mailRow.trim()
      }
      // メールアドレスが3つ以上指定されているか
      if (countMail > 3) {
        alert('お客様メールアドレスは3つまで指定してください。')
        return
      }
      let strMail = '' // 前後空白削除したメールアドレス文字列の組み立て用
      for (const mailRow of mailArray) {
        const trimMail = mailRow.trim()
        if (!(strMail === '')) {
          strMail += this.strMailDelimiter
        }
        strMail += trimMail
      }
      this.add_email = strMail // 前後空白を削除したメール羅列文字列を再セット
      for (const deviceRow of this.wsusDevicesArray) {
        this.flagExistWsus = false
        // 型番、シリアル番号が一致しているか？
        if ((deviceRow.model_number === this.add_model_number) && (deviceRow.serial_number === this.add_serial_number) && (this.add_contract_type === 'patch')) {
          this.flagExistWsus = true
        }
        if ((this.add_contract_type === 'appliance') && (deviceRow.contract_name === this.add_contract_name)) {
          this.flagExistWsus = true
        }
        if (this.flagExistWsus) {
          if (this.add_contract_type === 'patch') {
            if (!confirm('既に存在するWSUS機器です。\n' +
              '型番=' + deviceRow.model_number + ' シリアル番号=' + deviceRow.serial_number + '\nPilot承認=' + deviceRow.qu + ' Broad承認=' + deviceRow.prod + '\nemail=' + deviceRow.email + '\nが既に有り\n' +
              '型番=' + this.add_model_number + ' シリアル番号=' + this.add_serial_number + '\nPilot承認=' + this.add_qu + ' Broad承認=' + this.add_qu_prod + '\nemail=' + this.add_email + '\nに更新しますか?')) {
              return
            }
          } else if (this.add_contract_type === 'appliance') {
            if (!confirm('既に存在するWSUS機器です。\n' +
              '契約番号=' + deviceRow.contract_name + '\nPilot承認=' + deviceRow.qu + ' Broad承認=' + deviceRow.prod + '\nemail=' + deviceRow.email + '\nが既に有り\n' +
              '契約番号=' + this.add_contract_name + '\nPilot承認=' + this.add_qu + ' Broad承認=' + this.add_qu_prod + '\nemail=' + this.add_email + '\nに更新しますか?')) {
              return
            }
          }
        }
      }

      // V2.2対応 仮想アプラの場合は型番とシリアルを'-'にする
      if (this.add_contract_type === 'appliance') {
        this.add_model_number = '-'
        this.add_serial_number = '-'
      }

      const addWsusDevices = {
        machine_id: this.add_machine_id,
        model_number: this.add_model_number,
        serial_number: this.add_serial_number,
        qu: this.add_qu,
        qu_update_datetime: '',
        prod: this.add_qu_prod,
        prod_update_datetime: '',
        email: this.add_email,
        seq_id: this.add_seq_id,
        contract_type: this.add_contract_type,
        contract_name: this.add_contract_name
      }
      this.postRestWsusApi(addWsusDevices) // *** WSUS設定を追加、更新するAPIコール (POST)(PUT)
    },
    // *******************************
    // 削除ボタン押下げ時
    // *******************************
    delete_: function () {
      if (this.selected.length === 0) {
        alert('削除対象の機器を選択してください。')
        return
      }
      if (!confirm('WSUS機器を削除しますか？')) {
        return
      }
      this.deleteResWsusApi() // WSUS設定削除するAPIコール (DELETE)
    },
    // ***********************
    // 全WSUS設定一覧取得
    // ***********************
    getRestAllWsusApi: function () {
      this.isLoading = true
      axios.get('/contracts/wsus',
        {
          headers: { Authorization: this.$store.getters.user.token }
        }
      ).then((res) => {
        // WSUS契約一覧取得が成功した時
        this.wsusDevicesArray = res.data
        this.isLoading = false
      }).catch((error) => {
        // WSUS契約一覧取得が失敗した時
        this.isLoading = false
        alert('ユーザ一覧取得に失敗\n通信エラーが起きました。リロードするか時間をおいてアクセスしてください。\n' + error)
      })
    },
    // ***********************************
    // WSUS設定削除するAPIコール (DELETE)
    // ***********************************
    deleteResWsusApi: function () {
      // 更新前WSUS機器リストでループ
      for (const deviceRow of this.wsusDevicesArray) {
        // 削除対象終了コードリストでループ
        for (const delRow of this.selected) {
          // 削除選択されているか?
          if (deviceRow.machine_id === delRow.machine_id) {
            this.isLoading = true
            const idToken = this.$store.getters.user.token
            const headers = { headers: { Authorization: idToken } }
            axios.delete('/contracts/wsus/' + delRow.machine_id,
              headers
            ).then((res) => {
              this.isLoading = false
              this.getRestAllWsusApi() // 更新後の一覧更新
            }).catch((error) => {
              // WSUS設定削除が失敗した時
              this.isLoading = false
              alert('WSUS設定削除に失敗\n通信エラーが起きました。リロードするか時間をおいてアクセスしてください。\n' + error)
              this.getRestAllWsusApi() // 更新後の一覧更新
            })
          }
        }
      }
      this.selected = []
    },
    // **********************************************
    // WSUS設定を追加、更新するAPIコール (POST)(PUT)
    // **********************************************
    postRestWsusApi: function (addWsusDevices) {
      this.isLoading = true
      const idToken = this.$store.getters.user.token
      const headers = { headers: { Authorization: idToken } }
      axios.post('/contracts/wsus',
        {
          model_number: addWsusDevices.model_number,
          serial_number: addWsusDevices.serial_number,
          qu: addWsusDevices.qu,
          prod: addWsusDevices.prod,
          email: addWsusDevices.email,
          contract_type: addWsusDevices.contract_type,
          contract_name: addWsusDevices.contract_name
        },
        headers
      ).then((res) => {
        // console.log('追加')
        const postStatus = res.status
        const PostResData = res.data
        if (postStatus === 200) {
          // 更新後の入力フィールドクリア
          // v-formの値リセットになるはずだが、動作がおかしいので個別に実施
          // this.$refs.wsus_form.reset()
          this.add_machine_id = ''
          this.add_model_number = ''
          this.add_serial_number = ''
          this.add_qu = 'ON'
          this.add_qu_prod = 'ON'
          this.add_email = ''
          this.add_contract_type = 'patch'
          this.add_contract_name = ''
          // console.log(Array.isArray(PostResData))
          // console.log(PostResData)
          if (Array.isArray(PostResData)) {
            PostResData.forEach(PostResDataRow => {
              // console.log(PostResDataRow)
              this.wsusDevicesArray.push(PostResDataRow)
            })
          } else {
            this.wsusDevicesArray.push(PostResData)
          }
          // WSUS契約追加が成功した時
          this.$refs.wsus_form.resetValidation()
        }
        this.isLoading = false
      }).catch((error) => {
        const postStatus = error.response.status
        if (postStatus === 400) {
          const postError = error.response.data
          if (postError.status === -2) {
            // console.log(this.wsusDevicesArray)
            axios.put('/contracts/wsus/' + addWsusDevices.machine_id,
              {
                contract_type: addWsusDevices.contract_type,
                contract_name: addWsusDevices.contract_name,
                machine_id: addWsusDevices.machine_id,
                model_number: addWsusDevices.model_number,
                serial_number: addWsusDevices.serial_number,
                qu: addWsusDevices.qu,
                // qu_update_datetime: '', // #バックエンドの時間で上書きされる。
                prod: addWsusDevices.prod,
                // prod_update_datetime: '', // #バックエンドの時間で上書きされる。
                email: addWsusDevices.email,
                // update_user_id: '', // #バックエンドで上書きされる。
                enable: 'True',
                seq_id: addWsusDevices.seq_id
                // contract_number: '' // #無視される。
              },
              headers
            ).then((res) => {
              // WSUS契約更新が成功した時
              // 更新後の入力フィールドクリア
              // 以下でv-formの値リセットになるはずだが、動作がおかしいので個別に実施
              // this.$refs.wsus_form.reset()
              this.add_machine_id = ''
              this.add_model_number = ''
              this.add_serial_number = ''
              this.add_qu = 'ON'
              this.add_qu_prod = 'ON'
              this.add_email = ''
              this.add_contract_type = 'patch'
              this.add_contract_name = ''
              this.$refs.wsus_form.resetValidation()

              this.isLoading = false
              this.getRestAllWsusApi() // 更新後の一覧更新
            }).catch((error) => {
              // WSUS契約更新が失敗した時
              console.log(error)
              this.isLoading = false
              const postStatus = error.response.status
              if (postStatus === 400) {
                alert('WSUS契約更新に失敗\n情報が更新されています。リロードしてください。')
              } else {
                alert('WSUS契約更新に失敗\n通信エラーが起きました。リロードするか時間をおいてアクセスしてください。')
              }
            })
          } else {
            this.isLoading = false
            alert('装置情報が存在しません')
          }
        } else {
          console.log(error)
          // WSUS契約追加が失敗した時
          this.isLoading = false
          alert('WSUS契約追加に失敗\n通信エラーが起きました。リロードするか時間をおいてアクセスしてください。')
        }
      })
    }
  }
}
</script>

<style scoped>
.wsus_list /deep/ th { background-color:#dcdff1; border-left: 1px rgb(0,0,0, 0.12) solid;}
/* .wsus_list /deep/ td { background-color:#dcdff1; border-left: 1px rgb(0,0,0, 0.12) solid;} */
</style>
