import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

// ここに保存したい変数を設定する
const getDefaultState = () => {
  return {
    user: {
      orgid: '',
      org: '',
      id: '',
      name: '',
      role: [],
      token: ''
    }
  }
}
const getDefaultSystem = () => {
  return {
    system_info: {
      name: '',
      version: '',
      year: ''
    }
  }
}

export default new Vuex.Store({
  state: getDefaultState(),
  system: getDefaultSystem(),
  getters: {
    user: state => state.user,
    system: system => system.system_info
  },
  mutations: {
    reset (state) {
      // console.log('store reset')
      Object.assign(state, getDefaultState())
    },
    // ユーザー情報保存
    setUser (state, user) {
      state.user = user
    },
    setToken (state, token) {
      state.user.token = token
    },
    setOrgid (state, orgid) {
      state.user.orgid = orgid
    },
    setOrg (state, org) {
      state.user.org = org
    },
    // システム情報保存
    systemset (system) {
      Object.assign(system, getDefaultSystem())
    },
    setSystemName (system, systemName) {
      system.system_info.name = systemName
    },
    setSystemVer (system, systemVer) {
      system.system_info.ver = systemVer
    },
    setSystemYear (system, systemYear) {
      system.system_info.year = systemYear
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState(
    {
      key: 'RMT.store',
      storage: window.sessionStorage
    }
  )]
})
