import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import cognito from './components/cognito'
import axios from 'axios'
import 'vuetify/dist/vuetify.min.css'

Vue.config.productionTip = false
axios.defaults.baseURL = 'https://webapi.rmt.masterscope.jp/v1'

new Vue({
  router,
  store,
  vuetify,
  cognito,
  render: h => h(App)
}).$mount('#app')
