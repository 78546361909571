<template>
  <div>
    <div class="fullview">
      <div class="loading-spacer"></div>
      <vue-loading
        type="spiningDubbles"
        color="#aaa"
        :size="{ width: '100px', height: '100px' }"
      >
      </vue-loading>
    </div>
  </div>
</template>

<script>
import { VueLoading } from 'vue-loading-template'

export default {
  name: 'loading',
  components: {
    VueLoading
  }

}
</script>

<style scoped>
.fullview {
  background: #fefefe;
  position: absolute;
  top: 25%;
  left: 50%;
  margin: auto;
}
.loading-spacer {
  height: 0%;
}
</style>
