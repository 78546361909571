<template>
  <div>
    <loading v-show="isLoading"></loading>
    <div v-show="!isLoading">
    <div>
        <h1>{{ page_title }}</h1>
    </div>
    <div>
      <div>
        <v-simple-table
        id="user_edit"
        dense
        >
        <tbody>
          <tr>
            <th width="50px">ユーザ名</th>
            <td>
              <v-text-field
                name="name"
                v-model="user_info.name"
                placeholder="ユーザ名"
                label="ユーザの姓名を入力"
                :rules="[
                  required,
                  limit_length100
                ]"
                />
            </td>
          </tr>
          <tr>
            <th width="50px">所属</th>
            <td>
              <v-text-field
                name="department"
                v-model="user_info.department"
                placeholder="所属"
                label="所属を入力"
                :rules="[
                  limit_length300
                ]"
                />
            </td>
          </tr>
          <tr>
            <th width="50px">メールアドレス</th>
            <td>
              <v-text-field
                name="email"
                v-model="user_info.email"
                placeholder="メールアドレス"
                label="メールアドレスを入力"
                :rules="[
                  required,
                  limit_length254,
                  emailRules
                ]"
                />
            </td>
          </tr>
          <tr v-if="!is_superuser_orgid_80xxxx && !is_wsususer_orgid_7xxx2x">
            <th width="50px">ロール</th>
            <td width="200px">
              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="user_info.admin"
                    label="管理者"
                  />
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="user_info.operator"
                    label="作業者"
                  />
                </v-col>
                <v-col align-self="center">いずれかまたは<br>両方にチェック</v-col>
                <v-col/>
              </v-row>
            </td>
          </tr>
        </tbody>
        </v-simple-table>
      </div>
      <div>
        <br>
        <v-btn color="primary" @click="add_">登録</v-btn>
        <a>&nbsp;</a>
        <v-btn color="normal" @click="back_">戻る</v-btn>
      </div>
    </div>
    </div>  <!--v-show="!isLoading"-->
  </div>
</template>
<script>

import axios from 'axios'
import store from '@/store'
import Loading from '@/components/Loading'
import rmtlib from '@/plugins/class/rmtlib'

export default {
  components: {
    Loading
  },
  name: 'UserAddView',
  data: () => ({
    page_title: 'ユーザ登録',
    user_info: {
      ID: '',
      name: '',
      department: '',
      email: '',
      superuser: false,
      admin: false,
      operator: false,
      regist_date: ''
    },
    is_superuser_orgid_80xxxx: false, // スーパーユーザでログイン かつ 組織80XXXXを選択中の時、trueにする
    is_wsususer_orgid_7xxx2x: false,
    emailRules: value => /^((([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))$/.test(value) || 'メールアドレスの形式が不正です', // メール形式の制約
    required: value => !!value || '必ず入力してください', // 入力必須の制約
    limit_length100: value => value.length <= 100 || '100文字以内で入力してください', // 文字数の制約
    limit_length254: value => value.length <= 254 || '254文字以内で入力してください', // 文字数の制約
    limit_length255: value => value.length <= 255 || '255文字以内で入力してください', // 文字数の制約
    limit_length300: value => value.length <= 300 || '300文字以内で入力してください', // 文字数の制約
    isLoading: true
  }),
  methods: {
    back_: function () {
      console.log('UserAdd.vue back_: start')
      this.$router.push({ name: 'User' })
    },
    add_: function () {
      console.log('UserAdd.vue add_: start')
      var reg = /^((([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))$/
      if (this.user_info.name === '') {
        alert('ユーザ名を入力してください')
        return
      }
      if (this.user_info.name.length > 100) {
        alert('ユーザ名は100文字以内で入力してください')
        return
      }
      if (this.user_info.department.length > 300) {
        alert('所属は300文字以内で入力してください')
        return
      }
      if (this.user_info.email === '') {
        alert('メールアドレスを入力してください')
        return
      }
      if (this.user_info.email.length > 254) {
        alert('メールアドレスは254文字以内で入力してください')
        return
      }
      if (reg.test(this.user_info.email) === false) {
        alert('メールアドレスの形式が不正です')
        return
      }
      // (スーパーユーザでログイン かつ 組織80XXXXを選択中)または(WSUS組織)でない時、ロールの入力必須チェックを行う
      if ((this.is_superuser_orgid_80xxxx === false) && (this.is_wsususer_orgid_7xxx2x === false)) {
        // ロールの管理者、作業者のいずれかにチェックが入っていない状態か?
        if (!((this.user_info.admin === true) || (this.user_info.operator === true))) {
          alert('ロールはいずれか一方をチェックしてください')
          return
        }
      }
      if (!confirm('登録しますか？')) {
        console.log('canceled.')
        return
      }
      this.isLoading = true
      this.setRestApi()
    },
    setRestApi: function () {
      console.log('UserAdd.vue setRestApi: start')
      const orgid = store.getters.user.orgid
      // スーパーユーザでログイン かつ 組織80XXXXを選択中か?
      if (this.is_superuser_orgid_80xxxx === true) {
        this.user_info.superuser = true // スーパーユーザを作成する
        this.user_info.admin = false
        this.user_info.operator = false
      } else if (this.is_wsususer_orgid_7xxx2x === true) {
        this.user_info.superuser = false
        this.user_info.admin = true
        this.user_info.operator = true
      } else {
        this.user_info.superuser = false // スーパーユーザ属性でないユーザを作成する 管理者、作業者属性は画面で入力
      }
      const idToken = store.getters.user.token
      const headers = { headers: { Authorization: idToken } }
      axios.post('/users',
        {
          organization_id: orgid,
          name: this.user_info.name,
          department: this.user_info.department,
          email: this.user_info.email,
          superuser: this.user_info.superuser,
          admin: this.user_info.admin,
          operator: this.user_info.operator,
          enable: true,
          seq_id: '1'
        },
        headers
      ).then((res) => {
        // console.log('UserAdd.vue setRestApi: then 〇success')
        // console.log(res.data)
        this.isLoading = false
        this.$router.push({ name: 'User' }) // ユーザ一覧へ
      }).catch((error) => {
        // console.log('UserAdd.vue setRestApi: ★catch error=' + error)
        this.isLoading = false
        alert('ユーザ登録に失敗\n通信エラーが起きました。リロードするか時間をおいてアクセスしてください。\n' + error)
      })
    }
  },
  created: function () {
    console.log('UserAdd.vue created: start')
    console.log('UserAdd.vue created: this.$store.getters.user.orgid=' + this.$store.getters.user.orgid)
    // スーパーユーザでログイン かつ 組織80XXXXを選択中か
    if ((this.$store.getters.user.role[0]) && (this.$store.getters.user.orgid.slice(0, 2) === '80')) {
      this.is_superuser_orgid_80xxxx = true
    }
    // WSUS組織でのユーザ作成の場合
    if (rmtlib.isWsusOrg(this.$store.getters.user.orgid)) {
      this.is_wsususer_orgid_7xxx2x = true
    }
  },
  mounted: function () {
    console.log('UserAdd.vue mounted: start')
    // (スーパーユーザまたは管理者)以外か?
    if (!((this.$store.getters.user.role[0]) || (this.$store.getters.user.role[1]))) {
      this.isLoading = false
      alert('ユーザ登録の権限がありません')
      this.$router.push({ name: 'Top', params: { } })
      return false
    }
    this.isLoading = false
  }
}
</script>
<style scoped>
#user_edit table{ width:100%; }
#user_edit th{ width:50pt; background-color:#dcdff1; border: 1px #808080 solid; user-select: auto;}
#user_edit td{ width:200pt; border: 1px #808080 solid; text-align:left;}
.not_editable{ background-color: #cccccc ;}
input { border-color: red; }
</style>
