<template>
  <v-app>
    <main-header v-if="showNavigation"/>
    <v-navigation-drawer
      v-model="drawer"
      v-if="showNavigation"
      permanent
      app
      clipped
    >
      <v-divider></v-divider>
      <v-list>
        <v-list-item
          v-for="[icon, text, to] in links"
          :key="icon"
          :to="{ name: to, expact: true }"
          :exact=true
          link
        >
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view/>
    </v-main>
    <main-footer/>
  </v-app>
</template>

<script>
import MainFooter from './components/Footer'
import MainHeader from './components/Header'
import rmtlib from '@/plugins/class/rmtlib'

export default {
  name: 'App',
  components: {
    MainHeader,
    MainFooter
  },
  data: () => ({
    drawer: null,
    links: [],
    showNavigation: false,
    skipNavigation: [
      '/login', '/login/',
      '/organizationselect', '/organizationselect/'
    ],
    base_info: {
      system_name: 'ICT機器リモート運用基盤',
      version: '2.2.1',
      year: '2021'
    }
  }),
  created: function () {
    this.$store.commit('systemset') // システム情報の保存領域確保
    this.$store.commit('setSystemName', this.base_info.system_name) // システム名を設定
    this.$store.commit('setSystemVer', this.base_info.version) // システムバージョンを設定
    this.$store.commit('setSystemYear', this.base_info.year) // システム年を設定
  },
  mounted: function () {
    // console.log('App:mounted')
    this.loadMenu()
  },
  watch: {
    // Vue-Routerの遷移を検出して、メニューの表示・非表示を変更する。
    $route (to, from) {
      // console.log('App:route')
      this.setNavigation()
      this.loadMenu()
    }
  },
  methods: {
    setNavigation: function () {
      var path = this.$route.path
      // console.log(path)
      if (this.skipNavigation.includes(path.toLowerCase())) {
        this.showNavigation = false
      } else {
        this.showNavigation = true
      }
    },
    loadMenu: function () {
      this.links.length = 0
      if (rmtlib.isWsusOrg(this.$store.getters.user.orgid)) {
        this.links.push(['mdi-home', 'WSUS', 'Wsus_Setting'])
        this.links.push(['mdi-server-network', '機器一覧', 'Wsus_Device'])
        this.links.push(['mdi-database', 'パッケージ一覧', 'Wsus_Package'])
        this.links.push(['mdi-account', 'ユーザ一覧', 'User'])
      } else {
        this.links.push(['mdi-home', 'Top', 'Top'])
        if ((this.$store.getters.user.role[0]) || (this.$store.getters.user.role[1])) {
          this.links.push(['mdi-account', 'ユーザ一覧', 'User'])
        }
        if (((this.$store.getters.user.role[0]) && (this.$store.getters.user.orgid.slice(0, 2) !== '80')) || this.$store.getters.user.role[1]) {
          this.links.push(['mdi-file-document-multiple', '契約一覧', 'Contract'])
        }
        if (((this.$store.getters.user.role[0]) && (this.$store.getters.user.orgid.slice(0, 2) !== '80')) || this.$store.getters.user.role[1] || this.$store.getters.user.role[2]) {
          this.links.push(['mdi-server-network', '機器一覧', 'Device'])
          this.links.push(['mdi-database', 'パッケージ一覧', 'Package'])
        }
        // this.links.push(['mdi-amazon', 'About', 'About'])
      }
    }
  }
}
</script>

<style>
.v-data-table tr:first-child th {
  background-color:#dcdff1;
}
.v-data-table td {
  background: #f0f8ff;
}
.v-data-table tr:nth-child(odd) td {
  background: #fff;
}
.v-data-table tr:hover td {
    background-color: #eee;
}
</style>
