<template>
  <div>
    <loading v-show="isLoading"></loading>
    <div v-show="!isLoading">
      <div>
        <h1 id="id_title" >{{ title }}</h1>
      </div>
      <div id="id_table" class="organization_list">
        <div id="card_title">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              dense
              >
            </v-text-field>
          </v-card-title>
        </div>
        <div id="data_table">
          <v-data-table
            dense
            :headers="t_headers"
            :items="organizations"
            item-key="organization_id"
            :sort-by="['organization_id']"
            :search="search"
            @click:row="onClickOrganizationCell"
            >
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Loading from '@/components/Loading'

export default {
  name: 'OrganizationsListSelectView',
  data: () => ({
    title: 'テナント選択',
    search: '',
    t_headers: [
      // { v-radio: '選択', value: 'sel' },
      { text: 'テナントID', value: 'organization_id', width: 60 },
      { text: 'テナント名', value: 'organization_name', width: 200 }
    ],
    organizations: [],
    isLoading: true
  }),
  components: {
    Loading
  },
  mounted: function () {
    // console.log('OrganizationSelect.vue 4.mounted: start')
    if (this.$store.getters.user.role[0] === true) {
      this.getRestApi()
    } else {
      confirm('スーパーユーザではありません。ログイン画面に戻ります。')
      this.$store.commit('reset')
      this.$cognito.logout()
      this.$router.replace('/login')
    }
  },
  methods: {
    onClickOrganizationCell: function (data) {
      // console.log('OrganizationSelect.vue onClickOrganizationCell:' + data.organization_id)
      // console.log('store.orgid=' + this.$store.getters.user.orgid)
      this.$store.commit('setOrgid', data.organization_id)
      this.$store.commit('setOrg', data.organization_name)
      this.$router.push({ name: 'Top' })
    },
    getRestApi: function () {
      // console.log('OrganizationSelect.vue getRestApi:')
      const idToken = this.$store.getters.user.token
      const headers = { headers: { Authorization: idToken } }
      // console.log('OrganizationSelect.vue getRestApi: idToken=' + idToken)
      axios.get('/organizations',
        headers
      ).then((res) => {
        // console.log('OrganizationSelect.vue getRestApi: then get 〇success')
        this.organizations = res.data
        this.isLoading = false
      }).catch((error) => {
        console.log('OrganizationSelect.vue getRestApi: ★catch' + error)
        return false
      })
    }
  }

}
</script>
<style scoped>
.organization_list th{ background-color:#dcdff1; border: 1px #808080 solid;}
.organization_list td{ border: 1px #808080 solid; text-align:left;}
</style>
