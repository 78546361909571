<template>
  <v-app-bar app clipped-left>

    <v-app-bar-title>{{ title }}</v-app-bar-title>

    <v-spacer></v-spacer>

    <div><span class="org_info_css">テナント:{{ org_id }}({{ org_name }})</span></div>
    <div><span class="user_info_css">権限:{{ role }} ユーザ名:{{ username }}</span></div>

    <v-menu offset-y>
      <template v-slot:activator="{on}">
        <v-btn v-on="on" icon>
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="[icon, text, to] in links"
          :key="icon"
          :to="to"
          :exact=true
          link
        >
          <v-list-item-icon>
          <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <img src="../assets/img/logo_nec.png" alt="NEC">

  </v-app-bar>
</template>

<script>
export default {
  name: 'HeaderComponent',
  data: () => ({
    drawer: null,
    selected_prj: '',
    username: '',
    org_id: '',
    org_name: '',
    role: '',
    links: [
      ['mdi-account-edit', 'アカウント情報', '/AccountDetail'],
      ['mdi-logout', 'ログアウト', '/logout']
    ]
  }),
  created: function () {
    // console.log('Header:created')
    this.org_id = this.$store.getters.user.orgid
    this.org_name = this.$store.getters.user.org
    this.username = this.$store.getters.user.name
    // ヘッダーに表示する権限を編集する。 TODO 共通関数化
    this.role = (this.$store.getters.user.role[0] ? 'スーパーユーザ' : '')
    if (this.$store.getters.user.role[1]) {
      if (!(this.role.length === 0)) { this.role += ',' }
      this.role += '管理者'
    }
    if (this.$store.getters.user.role[2]) {
      if (!(this.role.length === 0)) { this.role += ',' }
      this.role += '作業者'
    }
  },
  watch: {
    //
  },
  computed: {
    title: function () {
      return this.$store.getters.system.name + ' Ver' + this.$store.getters.system.ver
    }
  },
  methods: {
    // TODO other methods
  }
}
</script>

<style scoped>
.mgr-10{
  margin-right : 10px;
}
.org_info_css{
  display: block;
  width: 220px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.user_info_css{
  display: block;
  width: 350px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
