<template>
  <div>
    <loading v-show="isLoading"></loading>
    <div v-show="!isLoading">
      <div>
        <h1 id="id_title1">{{ page_title }}</h1>
      </div>
      <div>
        <div>
          <div v-show="(actionType === 'reuse')">
            <!--- ********** 流用パッケージ作成のみ表示する箇所 ********** -->
            <v-simple-table id="package_table1">
              <tbody id="id_tbody_pkg_not_edit">
                <tr><th id="id_th_pkg_id">流用元ID</th>
                  <td id="id_td_pkg_id">
                    {{ base_pkg_id }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <br>
          </div>
          <!--- ********** パッケージ作成、流用パッケージ作成、削除パッケージ作成全てで表示する箇所 ********** -->
          <div>
            <v-simple-table id="package_table2">
              <tbody id="id_tbody_pkg_edit1">
                <tr>
                  <th id="id_th_pkg_name" width="50px">名称</th>
                  <td id="id_td_pkg_name">
                    <v-text-field
                      name="pkg_name"
                      v-model="package_info.pkg_name"
                      placeholder="名称"
                      label="名称を入力"
                      :rules="[
                        required,
                        limit_length50
                      ]"
                    >
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <th width="50px">概要</th>
                  <td>
                    <v-text-field
                      name="overview"
                      v-model="package_info.overview"
                      placeholder="パッケージ概要"
                      label="パッケージ概要を入力"
                      :rules="[
                        required,
                        limit_length100
                      ]"
                      />
                  </td>
                </tr>
                <tr>
                  <th width="50px">詳細</th>
                  <td>
                    <v-textarea
                      name="detail"
                      v-model="package_info.detail"
                      placeholder="パッケージ詳細"
                      label="パッケージ詳細を入力  (改行も1文字としてカウントします。)"
                      no-resize
                      rows="3"
                      :rules="[
                        required,
                        limit_length500
                      ]"
                      />
                  </td>
                </tr>
                <tr>
                  <th width="50px">対象装置</th>
                  <td>
                    <PackageDeviceSelect v-on:SelectDevice="receiveSelectDevice" :base_parm_info = "parm_info_for_device_list">
                    </PackageDeviceSelect>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <!--- ********** 削除パッケージ作成のみ表示する箇所 ********** -->
            <div v-show="(actionType === 'delete')">
              登録済みタスクの削除を行うパッケージを作成します
            </div>
            <v-simple-table id="package_table3">
              <!--- ********** 削除パッケージ作成のみ表示する箇所 ********** -->
              <tbody id="id_tbody_pkg_edit2" v-show="(actionType === 'delete')">
                <tr><th id="id_th_base_pkg_id" width="50px">ID(ベース)</th>
                  <td id="id_td_base_pkg_id">
                    {{ base_pkg_id }}
                  </td>
                </tr>
                <tr><th id="id_th_base_pkg_name" width="50px">名称(ベース)</th>
                  <td id="id_td_base_pkg_name">
                    {{ base_pkg_name }}
                  </td>
                </tr>
              </tbody>
              <!--- ********** パッケージ作成、流用パッケージ作成にて表示する箇所 ********** -->
              <tbody id="id_tbody_pkg_edit3" v-show="!(actionType === 'delete')">
                <tr>
                  <th width="50px">タスク終了コード</th>
                  <td>
                    <div>
                      <v-tabs v-model="tabidx" grow>
                        <v-tab
                          v-for="title in endcode_tab_titles"
                          :key="title.id"
                          :name="title.tabname"
                          @click="tab_click_"
                          v-bind:disabled="flagJsonTextErr"
                        >
                          {{ title.tabname }}
                        </v-tab>
                        <v-tab-item>
                          <v-card>
                            <v-card-text>
                              <!-- ************** タスク終了コード編集UI ************** -->
                              <!-- v-on:EndcodeTableは、子コンポーネントで更新した終了コードの情報を受信する指定 -->
                              <!-- :base_parm_infoは、子コンポーネントに親画面の特性を伝える指定 -->
                              <EndCodeList
                                v-on:EndcodeTable="receiveEndcodeTable"
                                :base_parm_info = "parm_info_for_endcode_list"
                                ref="EndCodeList"
                              >
                              </EndCodeList>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                        <v-tab-item>
                          <v-card>
                            <div>
                              <v-card-text>
                                終了コード(JSON)テキストを入力&nbsp;&nbsp;&nbsp;例.&nbsp;{"1":{"lv":1,"msg":"エラーMSG"},"other":{"lv":4,"msg":"-"}}
                                <v-textarea
                                  class="endjson"
                                  name="task_exit_code_file"
                                  v-model="package_info.task_exit_code_file"
                                  placeholder="JSON"
                                  label=""
                                  :rules="[
                                    required,
                                    limit_length20000
                                  ]"
                                  />
                              </v-card-text>
                            </div>
                            <div>
                              <p>終了コードのJSONテキストを入力後、[確定]ボタンを押してください。&nbsp;&nbsp;{{ this.statusJsonCheck }}</p>
                            </div>
                            <div>
                              <v-btn color="primary" @click="json_check_">確定</v-btn>
                              &nbsp;
                              <v-btn color="normal" @click="json_cancel_">キャンセル</v-btn><br>
                              &nbsp;
                            </div>
                          </v-card>
                        </v-tab-item>
                      </v-tabs>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th width="50px">実行処理</th>
                  <td>
                    <br>
                    実行スクリプトのファイル名
                    <v-text-field
                      name="execcmd"
                      v-model="package_info.exec.command"
                      placeholder=""
                      label=""
                      :rules="[
                        required
                      ]"
                      dense
                    >
                    </v-text-field>
                    実行スクリプトに与える引数
                    <v-text-field
                      name="execparm"
                      v-model="package_info.exec.arguments"
                      placeholder="実行スクリプトに与える引数"
                      label=""
                      dense
                    >
                    </v-text-field>
                    実行停止するまでの時間(HH:MM)
                    <v-text-field
                      name="execcmd"
                      v-model="package_info.exec.execution_time_limit"
                      placeholder=""
                      label=""
                      dense
                    >
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <th width="50px">確認処理</th>
                  <td>
                    <br>
                    確認スクリプトのファイル名
                    <v-text-field
                      name="execcmd"
                      v-model="package_info.check.check_task_command"
                      placeholder=""
                      label=""
                      dense
                    >
                    </v-text-field>
                    確認スクリプトに与える引数
                    <v-text-field
                      name="execparm"
                      v-model="package_info.check.check_task_arguments"
                      placeholder=""
                      label=""
                      dense
                    >
                    </v-text-field>
                    確認実行開始するまでの待ち時間(HH:MM)
                    <v-text-field
                      name="execcmd"
                      v-model="package_info.check.estimated_execution_time"
                      placeholder=""
                      label=""
                      dense
                    >
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <th width="50px">起動条件</th>
                  <td>
                    <v-radio-group v-model="package_info.trigger_select" row>
                      <v-radio label="1回" :value=1 v-on:change="change_trigger_">
                      </v-radio>
                      <v-radio label="毎日" :value=2 v-on:change="change_trigger_">
                      </v-radio>
                      <v-radio label="毎週" :value=3 v-on:change="change_trigger_">
                      </v-radio>
                      <v-radio label="即時実行" :value=4 v-on:change="change_trigger_">
                      </v-radio>
                    </v-radio-group>
                    <v-checkbox label="スケジュール時刻にタスクを開始できなかった場合、すぐにタスクを実行" v-model="package_info.trigger.required_immediate_execution" dense>
                    </v-checkbox>
                  </td>
                </tr>
                <tr>
                  <th width="50px">スケジュール</th>
                  <td>
                    <br>
                    <div v-show="flagInterval">
                      間隔{{ interval_unit }}
                      <v-text-field
                        name="interval"
                        v-model="package_info.trigger.interval"
                        placeholder="間隔"
                        label=""
                        dense
                        />
                    </div>
                    <div v-show="flagDayofweek">
                      指定する曜日:
                      <tbody id="id_day_of_week">
                        <td>
                          <v-checkbox label="(日)" v-model="package_info.sun" dense @click="day_of_week_click_">
                          </v-checkbox>
                        </td>
                        <td>
                          <v-checkbox label="(月)" v-model="package_info.mon" dense @click="day_of_week_click_">
                          </v-checkbox>
                        </td>
                        <td>
                          <v-checkbox label="(火)" v-model="package_info.tue" dense @click="day_of_week_click_">
                          </v-checkbox>
                        </td>
                        <td>
                          <v-checkbox label="(水)" v-model="package_info.wed" dense @click="day_of_week_click_">
                          </v-checkbox>
                        </td>
                        <td>
                          <v-checkbox label="(木)" v-model="package_info.thu" dense @click="day_of_week_click_">
                          </v-checkbox>
                        </td>
                        <td>
                          <v-checkbox label="(金)" v-model="package_info.fri" dense @click="day_of_week_click_">
                          </v-checkbox>
                        </td>
                        <td>
                          <v-checkbox label="(土)" v-model="package_info.sat" dense @click="day_of_week_click_">
                          </v-checkbox>
                        </td>
                      </tbody>
                    </div>
                    <div v-show="flagStartboundary">
                      開始日時
                      <VueCtkDateTimePicker
                        name='id_start_boundary'
                        format="YYYY-MM-DD HH:mm:00"
                        locale="lang"
                        label=""
                        position="top"
                        v-model="package_info.trigger.start_boundary"
                        readonly
                        dense
                        >
                      </VueCtkDateTimePicker>
                    </div>
                    <div v-show="flagEndboundary && flagStartboundary">
                      <br>
                    </div>
                    <div v-show="flagEndboundary">
                      有効期限日時
                      <VueCtkDateTimePicker
                        name='id_end_boundary'
                        format="YYYY-MM-DD HH:mm:00"
                        locale="lang"
                        label=""
                        position="top"
                        v-model="package_info.trigger.end_boundary"
                        readonly
                        dense
                        >
                      </VueCtkDateTimePicker>
                    </div>
                    <br>
                  </td>
                </tr>
                <tr>
                  <th width="50px">ファイル登録</th>
                  <td>
                    <div v-show="(actionType === 'reuse')">
                      <v-radio-group v-model="file_reuse_code">
                        <v-radio
                          label="新しいアップロードファイルを登録する。" :value=1
                          v-on:change="change_reuse_type_"
                        />
                        <v-radio
                          label="流用元のアップロードファイルを再利用する。" :value=2
                          v-on:change="change_reuse_type_"
                        />
                      </v-radio-group>
                    </div>
                    <br>
                    <div v-show="(actionType === 'reuse')">
                      <div v-show="(file_reuse_code === 2)">
                        File: {{ package_info.upload_file_name }}&nbsp;&nbsp;流用元ID:{{ base_pkg_id }}
                      </div>
                      <div v-show="(file_reuse_code === 1)">
                        File: {{ package_info.upload_file_name }}
                      </div>
                    </div>
                    <div v-show="!(actionType === 'reuse')">
                      File: {{ package_info.upload_file_name }}
                    </div>
                    <div v-show="(file_reuse_code === 1)">
                      <v-file-input
                        name="upload_file"
                        truncate-length="254"
                        accept="application/zip"
                        label="ファイル選択"
                        v-model="upload_filename_object"
                        v-bind:disabled="isUploading"
                      >
                      </v-file-input>
                      <v-btn color="primary" @click="upload_" v-bind:disabled="isUploading">{{isUploading_str}}</v-btn><br>
                    </div>
                    <br><a>&nbsp;</a>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </div>
        <div>
          <br>
          <v-btn color="primary" @click="regist_">登録</v-btn>
          <a>&nbsp;</a>
          <v-btn color="normal" @click="back_">戻る</v-btn>
          <br>
          <br>
        </div>
      </div>
    </div> <!-- v-show="!isLoading" -->
  </div>
</template>

<script>
import axios from 'axios'
import Loading from '@/components/Loading'
import PackageDeviceSelect from '@/components/package/PackageDeviceSelect'
import EndCodeList from '@/components/package/PackageEndcodeList'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import { convertEndcodeJsonArray, makeEndcodeJsonFormatText } from '@/components/common/PackageUtility.js'

export default {
  components: {
    VueCtkDateTimePicker,
    PackageDeviceSelect,
    EndCodeList,
    Loading
  },
  name: 'PackageAddView',
  data: () => ({
    page_title: 'パッケージ作成',
    file_reuse_code: 1, // 流用時の「File再選択」、「File再利用」の判別用コード
    //                    数字で指定する事
    //                    1:「File再選択」新しいアップロードファイルを登録する。    (divert_pkg_idが'')
    //                    2:「File再利用」流用元のアップロードファイルを再利用する。(divert_pkg_idが''以外)
    // ※DB登録時は、divert_pkg_idが''であるか''以外かで判別する
    upload_filename_object: {},
    tabidx: 0,
    actionType: '', // 'new': パッケージ作成, 'reuse': 流用パッケージ作成, 'delete': 削除用パッケージ作成
    endcode_tab_titles: [
      { id: 1, tabname: '終了コード一覧' },
      { id: 2, tabname: '終了コード(JSON)' }
    ],
    sel_device: [],
    endcode_list: [],
    endcode_list_renew: [],
    base_pkg_id: '',
    base_pkg_name: '',
    base_upload_file_name: '',
    upload_url: '',
    package_info: {
      pkg_id: '',
      pkg_name: '',
      overview: '',
      detail: '',
      task_exit_code_file: '',
      exec: { command: '', arguments: '', execution_time_limit: '' },
      check: { check_task_command: '', check_task_arguments: '', estimated_execution_time: '' },
      trigger: { trigger: '', required_immediate_execution: false, start_boundary: '', end_boundary: '', interval: '', day_of_week: [] },
      upload_file_name: '',
      divert_pkg_id: '',
      machines_list: [],
      trigger_select: 1, // 画面の起動条件とバインド 数字で指定する事
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      registration_datetime: ''
    },
    required: value => !!value || '必ず入力してください', // 入力必須の制約
    limit_length50: value => value.length <= 50 || '50文字以内で入力してください', // 文字数の制約
    limit_length100: value => value.length <= 100 || '100文字以内で入力してください', // 文字数の制約
    limit_length254: value => value.length <= 254 || '254文字以内で入力してください', // 文字数の制約
    limit_length255: value => value.length <= 255 || '255文字以内で入力してください', // 文字数の制約
    limit_length300: value => value.length <= 300 || '300文字以内で入力してください', // 文字数の制約
    limit_length500: value => value.length <= 500 || '500文字以内で入力してください', // 文字数の制約
    limit_length20000: value => value.length <= 20000 || '20000文字以内で入力してください', // 文字数の制約
    parm_info_for_device_list: {
      actionType: '',
      pkgid: ''
    },
    parm_info_for_endcode_list: {
      gamen_type: 'Add'
    },
    flagInterval: true, //      間隔を表示する時、true
    flagDayofweek: true, //     指定する曜日を表示する時、true
    flagStartboundary: true, // 開始日時を表示する時、true
    flagEndboundary: true, //   有効期限日時を表示する時、true
    strJsonArray: [],
    interval_unit: '',
    flagDayofweekCheck: false,
    nowDateTimeFormat: '', //  現在日付時間
    statusJsonCheck: '', //    終了コードのチェック状態(メッセージ)
    flagJsonTextErr: false, // 終了コードのチェック状態(エラーフラグ) true:エラー状態  false:JSON.parseでエラーがない状態(業務要件チェックは含まれない)
    isLoading: true,
    isUploading: false,
    isUploading_str: 'アップロード'
  }),
  watch: {
    // *******************************
    // 変数package_info更新時
    // *******************************
    package_info: {
      handler: function () {
        // ********** 起動条件 DB項目変換
        // console.log('this.package_info.trigger_select=<' + this.package_info.trigger_select + '>')
        if (this.package_info.trigger_select === 1) {
          if (!(this.package_info.trigger.trigger === 'one_time')) {
            this.package_info.trigger.trigger = 'one_time'
          }
        } else if (this.package_info.trigger_select === 2) {
          if (!(this.package_info.trigger.trigger === 'every_day')) {
            this.package_info.trigger.trigger = 'every_day'
          }
        } else if (this.package_info.trigger_select === 3) {
          if (!(this.package_info.trigger.trigger === 'every_week')) {
            this.package_info.trigger.trigger = 'every_week'
          }
        } else if (this.package_info.trigger_select === 4) {
          if (!(this.package_info.trigger.trigger === 'immediately')) {
            this.package_info.trigger.trigger = 'immediately'
          }
        } else {
          this.package_info.trigger.trigger = ''
        }
        const trigger = this.package_info.trigger.trigger
        // console.log('trigger_select=<' + trigger + '>')
        // ********** スケジュール DB項目変換
        this.flagInterval = false
        this.flagDayofweek = false
        this.flagStartboundary = false
        this.flagEndboundary = false
        if (trigger === 'one_time') {
          // console.log('one_time trigger=<' + trigger + '>')
          this.package_info.trigger_display = '１回'
          this.flagInterval = false
          this.flagDayofweek = false
          this.flagStartboundary = true //    開始日時（１回限り）
          this.flagEndboundary = true //      有効期限日時（１回限り）
          this.interval_unit = ''
        } else if (trigger === 'every_day') {
          // console.log('every_day trigger=<' + trigger + '>')
          this.package_info.trigger_display = '毎日'
          this.flagInterval = true //         間隔（日）
          this.flagDayofweek = false
          this.flagStartboundary = true //    開始日時（毎日）
          this.flagEndboundary = true //      有効期限日時（毎日）
          this.interval_unit = '（日）'
        } else if (trigger === 'every_week') {
          // console.log('every_week trigger=<' + trigger + '>')
          this.package_info.trigger_display = '毎週'
          this.flagInterval = true //         間隔（週）
          this.flagDayofweek = true //        指定する曜日
          this.flagStartboundary = true //    開始日時（毎週）
          this.flagEndboundary = true //      有効期限日時（毎週）
          this.interval_unit = '（週）'
        } else if (trigger === 'immediately') {
          // console.log('immediately trigger=<' + trigger + '>')
          this.package_info.trigger_display = '即時実行'
          this.flagInterval = false
          this.flagDayofweek = false
          this.flagStartboundary = false
          this.flagEndboundary = true //      有効期限日時
          this.interval_unit = ''
        } else {
          // console.log('else trigger=<' + trigger + '>')
          this.package_info.trigger_display = trigger // 予期しないtriggerの場合はそのまま表示
          this.flagInterval = true
          this.flagDayofweek = true
          this.flagStartboundary = true
          this.flagEndboundary = true
          this.package_info.interval_unit = ''
        }
        // ***
        if (!(this.package_info.trigger.day_of_week === '')) {
          this.flagDayofweekCheck = false
          this.package_info.trigger.day_of_week.forEach(dayOfWeekRow => {
            if (dayOfWeekRow === 'sunday') {
              this.package_info.sun = true
              this.flagDayofweekCheck = true
            } else if ((dayOfWeekRow === 'monday')) {
              this.package_info.mon = true
              this.flagDayofweekCheck = true
            } else if ((dayOfWeekRow === 'tuesday')) {
              this.package_info.tue = true
              this.flagDayofweekCheck = true
            } else if ((dayOfWeekRow === 'wednesday')) {
              this.package_info.wed = true
              this.flagDayofweekCheck = true
            } else if ((dayOfWeekRow === 'thursday')) {
              this.package_info.thu = true
              this.flagDayofweekCheck = true
            } else if ((dayOfWeekRow === 'friday')) {
              this.package_info.fri = true
              this.flagDayofweekCheck = true
            } else if ((dayOfWeekRow === 'saturday')) {
              this.package_info.sat = true
              this.flagDayofweekCheck = true
            }
          })
        }
        // *** 終了コードJSONテキストの動的エラーチェック、エラーあり時、タブ切替を無効化する
        this.statusJsonCheck = ''
        this.flagJsonTextErr = false
        if (!(this.package_info.task_exit_code_file === undefined)) {
          if (!(this.package_info.task_exit_code_file === '')) {
            let endcodeJsonObj = {}
            try {
              endcodeJsonObj = JSON.parse(this.package_info.task_exit_code_file)
            } catch (err) {
              this.flagJsonTextErr = true
              this.statusJsonCheck = '★現在のJSONテキストにエラーがあります。(' + err + ')'
            }
            endcodeJsonObj = '' // UNUSEDエラー回避
            this.statusJsonCheck += endcodeJsonObj + '' // UNUSEDエラー回避
          }
        }
      },
      deep: true
    }
  },
  methods: {
    // **************
    // 起動条件変更時
    // **************
    change_trigger_: function () {
      // ※注意 ここでthis.package_info.trigger_selectを参照しても変更前の「起動条件」選択値が参照されるので使えない
      // 流用時のみ「起動条件」this.package_info.trigger_selectを変更してもwatchでの変更イベントが発生しない為、別項目で強制的に変更イベントを発生させる
      this.package_info.trigger.required_immediate_execution = !(this.package_info.trigger.required_immediate_execution)
      this.package_info.trigger.required_immediate_execution = !(this.package_info.trigger.required_immediate_execution)
    },
    // *********************************
    // 流用時のファイル再利用選択変更時
    // *********************************
    change_reuse_type_: function () {
      // 2:File再利用 -> 1:File再選択 変更か?
      if (this.file_reuse_code === 2) {
        this.package_info.upload_file_name = ''
      } else {
        this.package_info.upload_file_name = this.base_upload_file_name
      }
    },
    // ***************************
    // 全角数字を半角数字に変換
    // ***************************
    convertHankakuNumber: function (str) {
      return str.replace(/[０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0)
      })
    },
    // ***************************
    // 終了コードJSON形式チェック
    // ***************************
    json_check_: function () {
      const endcodeJsonTextBackup = this.package_info.task_exit_code_file
      if (this.package_info.task_exit_code_file === '') {
        this.package_info.task_exit_code_file = '{"other":{"lv":4,"msg":"-"}}'
        alert('終了コード"other"が無くなっている為、初期状態の"other"の行を追加します。')
      }
      // ********** 入力された終了コードJSONテキストをJSON.parseで処理させ文法的に正しいかチェック
      // ********** 終了コードJSONテキスト -> 終了コードJSONオブジェクト
      const endcodeJsonText = this.package_info.task_exit_code_file
      // console.log('package_info.task_exit_code_file')
      // console.log(endcodeJsonText)
      let endcodeJsonObj = {}
      try {
        endcodeJsonObj = JSON.parse(endcodeJsonText)
      } catch (err) {
        console.log(endcodeJsonText)
        console.log('JSON.parseエラー\nerr=' + err)
        // console.log(err)
        alert('入力された終了コードJSONテキストが不正です。\n' + err)
        this.package_info.task_exit_code_file = endcodeJsonTextBackup
        return
      }
      // ***
      // this.package_info.task_exit_code_file = this.package_info.task_exit_code_file.replace(/\s+/g, ' ') // 全角空白の除去、半角空白化する (やめる)
      // this.package_info.task_exit_code_file = this.convertHankakuNumber(this.package_info.task_exit_code_file) // 全角数字を半角化 (やめる)
      // ********** 終了コードJSONオブジェクト->終了コード一覧用配列化
      this.endcode_list_renew = convertEndcodeJsonArray(endcodeJsonObj)
      // ****
      this.package_info.task_exit_code_file = makeEndcodeJsonFormatText(this.endcode_list_renew)
      // ****
      // console.log(this.endcode_list_renew)
      this.endcode_list_renew.forEach(endcodeListRow => {
        endcodeListRow.ID = this.convertHankakuNumber(endcodeListRow.ID)
        endcodeListRow.Lv = this.convertHankakuNumber(endcodeListRow.Lv + '')
        var ID = endcodeListRow.ID
        var Lv = endcodeListRow.important
        var Msg = endcodeListRow.message
        // console.log('ID:' + ID + ' important:' + Lv + ' message:' + Msg)
        if (!(ID === 'other')) {
          if (isNaN(ID)) {
            alert('終了コード=' + ID + 'のデータ箇所にて\n終了コードが半角数字またはotherではありません。')
            return
          } else {
            const intEndCode = parseInt(ID, 10)
            if ((intEndCode < 0) || (intEndCode > 4294967295)) {
              alert('終了コード=' + ID + 'のデータ箇所にて\n終了コードが不正です。0～4294967295の半角数字で入力してください。')
              return
            }
          }
        }
        if (Lv === undefined) {
          alert('終了コード=' + ID + 'のデータ箇所にて\nレベル指定 "lv":の指定がありません。 ')
        } else if (isNaN(Lv)) {
          alert('終了コード=' + ID + 'のデータ箇所にて\nレベル指定 "lv": が1～4の半角数字を指定してください。\n1:エラー, 2:警告, 3:情報, 4:その他')
          return
        } else {
          const intLv = parseInt(Lv, 10)
          if ((intLv < 1) || (intLv > 4)) {
            alert('終了コード=' + ID + 'のデータ箇所にて\nレベル指定 "lv": が不正です。1～4の半角数字で入力してください。\n1:エラー, 2:警告, 3:情報, 4:その他')
            return
          }
        }
        if (Msg === undefined) {
          alert('終了コード=' + ID + 'のデータ箇所にてメッセージ指定 "msg":の指定がありません。 ')
        } else if (Msg.length > 64) {
          alert('終了コード=' + ID + 'のデータ箇所にてメッセージ指定が64文字を越えています\n64文字以内で入力してください。 ')
        } else if (Msg.length === 0) {
          alert('終了コード=' + ID + 'のデータ箇所にてメッセージが入力されていません。\n1～64文字のメッセージを入力してください。')
        }
      })
      // **********  終了コード子画面に終了コード一覧Array変数を送信
      if (this.endcode_list_renew === []) {
        alert('入力された終了コードJSONテキストが不正の為、^n終了コード一覧を更新できません。')
      } else {
        this.$refs.EndCodeList.renewEndcodeInfo(this.endcode_list_renew)
      }
    },
    // *****************
    // JSONキャンセル時
    // *****************
    json_cancel_: function () {
      this.statusJsonCheck = '' //    終了コードのチェック状態(メッセージ)クリア
      this.flagJsonTextErr = false // 終了コードのチェック状態(エラーフラグ)クリア
      this.tabidx = 0
    },
    // *******************
    // 曜日指定クリック時
    // *******************
    day_of_week_click_: function () {
      // ********** 曜日指定 DB項目変換
      //            day_of_week: this.package_info.trigger.day_of_week //                                   [＜string＞, ...]  # 曜日のリスト "sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"
      this.flagDayofweekCheck = false
      const strArrayWeekOfDay = []
      if (this.package_info.sun) {
        strArrayWeekOfDay.push('sunday')
        this.flagDayofweekCheck = true
      }
      if (this.package_info.mon) {
        strArrayWeekOfDay.push('monday')
        this.flagDayofweekCheck = true
      }
      if (this.package_info.tue) {
        strArrayWeekOfDay.push('tuesday')
        this.flagDayofweekCheck = true
      }
      if (this.package_info.wed) {
        strArrayWeekOfDay.push('wednesday')
        this.flagDayofweekCheck = true
      }
      if (this.package_info.thu) {
        strArrayWeekOfDay.push('thursday')
        this.flagDayofweekCheck = true
      }
      if (this.package_info.fri) {
        strArrayWeekOfDay.push('friday')
        this.flagDayofweekCheck = true
      }
      if (this.package_info.sat) {
        strArrayWeekOfDay.push('saturday')
        this.flagDayofweekCheck = true
      }
      this.package_info.trigger.day_of_week = strArrayWeekOfDay
    },
    // ***********************
    // 終了コードTABクリック時
    // ***********************
    tab_click_: function () {
      // 「終了コード(JSON)」タブからのTABクリック移動か？
      if (this.tabidx === 1) {
        try {
          JSON.parse(this.package_info.task_exit_code_file)
        } catch (err) {
          console.log('JSON.parse err')
          alert('入力した終了コードJSONテキストが不正です。\n' +
                '終了コード一覧は更新できません。\n' +
                '終了コード(JSON)タブに戻り\n' +
                '[確定]ボタンを押して検証後にタブ切替を行ってください。')
          this.tabidx = 1
          return false
        }
        this.json_check_()
        return
      }
      // 「終了コード一覧」タブからのTABクリック移動か？
      // ******************
      // ********** 親画面のJSONテキスト枠に終了コード一覧から生成したテキストを生成
      this.package_info.task_exit_code_file = makeEndcodeJsonFormatText(this.endcode_list)
      this.json_check_() // 終了コードJSONテキストのソート成形目的
    },
    // ***********************
    // Boolean文字列->Boolean
    // ***********************
    toBooleanVal: function (booleanStr) {
      if (booleanStr.toLowerCase() === 'true') {
        return true
      } else {
        return false
      }
    },
    // ***********************
    // Boolean->Boolean文字列
    // ***********************
    toBooleanStr: function (booleanVal) {
      if (booleanVal) {
        return 'True'
      } else {
        return 'False'
      }
    },
    // **************
    // アップロード
    // **************
    upload_: function () {
      // ファイル選択後、×を押して消したか?
      if (this.upload_filename_object === null) { // upload_filename_objectがnull状態で.name参照するとundefinedとはならず処理が停止するので必要
        alert('クリップマークをクリックしファイルを選択してください。') // 1度ファイル選択し、×で消して[アップロード]ボタンを押すとこちらが出る
        return
      } else {
        if (this.upload_filename_object.name === undefined) {
          alert('クリップマークをクリックしファイルを選択してください。') // 無操作で[アップロード]ボタンを押すとこちらが出る
          return
        }
      }
      this.putUploadApi() // ファイルアップロード処理
    },
    // *******************************
    // 対象機器一覧更新受信時処理
    // *******************************
    receiveSelectDevice: function (value) {
      this.sel_device = value
    },
    // *******************************
    // 終了コード一覧更新受信時処理
    // *******************************
    receiveEndcodeTable: function (value) {
      // console.log('receiveEndcodeTable')
      this.endcode_list = value
      // console.log(this.endcode_list)
    },
    // *******************************
    // [戻る]ボタンクリック時
    // *******************************
    back_: function () {
      if (this.actionType === 'new') {
        this.$router.push({ name: 'Package', params: {} })
      } else if (this.actionType === 'reuse') {
        this.$router.push({ name: 'PackageDetail', params: { ID: this.base_pkg_id } })
      } else if (this.actionType === 'delete') {
        this.$router.push({ name: 'PackageDetail', params: { ID: this.base_pkg_id } })
      }
    },
    // *******************************
    // [登録]ボタンクリック時
    // *******************************
    regist_: function () {
      var regHHMM = /^[0-9][0-9]:[0-9][0-9]$/
      if (this.package_info.pkg_name === '') {
        alert('名称を入力してください')
        return
      }
      if (this.package_info.pkg_name.length > 50) {
        alert('名称は50文字以内で入力してください')
        return
      }
      if (this.package_info.overview === '') {
        alert('概要を入力してください')
        return
      }
      if (this.package_info.overview.length > 100) {
        alert('概要は100文字以内で入力してください')
        return
      }
      if (this.package_info.detail === '') {
        alert('詳細を入力してください')
        return
      }
      if (this.package_info.detail.length > 500) {
        alert('詳細は500文字以内で入力してください\n現在の詳細文字数=' + this.package_info.detail.length)
        return
      }
      if (this.sel_device.length <= 0) {
        alert('対象装置を1つ以上選択してください。')
        return
      }
      // パッケージ作成 or 流用パッケージ作成 の画面か?
      if ((this.actionType === 'new') || (this.actionType === 'reuse')) {
        // 終了コードJSONテキストがエラー状態か?
        if (this.flagJsonTextErr) {
          alert('終了コードJSONテキストがエラー状態です。修正してください。')
          return
        }
        // 実行処理-スクリプトファイル名が空欄か?
        if (this.package_info.exec.command === '') {
          alert('実行処理-スクリプトファイル名を入力してください。')
          return
        } else if (this.package_info.exec.command.length > 100) {
          alert('実行処理-スクリプトファイル名は100文字以内で入力してください。')
          return
        }
        // 実行処理-実行スクリプトに与える引数が空欄以外か?
        if (!(this.package_info.exec.arguments === '')) {
          if (this.package_info.exec.arguments.length > 255) {
            alert('実行処理-実行スクリプトに与える引数は255文字以内で入力してください。')
            return
          }
        }
        // 実行停止するまでの時間(HH:MM)に入力があるか?
        if (!(this.package_info.exec.execution_time_limit === '')) {
          // 実行停止するまでの時間(HH:MM)がHH:MMの形式となっていないか?
          if (regHHMM.test(this.package_info.exec.execution_time_limit) === false) {
            alert('実行停止するまでの時間(HH:MM)の入力形式が不正です')
            return
          } else {
            const intHH1 = parseInt(this.package_info.exec.execution_time_limit.slice(0, 2), 10)
            const intMM1 = parseInt(this.package_info.exec.execution_time_limit.slice(3, 5), 10)
            if (!((intHH1 >= 0) && (intHH1 <= 23))) {
              alert('実行停止するまでの時間(HH:MM)のHHは0～23の範囲で入力してください。')
              return
            }
            if (!((intMM1 >= 0) && (intMM1 <= 59))) {
              alert('実行停止するまでの時間(HH:MM)のMMは0～59の範囲で入力してください。')
              return
            }
          }
        }
        // 確認処理-確認スクリプトファイル名が空欄以外か?
        if (!(this.package_info.check.check_task_command === '')) {
          if (this.package_info.check.check_task_command.length > 100) {
            alert('確認処理-確認スクリプトファイル名は100文字以内で入力してください。')
            return
          }
        }
        // 確認処理-確認スクリプトに与える引数が空欄以外か?
        if (!(this.package_info.check.check_task_arguments === '')) {
          if (this.package_info.check.check_task_arguments.length > 255) {
            alert('確認処理-確認スクリプトに与える引数は255文字以内で入力してください。')
            return
          }
        }
        // 確認処理-確認スクリプトの引数 または
        // 確認処理-確認スクリプト実行までの待ち時間 の入力があるか？
        if ((!(this.package_info.check.check_task_arguments === '')) ||
            (!(this.package_info.check.estimated_execution_time === ''))) {
          // 確認処理-確認スクリプトファイル名が空欄か?
          if (this.package_info.check.check_task_command === '') {
            alert('確認処理の項目入力時は、確認処理-確認スクリプトファイル名を必ず入力してください。')
            return
          }
        }
        // 確認実行開始するまでの待ち時間(HH:MM)に入力があるか?
        if (!(this.package_info.check.estimated_execution_time === '')) {
          // 確認実行開始するまでの待ち時間(HH:MM)がHH:MMの形式となっていないか?
          if (regHHMM.test(this.package_info.check.estimated_execution_time) === false) {
            alert('確認実行開始するまでの待ち時間(HH:MM)の入力形式が不正です')
            return
          } else {
            const intHH2 = parseInt(this.package_info.check.estimated_execution_time.slice(0, 2), 10)
            const intMM2 = parseInt(this.package_info.check.estimated_execution_time.slice(3, 5), 10)
            if (!((intHH2 >= 0) && (intHH2 <= 23))) {
              alert('確認実行開始するまでの待ち時間(HH:MM)のHHは0～23の範囲で入力してください。')
              return
            }
            if (!((intMM2 >= 0) && (intMM2 <= 59))) {
              alert('確認実行開始するまでの待ち時間(HH:MM)のMMは0～59の範囲で入力してください。')
              return
            }
          }
        }
        // File選択してアップロードするパッケージの時か?
        if (this.file_reuse_code === 1) {
          if (this.package_info.upload_file_name === '') {
            alert('ファイルがアップロードされていません。\n[アップロード]ボタンを押してから[登録]ボタンを押してください。')
            return
          } else {
            // ファイル選択後、×を押して消したか?
            if (this.upload_filename_object === null) { // upload_filename_objectがnull状態で.name参照するとundefinedとはならず処理が停止するので必要 ×を押すとnullになる
            } else {
              if (this.upload_filename_object.name === undefined) { // アップロードファイルがありファイル選択していなければOK {}で初期化後の参照はこちら
              } else if (!(this.upload_filename_object.name === '')) {
                alert('再選択したファイルをアップロードしていません。\n再選択ファイルを×印で取り消すか\n[アップロード]ボタンを押してから[登録]ボタンを押してください。')
                return
              }
            }
          }
        }
        // 起動条件が「毎日」「毎週」の時か?
        if ((this.package_info.trigger_select === 2) ||
            (this.package_info.trigger_select === 3)) {
          if (isNaN(this.package_info.trigger.interval)) {
            alert('実行間隔には数字を入力してください。')
            return
          } else {
            const intInterval = parseInt(this.package_info.trigger.interval, 10)
            // スケジュールの間隔が0より大きいか?
            if (!(intInterval > 0)) {
              alert('スケジュールの間隔には0より大きい数字を入力してください。')
              return
            }
            // 「毎日」の時か?
            if (this.package_info.trigger_select === 2) {
              if (intInterval > 365) {
                alert('起動条件が「毎日」の時は間隔に1～365の範囲で入力してください。')
                return
              }
            }
            // 「毎週」の時か?
            if (this.package_info.trigger_select === 3) {
              if (intInterval > 52) {
                alert('起動条件が「毎日」の時は間隔に1～52の範囲で入力してください。')
                return
              }
            }
          }
        }
        // 起動条件が「毎週」の時か?
        if (this.package_info.trigger_select === 3) {
          // 曜日が1つも指定されていないか?
          if (!(this.flagDayofweekCheck)) {
            alert('起動条件が毎週の時、スケジュールの曜日を1つ以上選択してください。')
            return
          }
        }
        // 起動条件が「1回」「毎日」「毎週」の時か?
        if ((this.package_info.trigger_select === 1) ||
            (this.package_info.trigger_select === 2) ||
            (this.package_info.trigger_select === 3)) {
          if (this.package_info.trigger.start_boundary === '') {
            alert('開始日時を入力してください。')
            return
          }
        }
        // 有効期限日時入力がある場合
        if (!(this.package_info.trigger.end_boundary === '')) {
          // 有効期限日時は現在日付時間より未来であること
          if (this.package_info.trigger.end_boundary <= this.nowDateTimeFormat) {
            alert('有効期限日時は現在日付時間より未来を指定してください。')
            return
          }
        }
        // 起動条件が「1回」「毎日」「毎週」の時か?
        if ((this.package_info.trigger_select === 1) ||
            (this.package_info.trigger_select === 2) ||
            (this.package_info.trigger_select === 3)) {
          // 有効期限日時入力がある場合
          if (!(this.package_info.trigger.end_boundary === '')) {
            // 有効期限日時が開始日時より過去か?
            if (this.package_info.trigger.end_boundary < this.package_info.trigger.start_boundary) {
              alert('開始日時は有効期限日時より過去を指定してください。')
              return
            }
          }
        }
      }
      // ***
      if (!confirm('登録しますか？')) {
        return
      }
      this.isLoading = true
      this.putRestApi()
    },
    // *******************************
    // パッケージ情報 登録処理
    // *******************************
    putRestApi: function () {
      this.isLoading = true
      const idToken = this.$store.getters.user.token
      const headers = { headers: { Authorization: idToken } }
      var divertPkgId = ''
      var taskType = 'タスク登録' // タスク種類 'タスク登録' or 'タスク削除' this.package_info.task
      // 流用パッケージ作成の画面入力か？
      if (this.actionType === 'reuse') {
        taskType = 'タスク登録'
        // 流用元のアップロードファイルを再利用するか?
        if (this.file_reuse_code === 2) {
          divertPkgId = this.base_pkg_id
        } else {
          divertPkgId = ''
        }
        // 削除用パッケージ作成の画面入力か？
      } else if (this.actionType === 'delete') {
        taskType = 'タスク削除'
        divertPkgId = this.base_pkg_id
        // パッケージ作成(通常の新規作成)画面入力か？
      } else {
        taskType = 'タスク登録'
        divertPkgId = ''
      }
      // 起動条件が「毎日」「毎週」以外の時は、間隔変数を"0"に初期化する
      // 起動条件が「毎日」「毎週」以外の時か?
      if (!((this.package_info.trigger_select === 2) || (this.package_info.trigger_select === 3))) {
        this.package_info.trigger.interval = '0'
      }
      // 曜日指定は起動条件が「毎週」以外の時は曜日指定変数を空配列で初期化する
      // 起動条件が「毎週」の時か?
      if (!(this.package_info.trigger_select === 3)) {
        this.package_info.trigger.day_of_week = []
      }
      // 開始日時は起動条件が「即時実行」の時は開始日時変数を初期化する
      // 起動条件が「毎週」の時か?
      if (this.package_info.trigger_select === 4) {
        this.package_info.trigger.start_boundary = ''
      }
      // 対象機器のマシンID文字列配列を編集
      var arrayMachineId = []
      this.sel_device.forEach(selDdeviceRow => {
        arrayMachineId.push(selDdeviceRow.machine_id)
      })
      // boolean項目を明示的に対応する文字列に変換
      var strRequiredImmediateExecution = this.toBooleanStr(this.package_info.trigger.required_immediate_execution)
      // 終了コードJSONテキスト生成
      this.package_info.task_exit_code_file = makeEndcodeJsonFormatText(this.endcode_list)
      this.json_check_() // 終了コードJSONテキストのソート成形目的
      // 終了コードJSONから改行を除去
      this.package_info.task_exit_code_file = this.package_info.task_exit_code_file.replace(/\n/g, '')
      // リクエストデータ組み立て
      var requestObj = {}
      if (this.actionType === 'delete') {
        // 削除パッケージ作成の時はこちら
        requestObj =
        {
          pkg_name: this.package_info.pkg_name, // ＜string＞ # 名称
          overview: this.package_info.overview, // ＜string＞ # 概要
          detail: this.package_info.detail, //     ＜string＞ # 詳細
          task: taskType, //                       ＜string＞ # 種別 タスク登録/タスク削除
          category: '', //                         ＜string＞ # カテゴリー WSUSパッケージ以外では''(値なし固定)
          environment: '', //                      ＜string＞ # 環境       WSUSパッケージ以外では''(値なし固定)
          //                                                  # 登録日 は内部で設定。
          //                                                  # 作業者(ユーザID) は内部で設定。
          //                                                  # 処理ステータス は内部で設定。
          task_exit_code_file: '', //              ＜string＞ # タスク終了コード(JSON)テキスト
          exec: {
            command: '', //                        ＜string＞ # スクリプトファイル名
            arguments: '', //                      ＜string＞ # 引数
            execution_time_limit: '' //            ＜string＞ # タイムアウト
          },
          check: {
            check_task_command: '', //             ＜string＞ # 確認スクリプトファイル名
            check_task_arguments: '', //           ＜string＞ # 確認スクリプトの引数
            estimated_execution_time: '' //        ＜string＞ # 確認スクリプト実行までの待ち時間
          },
          trigger: {
            trigger: '', //                                     ＜string＞ # 起動条件
            required_immediate_execution: 'False', //           ＜string＞ # スケジュール時刻にタスクを開始できなかった場合、すぐにタスクを実行
            start_boundary: '', //                              ＜string＞ # 開始日時     画面からはYYYY-MM-DD HH:MMで入力 秒':00'を固定で付加
            end_boundary: '', //                                ＜string＞ # 有効期限日時 画面からはYYYY-MM-DD HH:MMで入力 秒':00'を固定で付加
            interval: '0', //                                   ＜string＞ # 間隔(日)
            day_of_week: [] //                                 [＜string＞, ...]  # 曜日のリスト "sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"
          },
          upload_file_name: '', //                              ＜string＞        # アップロードファイル名
          divert_pkg_id: divertPkgId, // <--削除対象のタスク    ＜string＞        # アップロードファイル流用元のID
          machines_list: arrayMachineId //                     [＜string＞, ...]  # 装置IDのリスト
        }
      } else {
        // パッケージ作成や流用パッケージ作成の時はこちら
        requestObj =
        {
          pkg_name: this.package_info.pkg_name, // ＜string＞ # 名称
          overview: this.package_info.overview, // ＜string＞ # 概要
          detail: this.package_info.detail, //     ＜string＞ # 詳細
          task: taskType, //                       ＜string＞ # 種別 タスク登録/タスク削除
          category: '', //                         ＜string＞ # カテゴリー WSUSパッケージ以外では''(値なし固定)
          environment: '', //                      ＜string＞ # 環境       WSUSパッケージ以外では''(値なし固定)
          //                                                  # 登録日 は内部で設定。
          //                                                  # 作業者(ユーザID) は内部で設定。
          //                                                  # 処理ステータス は内部で設定。
          task_exit_code_file: this.package_info.task_exit_code_file, //                  ＜string＞ # タスク終了コード(JSON)テキスト
          exec: {
            command: this.package_info.exec.command.replace(/'/g, ''), //                                   ＜string＞ # スクリプトファイル名
            arguments: this.package_info.exec.arguments.replace(/'/g, ''), //                               ＜string＞ # 引数
            execution_time_limit: this.package_info.exec.execution_time_limit //          ＜string＞ # タイムアウト
          },
          check: {
            check_task_command: this.package_info.check.check_task_command.replace(/'/g, ''), //            ＜string＞ # 確認スクリプトファイル名
            check_task_arguments: this.package_info.check.check_task_arguments.replace(/'/g, ''), //        ＜string＞ # 確認スクリプトの引数
            estimated_execution_time: this.package_info.check.estimated_execution_time // ＜string＞ # 確認スクリプト実行までの待ち時間
          },
          trigger: {
            trigger: this.package_info.trigger.trigger, //                         ＜string＞, # 起動条件
            required_immediate_execution: strRequiredImmediateExecution, //        ＜string＞, # スケジュール時刻にタスクを開始できなかった場合、すぐにタスクを実行
            start_boundary: this.package_info.trigger.start_boundary, //                                      ＜string＞, # 開始日時     画面からはYYYY-MM-DD HH:MMで入力 秒':00'を固定で付加
            end_boundary: this.package_info.trigger.end_boundary, //                                          ＜string＞, # 有効期限日時 画面からはYYYY-MM-DD HH:MMで入力 秒':00'を固定で付加
            interval: this.package_info.trigger.interval, //                       ＜string＞, # 間隔(日)
            day_of_week: this.package_info.trigger.day_of_week //                 [＜string＞, ...]  # 曜日のリスト "sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"
          },
          upload_file_name: this.package_info.upload_file_name, //                 ＜string＞,       # アップロードファイル名
          divert_pkg_id: divertPkgId, //                                           ＜string＞,       # アップロードファイル流用元のID
          machines_list: arrayMachineId //                                        [＜string＞, ...]  # 装置IDのリスト
        }
      }
      axios.put('/packages/' + this.package_info.pkg_id,
        requestObj,
        headers
      ).then((res) => {
        this.isLoading = false
        this.$router.push({ name: 'PackageDetail', params: { id: this.package_info.pkg_id } }) // 〇登録成功したらパッケージ詳細に遷移する
      }).catch((error) => {
        console.log('putRestApi: catch=' + error)
        this.isLoading = false
        alert('パッケージ作成に失敗\n通信エラーが起きました。リロードするか時間をおいてアクセスしてください。\n' + error)
      })
    },
    // *******************************
    // パッケージ情報 取得処理
    // *******************************
    getRestApi: function () {
      axios.get('/packages/' + this.$route.params.id,
        { headers: { Authorization: this.$store.getters.user.token } }
      ).then((res) => {
        // 流用画面遷移時点でパッケージ情報が読めた時
        // pkg_idは変更しない。upload_urlはDBにはないので対処不要。
        res.data.pkg_id = this.package_info.pkg_id
        this.package_info = res.data
        // 読み込んだパッケージ情報の種別が「タスク削除」以外か?
        if (!(this.package_info.task === 'タスク削除')) {
          // console.log('this.package_info.task_exit_code_file')
          this.json_check_() // JSONテキストを確認し、終了コード子画面の一覧内容を更新する
        }
        this.package_info.trigger.required_immediate_execution = this.toBooleanVal(this.package_info.trigger.required_immediate_execution) // 文字'True' -> boolean値'true', 文字'False' -> boolean値'false'
        this.base_pkg_id = this.$route.params.id
        this.base_pkg_name = this.package_info.pkg_name
        this.base_upload_file_name = this.package_info.upload_file_name
        this.package_info.pkg_name = '' // これから入力する名称を初期化する
        this.package_info.upload_file_name = '' // これから入力するアップロードファイル名を初期化する
        this.isLoading = false
        // ***
        if (this.package_info.trigger.trigger === 'one_time') {
          this.package_info.trigger_select = 1
        } else if (this.package_info.trigger.trigger === 'every_day') {
          this.package_info.trigger_select = 2
        } else if (this.package_info.trigger.trigger === 'every_week') {
          this.package_info.trigger_select = 3
        } else if (this.package_info.trigger.trigger === 'immediately') {
          this.package_info.trigger_select = 4
        }
        // 読み込んだパッケージ情報の種別が「タスク削除」か?
        if (this.package_info.task === 'タスク削除') {
          // 読み込んだパッケージ情報の種別が「タスク削除」の時は不正な画面遷移である為、パッケージ一覧に戻す。
          this.isLoading = false
          alert('種別が「タスク削除」のパッケージに対して\n流用パッケージ作成や削除パッケージ作成はできません。\nパッケージ一覧に戻ります。')
          this.$router.push({ name: 'Package', params: {} }) // パッケージ一覧へ遷移
        }
      }).catch((error) => {
        // 流用画面遷移時点でパッケージ情報がなくなった時、通信上のエラーの時
        console.log('getRestApi error=' + error)
        this.isLoading = false
        alert('パッケージ情報取得に失敗\n通信エラーが起きました。リロードするか時間をおいてアクセスしてください。\n' + error)
        this.$router.push({ name: 'Package', params: {} }) // パッケージ一覧へ遷移
      })
    },
    // *********************************************
    // ID採番、アップロード先URL 取得処理
    // *********************************************
    postRestApi: function () {
      // console.log('PackageAdd.vue postRestApi:')
      const orgid = this.$store.getters.user.orgid
      const idToken = this.$store.getters.user.token
      const headers = { headers: { Authorization: idToken }, params: { org: orgid } }
      axios.post('/packages', {},
        headers
      ).then((res) => {
        this.package_info.pkg_id = res.data.pkg_id
        this.upload_url = res.data.upload_url
      }).catch((error) => {
        console.log('postRestApi error=' + error)
        this.package_info.pkg_id = ''
        this.upload_url = ''
        this.isLoading = false
        alert('ID採番、アップロードURL取得に失敗、処理を継続できません。\n通信エラーが起きました。リロードするか時間をおいてアクセスしてください。\n' + error)
        if (this.actionType === 'new') {
          this.$router.push({ name: 'Package', params: {} }) // 遷移元画面 パッケージ一覧へ戻す
        } else if ((this.actionType === 'reuse') || (this.actionType === 'delete')) {
          this.$router.push({ name: 'PackageDetail', params: { id: this.$route.params.id } }) // 遷移元画面 パッケージ詳細へ戻す
        } else {
          this.$router.push({ name: 'Package', params: {} }) // 通常あり得ない とりあえずパッケージ一覧へ戻す
        }
      })
    },
    // **************************
    // ファイルアップロード処理
    // *************************
    putUploadApi: function () {
      this.isUploading = true
      this.isUploading_str = 'アップロード中'
      const headers = { headers: { 'Content-Type': this.upload_filename_object.type } }
      axios.put(this.upload_url,
        this.upload_filename_object,
        headers
      ).then((res) => {
        this.package_info.upload_file_name = this.upload_filename_object.name
        this.upload_filename_object = {} // アップロードが成功したら[ファイル選択]で保持したファイル情報を初期化する
        this.isUploading_str = 'アップロード'
        this.isUploading = false
      }).catch((error) => {
        console.log('putUploadApi: error=' + error)
        this.isUploading_str = 'アップロード'
        this.isUploading = false
        alert('ファイルアップロードに失敗しました。\n通信エラーが起きました。リロードするか時間をおいてアクセスしてください。\n' + error)
      })
    }
  },
  // *******************************
  // モジュール生成時
  // *******************************
  created: function () {
    // **********
    // ***
    this.postRestApi() // ID採番、アップロード先URL 取得
    // **********
    const nowDateTime = new Date() // 現在の日付時間取得
    this.nowDateTimeFormat =
          ('0000' + nowDateTime.getFullYear()).slice(-4) + '-' +
          ('00' + (nowDateTime.getMonth() + 1).toString(10)).slice(-2) + '-' +
          ('00' + nowDateTime.getDate()).slice(-2) + ' ' +
          ('00' + nowDateTime.getHours()).slice(-2) + ':' +
          ('00' + nowDateTime.getMinutes()).slice(-2) // 現在日付時間を"YYYY-MM-DD HH:MM"に編集
    // ***
    if (this.$route.params.actionType === undefined) {
      this.actionType = 'new'
      this.page_title = 'パッケージ作成'
      this.parm_info_for_device_list.actionType = this.actionType
      this.parm_info_for_device_list.pkgid = this.package_info.pkg_id
    } else {
      this.actionType = this.$route.params.actionType
      if (this.actionType === 'reuse') {
        this.page_title = '流用パッケージ作成'
        this.parm_info_for_device_list.actionType = this.actionType
        this.parm_info_for_device_list.pkgid = this.$route.params.id
      } else if (this.actionType === 'delete') {
        this.page_title = '削除用パッケージ作成'
        this.parm_info_for_device_list.actionType = this.actionType
        this.parm_info_for_device_list.pkgid = this.$route.params.id
      }
    }
  },
  // *******************************
  // 画面マウント時
  // *******************************
  mounted: function () {
    // (スーパーユーザ)か?
    if (this.$store.getters.user.role[0]) {
      var strFormName = ''
      this.isLoading = false
      if (this.actionType === 'new') {
        strFormName = 'パッケージ作成'
      } else if (this.actionType === 'reuse') {
        strFormName = '流用パッケージ作成'
      } else if (this.actionType === 'delete') {
        strFormName = '削除パッケージ作成'
      } else {
        strFormName = 'パッケージ作成'
      }
      alert('スーパーユーザは' + strFormName + '画面の操作権限がありません。\nパッケージ詳細画面などの照会のみ可能です。')
      this.$router.push({ name: 'Package', params: {} })
      return false
    }
    // ***
    // パッケージ作成以外か?
    if (!(this.actionType === 'new')) {
      this.getRestApi() // 流用パッケージ作成、削除用パッケージ作成時に画面表示に必要な情報を取得
    }
    // *** パッケージ作成の時か?
    if (this.actionType === 'new') {
      this.isLoading = false // パッケージ作成の時はDB i/oがないのでここでローディング表示終了 その他はi/o完了後
    }
  }
}
</script>
<style scoped>
#package_table1 table{ width:100%; }
#package_table1 th{ width: 150pt; background-color:#dcdff1; border: 1px #808080 solid; user-select: auto;}
#package_table1 td{ border: 1px #808080 solid; text-align:left;}
#package_table2 table{ width:100%; }
#package_table2 th{ width: 150pt; background-color:#dcdff1; border: 1px #808080 solid; user-select: auto;}
#package_table2 td{ border: 1px #808080 solid; text-align:left;}
#package_table3 table{ width:100%; }
#package_table3 th{ width: 150pt; background-color:#dcdff1; border: 1px #808080 solid; user-select: auto;}
#package_table3 td{ border: 1px #808080 solid; text-align:left;}
#id_day_of_week td{ width: 80px; border: 0px #808080 solid; text-align:left;}
.endjson { border: 0px #808080 solid; text-align:left;}
.sampleTable {
    border-collapse: collapse;
    table-layout: fixed;
}
.not_editable{ background-color: #cccccc ;}
input { border-color: red; }
</style>
