<template>
  <div>
    <loading v-show="isLoading"></loading>
    <div v-show="!isLoading">
      <div>
        <h1 id="id_title1">{{ title }}</h1>
      </div>
      <div>
        <div>
          <v-simple-table id="user_l">
            <tbody id="id_tbody_user">
              <tr><th id="id_th_user">ユーザID</th>
                <td id="id_td_user"> {{user_info.user_id }}
                </td>
              </tr>
              <tr><th id="id_th_name">ユーザ名</th>
                <td id="id_td_name"> {{user_info.name }}
                </td>
              </tr>
              <tr><th id="id_th_department">所属</th>
                <td id="id_td_department"> {{  user_info.department }}
                </td>
              </tr>
              <tr><th id="id_th_email"     >メールアドレス</th>
                <td id="id_td_email"> {{ user_info.email }}
                </td>
              </tr>
              <tr><th id="id_th_role"      >ロール</th>
                <td id="id_td_role"> {{  user_info.superuser ? 'スーパーユーザ' : '' }}
                  {{ (user_info.superuser && user_info.admin   ) ? '&nbsp;' : '' }}
                  {{  user_info.admin ? '管理者' : '' }}
                  {{((user_info.superuser || user_info.admin) && user_info.operator) ? '&nbsp;' : '' }}
                  {{  user_info.operator ? '作業者' : '' }}
                </td>
              </tr>
              <tr><th id="id_th_regdt"     >登録日        </th>
                <td id="id_td_regdt" > {{  user_info.add_datetime}}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
        <div>
          <br>
          <v-btn color="primary" @click="edit_">編集</v-btn>
          <a>&nbsp;</a>
          <v-btn color="normal" @click="back_">戻る</v-btn>
          <a>&nbsp;</a>
          <v-btn color="secondary" @click="pwreset_">パスワードリセット</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import axios from 'axios'
import store from '@/store'
import Loading from '@/components/Loading'

export default {
  components: {
    Loading
  },
  name: 'UserDetail',
  data: () => ({
    title: 'アカウント詳細',
    myuser_info: {},
    user_info: {
      user_id: '',
      name: '',
      department: '',
      email: '',
      superuser: false,
      admin: false,
      operator: false,
      add_datetime: ''
    },
    userid: '',
    isLoading: true
  }),
  methods: {
    edit_: function () {
      console.log('AccountDetail.vue methods: edit_ start')
      this.$router.push({ name: 'AccountEdit', params: {} })
    },
    back_: function () {
      console.log('AccountDetail.vue methods: back_ start')
      this.$router.push({ name: 'Top', params: {} })
    },
    pwreset_: function () {
      console.log('AccountDetail.vue methods: pwreset_ start')
      if (!confirm('パスワードリセットしますか？')) {
        console.log('canceled.')
        return
      }
      this.isLoading = true
      this.resetRestApi()
    },
    resetRestApi: function () {
      const idToken = store.getters.user.token
      const headers = { headers: { Authorization: idToken } }
      axios.post('/users/me/passreset',
        {},
        headers
      ).then((res) => {
        this.isLoading = false
        // console.log('自ユーザのパスワードリセット 〇success.')
        alert('自ユーザのパスワードリセットに成功しました。')
      }).catch((error) => {
        // console.log('自ユーザのパスワードリセットに失敗しました。★catch.')
        this.isLoading = false
        alert('自ユーザのパスワードリセットに失敗しました。\n' + error)
      })
    },
    getRestApi: function () {
      // console.log('AccountDetail.vue methods: getRestApi: start')
      // console.log('AccountDetail.vue userid=' + this.$route.params.id)
      const idToken = store.getters.user.token
      const headers = { headers: { Authorization: idToken } }
      axios.get('/users/me',
        headers
      ).then((res) => {
        // console.log('AccountDetail.vue getRestApi: then 〇success')
        this.isLoading = false
        this.user_info = res.data
      }).catch((error) => {
        // console.log('AccountDetail.vue getRestApi: ★catch')
        this.isLoading = false
        alert('ログインユーザ情報取得に失敗\n通信エラーが起きました。リロードするか時間をおいてアクセスしてください。\n' + error)
        return false
      })
    }
  },
  mounted: function () {
    console.log('AccountDetail.vue mounted: start')
    this.getRestApi()
  }
}
</script>
<style scoped>
#user_l table{ width:100%; }
#user_l th{ min-width:40px; max-width:50px; background-color:#dcdff1; border: 1px #808080 solid; user-select: auto;}
#user_l td{ border: 1px #808080 solid; text-align:left;}
</style>
