<template>
  <div>
    <div v-show="(parm_info.gamen_type === 'Add')">
      <v-btn color="error" @click="del_end_code_">削除</v-btn>&nbsp;&nbsp;削除する場合は行のチェックボックスを選択し[削除]ボタンを押してください。otherの行は削除できません。
    </div>
    <div class="device_list">
      <div id="card_title">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            dense
            >
          </v-text-field>
        </v-card-title>
      </div>
      <v-data-table v-show="(parm_info.gamen_type === 'Add')"
        v-model="selected"
        :headers="t_headers"
        :items="endcode_list"
        item-key="ID"
        :search="search"
        :sort-by="['ID']"
        show-select
        @click:row="onClickDeviceCell"
        dense
      >
      </v-data-table>
      <v-data-table v-show="(parm_info.gamen_type === 'Detail')"
        v-model="selected"
        :headers="t_headers"
        :items="endcode_list"
        item-key="ID"
        :search="search"
        :sort-by="['ID']"
        @click:row="onClickDeviceCell"
        dense
      >
      </v-data-table>
    </div>
    <br>
    <div v-show="(parm_info.gamen_type === 'Add')">
      <table id="id_end_code_row" class="sampleTable">
        <tr>
          <td width="200">
            <v-text-field id="id_end1"
              name="add_end_code"
              v-model.trim="add_end_code"
              placeholder="終了コード"
              label=""
              :rules="[
                limit_length100
              ]"
              dense
              />
          </td>
          <a>&nbsp;</a>
          <td width="200">
            <v-select
              v-model="add_important_level"
              :items="important_levels"
              item-text="levelname"
              item-value="levelid"
              label=""
              placeholder="レベル"
              dense
            />
          </td>
          <a>&nbsp;</a>
          <td width="500">
            <v-text-field id="id_end3"
              name="add_message"
              v-model="add_message"
              placeholder="メッセージ"
              label=""
              :rules="[
                limit_length100
              ]"
              dense
              />
          </td>
        </tr>
      </table>
      <div>
        <v-btn color="primary" @click="add_end_code_">追加更新</v-btn>&nbsp;&nbsp;終了コード登録は上記の行を入力後[追加更新]ボタンを押してください。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EndCodeList',
  data: () => ({
    add_end_code: '',
    add_important_level: 0,
    add_message: '',
    important_levels: [
      { levelname: 'レベルを選択', levelid: 0 },
      { levelname: 'エラー', levelid: 1 },
      { levelname: '警告', levelid: 2 },
      { levelname: '情報', levelid: 3 },
      { levelname: 'その他', levelid: 4 }
    ],
    selected: [],
    search: '',
    t_headers: [
      { text: '終了コード', value: 'ID' },
      { text: 'レベル', value: 'important_name' },
      { text: 'メッセージ', value: 'message' }
    ],
    endcode_list: [ // 終了コード一覧はこの変数配列を表示する
      { ID: 'other', important: 4, important_name: 'その他', message: '-' } // パッケージ作成の初期状態
      // 流用パッケージ作成や削除パッケージ作成ではDB内容から更新される
    ],
    endcode_list_update: [], // 終了コード一覧更新時に使用する。作成で問題なければendcode_listに代入
    parm_info: {},
    limit_length100: value => value.length <= 100 || '100文字以内で入力してください', // 文字数の制約
    limit_length254: value => value.length <= 254 || '254文字以内で入力してください', // 文字数の制約
    limit_length255: value => value.length <= 255 || '255文字以内で入力してください', // 文字数の制約
    limit_length300: value => value.length <= 300 || '300文字以内で入力してください' //  文字数の制約
  }),
  props: {
    base_parm_info: {}
  },
  watch: {
    endcode_list: {
      handler: function () {
        // console.log('PackageDeviceSelect.vue watch: endcode_list')
        // ********** レベルを表示文字列に変換 TODO 共通関数化すること
        this.endcode_list.important_name = this.endcode_list.important
        this.endcode_list.forEach(element => {
          if (element.important === 1) {
            element.important_name = 'エラー'
          } else if (element.important === 2) {
            element.important_name = '警告'
          } else if (element.important === 3) {
            element.important_name = '情報'
          } else if (element.important === 4) {
            element.important_name = 'その他'
          }
          // console.log('this.endcode_list.important_name=' + this.endcode_list.important_name)
        })
        // **********
        // 親画面に子画面の終了コード一覧配列を親に送信
        this.$emit('EndcodeTable', this.endcode_list)
      }
    }
  },
  methods: {
    renewEndcodeInfo: function (endcodeInfoRenew) {
      // console.log('PackageEndcodeList.vue renewEndcodeInfo:')
      this.endcode_list = endcodeInfoRenew
    },
    onClickDeviceCell: function (data) {
      // console.log('PackageDeviceSelect.vue onClickDeviceCell: (' + data.ID + ',' + data.important + ',' + data.message + ')')
      this.add_end_code = data.ID
      this.add_important_level = data.important
      this.add_message = data.message
    },
    add_end_code_: function () {
      // console.log('PackageEndcodeList.vue add_end_code_:')
      if (this.add_end_code === '') {
        alert('終了コードを入力してください。')
        return
      }
      if (!(this.add_end_code === 'other')) {
        if (isNaN(this.add_end_code)) {
          alert('終了コードが数字またはotherではありません。')
          return
        } else {
          const intEndCode = parseInt(this.add_end_code, 10)
          if ((intEndCode < 0) || (intEndCode > 4294967295)) {
            alert('終了コードが不正です。0～4294967295の半角数字で入力してください。')
            return
          }
        }
      }
      if ((this.add_important_level === '') || (this.add_important_level === 'レベルを選択')) {
        alert('レベルを選択してください。')
        return
      }
      if (this.add_message === '') {
        alert('メッセージを入力してください')
        return
      }
      if (this.add_message.length > 64) {
        alert('メッセージは64文字以内で入力してください。')
        return
      }
      this.endcode_list_update = []
      for (const deviceRow of this.endcode_list) {
        let flagExist = false
        if (deviceRow.ID === this.add_end_code) {
          flagExist = true
        }
        if (flagExist) {
          if (!confirm('既に存在する終了コードです。\n終了コード=' + deviceRow.ID + ' レベル=' + deviceRow.important + ' メッセージ=' + deviceRow.message + 'が既に有り\n' +
            '終了コード=' + this.add_end_code + ' レベル=' + this.add_important_level + ' メッセージ=' + this.add_message + 'に\n更新しますか?')) {
            console.log('canceled.')
            return
          }
        } else {
          this.endcode_list_update.push(deviceRow)
        }
      }
      this.endcode_list = this.endcode_list_update
      const addEndcode = { ID: this.add_end_code, important: this.add_important_level, message: this.add_message }
      this.endcode_list.push(addEndcode)
      this.add_end_code = ''
      this.add_important_level = ''
      this.add_message = ''
    },
    del_end_code_: function () {
      // console.log('PackageEndcodeList.vue del_end_code_:')
      if (this.selected.length === 0) {
        alert('削除対象の終了コードを選択してください。')
        return
      }
      for (const delRow of this.selected) {
        if (delRow.ID === 'other') {
          alert('終了コードotherは削除できません。選択を解除してください。')
          return
        }
      }
      if (!confirm('終了コードを削除しますか？')) {
        console.log('canceled.')
        return
      }
      this.endcode_list_update = []
      // 更新前終了コードリストでループ
      for (const deviceRow of this.endcode_list) {
        let flagNotExist = true
        // 削除対象終了コードリストでループ
        for (const delRow of this.selected) {
          if (deviceRow.ID === delRow.ID) {
            if (!(deviceRow.ID === 'other')) {
              flagNotExist = false
            }
          }
        }
        if (flagNotExist) {
          const addEndcode = { ID: deviceRow.ID, important: deviceRow.important, message: deviceRow.message }
          this.endcode_list_update.push(addEndcode)
        }
      }
      this.endcode_list = []
      this.endcode_list = this.endcode_list_update
      this.selected = []
    }
  },
  created: function () {
    // console.log('PackageEndcodeList.vue created:')
  },
  mounted: function () {
    // console.log('PackageEndcodeList.vue mounted:')
    this.parm_info = this.base_parm_info
  }
}
</script>

<style scoped>
#id_end_code_row th{ background-color:#dcdff1; border: 1px #808080 solid;}
#id_end_code_row td{ border: 1px #808080 solid; text-align:left;}
.device_list th{ background-color:#dcdff1; }
#id_end_code_row td{ border: 0px #808080 solid; background-color:#ffffff; text-align:left;}
</style>
