<template>
  <div>

    <div v-show="!isLoading">

      <div class="sublist_window" v-show="!error_confirm_window">
        <v-card-title>
          <v-text-field
            v-model="search2"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          dense
          :headers="t_headers"
          :items="t_data"
          :search="search2"
          :custom-sort="custom_sort_pkg_history_importance"
          :sort-by="sort_by"
          :sort-desc="[true]"
        >
          <!--template v-slot:item.enable_info="{ item }">
            <span v-if='item.enable_info=="HS-10"'>新規登録状態</span>
            <span v-else-if='item.enable_info=="HS-20"'>有効</span>
            <span v-else-if='item.enable_info=="HS-30"'>無効</span>
            <span v-else>不明({{ item.enable_info }})</span>
          </template-->
          <template v-slot:item.message="{ item }">
            <span v-if='item.message=="警告"'><v-icon color='yellow'>mdi-alert</v-icon></span>
            <span v-if='item.message=="異常"'><v-icon color='red'>mdi-close-circle</v-icon></span>
            {{ item.message }}
          </template>
          <template v-slot:item.is_confirmed="{ item }">
            <!--{{ item.is_confirmed=='未確認' ? '':'確認済み' }}-->
            <span v-if='history_tab'>{{ item.is_confirmed }}</span>
            <span v-else>
              <!--v-btn v-if='item.is_confirmed=="未確認" && (item.message=="警告" || item.message=="異常")' v-on:click="confirm_info=item; error_confirm_window=true" depressed color="error" -->
              <v-btn v-on:click="confirm_info=item; error_confirm_window=true" depressed color="error" >
                未確認
              </v-btn>
            </span>
          </template>
          <template v-slot:item.importance="{ item }">
            <span v-if='item.importance=="エラー"'><v-icon color='red'>mdi-close-circle</v-icon></span>
            <span v-else-if='item.importance=="警告"'><v-icon color='yellow'>mdi-alert</v-icon></span>
            <span v-else-if='item.importance=="情報"'><v-icon color='blue'>mdi-information</v-icon></span>
            <span v-else><v-icon color='gray'>mdi-dots-horizontal-circle</v-icon></span>
            {{ item.importance}}
          </template>
        </v-data-table>
      </div>

      <div class="confirm_window" v-show="error_confirm_window">
        <div>
          <v-btn color="error" v-on:click="confirmErrorPackage()">確認済み</v-btn>
          <a>&nbsp;</a>
          <v-btn color="normal" v-on:click="error_confirm_window=false">戻る</v-btn>
        </div>
        <div>
          {{ error_message }}
        </div>
        <br>
        <div>
          <v-simple-table>
            <tbody>
              <tr><th>日時</th><td> {{ confirm_info.datetime }} </td></tr>
              <tr><th>説明</th>
                <td>
                  <span v-if='confirm_info.message=="警告"'><v-icon color='yellow'>mdi-alert</v-icon></span>
                  <span v-if='confirm_info.message=="異常"'><v-icon color='red'>mdi-close-circle</v-icon></span>
                  {{ confirm_info.message }}
                </td>
              </tr>
              <tr><th>ID</th><td> {{ confirm_info.pkg_id }} </td></tr>
              <tr><th>名称</th><td> {{ confirm_info.pkg_name }} </td></tr>
              <tr><th>確認状況</th><td> {{ confirm_info.confirmed }} </td></tr>
              <tr><th>確認者</th><td> {{ confirm_info.confirmer }} </td></tr>
              <tr><th>確認日時</th><td> {{ confirm_info.confirmation_datetime }} </td></tr>
            </tbody>
          </v-simple-table>
        </div>
        <br>
      </div>

    </div>

    <br v-for='n of 5' :key='n' v-show="isLoading" />
    <loading v-show="isLoading" />

  </div>
</template>

<script>
import axios from 'axios'
import Loading from '@/components/Loading'
import { getPhsInfo } from '@/components/common/PackageUtility.js'

export default {
  components: {
    Loading
  },
  name: 'DeviceDetailSubView',
  data: () => ({
    title: '',
    isLoading: true,
    error_message: '',
    search2: '',
    sort_by: ['pkg_id'],
    error_confirm_window: false,
    history_tab: true,
    isHost: true,
    t_headers: [
      {
        align: 'start',
        filterable: true,
        text: 'ID',
        value: 'id'
      },
      { text: 'データ1', value: 'data1' },
      { text: 'データ2', value: 'data2' }
    ],
    t_data: [
      { id: '1', data1: 'name', data2: 'value' }
    ],
    confirm_info: { },

    // 関連機器
    related_device_header: [
      /* { text: '装置採番番号', value: 'machine_id' }, { text: 'ホストマシンの番号', value: 'host_machine_id' }, */ { text: 'サーバ種別', value: 'host_guest' }, { text: '型番', value: 'model_number' }, { text: 'シリアル番号', value: 'serial_number' }, { text: 'ホスト名', value: 'hostname' }, { text: '死活ステータス', value: 'saf' }, { text: '有効情報', value: 'enable_info' }/*, { text: '機器の契約番号', value: 'contract_number' } */
    ],
    related_device_data: [
      /*
      { machine_id: 'マシン1', host_machine_id: 'ホストマシン番号1', host_guest: 'ホスト装置', model_number: 'aaaa', serial_number: 'xxxx-yyyy', hostname: 'ホスト1', safe: '死活ステータス1', enable_info: 'HS-20', contract_number: '契約番号1234' },
      { machine_id: 'マシン2', host_machine_id: 'ホストマシン番号2', host_guest: 'ホスト装置', model_number: 'aaaa', serial_number: 'xxxx-yyyy', hostname: 'ホスト1', safe: '死活ステータス1', enable_info: 'HS-20', contract_number: '契約番号1234' },
      { machine_id: 'マシン3', host_machine_id: 'ゲストマシン番号1', host_guest: '仮想マシン', model_number: 'bbbb', serial_number: 'eeee-ffff', hostname: 'ゲスト1', safe: '死活ステータス2', enable_info: 'HS-30', contract_number: '契約番号4567' },
      { machine_id: 'マシン4', host_machine_id: 'ゲストマシン番号2', host_guest: '仮想マシン', model_number: 'bbbb', serial_number: 'eeee-ffff', hostname: 'ゲスト1', safe: '死活ステータス2', enable_info: 'HS-30', contract_number: '契約番号4567' }
      */
    ],
    // PKG
    pkg_header: [
      /* { text: '装置採番番号', value: 'machine_id' }, */{ text: 'ID', value: 'pkg_id' }, { text: '名称', value: 'pkg_name' }, /* { text: '種別', value: 'task' }, */ { text: '登録日', value: 'registration_datetime' }/*, { text: '処理ステータス', value: 'phs' } */
    ],
    pkg_data: [
      /*
      { machine_id: 'machine_1', pkg_id: '000001', pkg_name: 'pkg name1', task: '登録', registration_datetime: 'bbb', state: '転送中' }
      */
    ],
    // パッケージログ
    pkg_history_header: [
      /* { text: 'PKGID+装置ID+情報取得日時', value: 'pkg_id_machine_id_unixtime' }, { text: '装置採番番号', value: 'machine_id' }, */{ text: 'レベル', value: 'importance' }, { text: '日時', value: 'datetime' }, { text: '説明', value: 'message' }, { text: 'ID', value: 'pkg_id' }, { text: '名称', value: 'pkg_name' }, { text: '確認状況', value: 'confirmed' }, { text: '確認者', value: 'confirmer' }, { text: '確認日時', value: 'confirmation_datetime' }
    ],
    pkg_history_data: [
      /*
      { pkg_id_machine_id_unixtime: 'pkg_id_machine_id_unixtime1', machine_id: '装置採番番号1', pkg_id: 'pkg_id1', datetime: '2021-10-11T12:34:56', message: 'PKG log1', pkg_name: 'pkg_name1', is_confirmed: '確認済み', confirmer: '確認者1', confirmation_datetime: '2021-hogehoge' },
      { pkg_id_machine_id_unixtime: 'pkg_id_machine_id_unixtime2', machine_id: '装置採番番号2', pkg_id: 'pkg_id2', datetime: '2021-10-11T12:34:57', message: '警告', pkg_name: 'pkg_name2', is_confirmed: '未確認', confirmer: '', confirmation_datetime: '' },
      { pkg_id_machine_id_unixtime: 'pkg_id_machine_id_unixtime3', machine_id: '装置採番番号3', pkg_id: 'pkg_id3', datetime: '2021-10-11T12:34:58', message: '異常', pkg_name: 'pkg_name3', is_confirmed: '未確認', confirmer: '', confirmation_datetime: '' },
      { pkg_id_machine_id_unixtime: 'pkg_id_machine_id_unixtime4', machine_id: '装置採番番号4', pkg_id: 'pkg_id4', datetime: '2021-10-11T12:34:59', message: '異常', pkg_name: 'pkg_name4', is_confirmed: '確認済み', confirmer: '確認者2', confirmation_datetime: '2021-hogehoge' }
      */
    ],
    // パッケージログ結果(未確認)
    pkg_error_header: [
      /* { text: 'PKGID+装置ID+情報取得日時', value: 'pkg_id_machine_id_unixtime' }, { text: '装置採番番号', value: 'machine_id' }, */{ text: 'レベル', value: 'importance' }, { text: '日時', value: 'datetime' }, { text: '説明', value: 'message' }, { text: 'ID', value: 'pkg_id' }, { text: '名称', value: 'pkg_name' }, { text: '確認状況', value: 'is_confirmed' }, { text: '確認者', value: 'confirmer' }, { text: '確認日時', value: 'confirmation_datetime' }
    ],
    pkg_error_data: [
      /*
      { pkg_id_machine_id_unixtime: 'pkg_id_machine_id_unixtime1', machine_id: '装置採番番号1', pkg_id: 'pkg_id1', datetime: '2021-10-11T12:34:56', message: 'PKG log1', pkg_name: 'pkg_name1', is_confirmed: '確認済み', confirmer: '確認者1', confirmation_datetime: '2021-hogehoge' },
      { pkg_id_machine_id_unixtime: 'pkg_id_machine_id_unixtime2', machine_id: '装置採番番号2', pkg_id: 'pkg_id2', datetime: '2021-10-11T12:34:57', message: '警告', pkg_name: 'pkg_name2', is_confirmed: '未確認', confirmer: '', confirmation_datetime: '' },
      { pkg_id_machine_id_unixtime: 'pkg_id_machine_id_unixtime3', machine_id: '装置採番番号3', pkg_id: 'pkg_id3', datetime: '2021-10-11T12:34:58', message: '異常', pkg_name: 'pkg_name3', is_confirmed: '未確認', confirmer: '', confirmation_datetime: '' },
      { pkg_id_machine_id_unixtime: 'pkg_id_machine_id_unixtime4', machine_id: '装置採番番号4', pkg_id: 'pkg_id4', datetime: '2021-10-11T12:34:59', message: '異常', pkg_name: 'pkg_name4', is_confirmed: '確認済み', confirmer: '確認者2', confirmation_datetime: '2021-hogehoge' }
      */
    ]
  }),

  beforeMount: function () {
  },

  mounted: function () {
  },

  watch: {
    pkg_data: {
      handler: function () {
        this.pkg_data.forEach(rowPackage => {
          rowPackage.phs = getPhsInfo(rowPackage.state).phsInfo.action_status
        })
      }
    },
    pkg_history_data: {
      handler: function () {
        this.pkg_history_data.forEach(rowhistory => {
          if (rowhistory.is_confirmed === 'required') {
            rowhistory.confirmed = '未確認'
          } else if (rowhistory.is_confirmed === 'confirmed') {
            rowhistory.confirmed = '確認済み'
          } else {
            rowhistory.confirmed = '-'
          }
        })
      }
    },
    pkg_error_data: {
      handler: function () {
        this.pkg_error_data.forEach(rowerror => {
          if (rowerror.is_confirmed === 'required') {
            rowerror.confirmed = '未確認'
          } else if (rowerror.is_confirmed === 'confirmed') {
            rowerror.confirmed = '確認済み'
          } else {
            rowerror.confirmed = '-'
          }
        })
      }
    }
  },

  methods: {
    custom_sort_pkg_history_importance: function (items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === 'importance') {
          var va = /^エラー/.test(a.importance) ? 4 : /^警告/.test(a.importance) ? 3 : /^情報/.test(a.importance) ? 2 : /^その他/.test(a.importance) ? 1 : 0
          var vb = /^エラー/.test(b.importance) ? 4 : /^警告/.test(b.importance) ? 3 : /^情報/.test(b.importance) ? 2 : /^その他/.test(b.importance) ? 1 : 0
          if (!isDesc[0]) {
            return va - vb
          } else {
            return vb - va
          }
        } else {
          if (!isDesc[0]) {
            return a[index] < b[index] ? -1 : 1
          } else {
            return b[index] < a[index] ? -1 : 1
          }
        }
      })
      return items
    },
    tab_init: function () {
      this.isLoading = true
      this.error_confirm_window = false
    },
    update_data: function (title, header, data, sorting) {
      // console.log('call update_data')
      this.title = title
      this.t_headers = header
      this.t_data = data
      // console.log(data)
      this.sort_by = sorting
    },

    confirmErrorPackage: function () {
      if (!confirm('確認済みにします。よろしいですか?')) {
        return
      }
      var method = 'post'
      axios.request({
        method: method,
        url: '/machines/' + `${this.$route.params.id}` + '/histories/' + this.confirm_info.pkg_id_machine_id_unixtime,
        headers: { Authorization: this.$store.getters.user.token },
        params: { org: this.$store.getters.user.orgid, action: 'confirm' }
      }).then((res) => {
        // console.log(res)
        alert('確認済みに設定しました。')
        this.selectErrTab()
      }).catch((error) => {
        console.log(error)
        // alert(error)
        alert('操作に失敗しました。')
        // this.error_message += '[POST /machines/]: Failed' + '\n'
      })
    },

    selectDevTab: function (isHost) {
      // this.isHost = isHost
      this.tab_init()
      this.getRestApiTabWindow(() => {
        this.update_data('関連機器', this.related_device_header, this.related_device_data, null)
        this.isLoading = false
      }, 'Dev')
    },
    selectPkgTab: function () {
      this.tab_init()
      this.getRestApiTabWindow(() => {
        this.update_data('PKG', this.pkg_header, this.pkg_data, ['pkg_id'])
        this.isLoading = false
      }, 'Pkg')
    },
    selectHisTab: function () {
      this.history_tab = true
      this.tab_init()
      this.getRestApiTabWindow(() => {
        this.update_data('パッケージログ', this.pkg_history_header, this.pkg_history_data, ['datetime'])
        this.isLoading = false
      }, 'His')
    },
    selectErrTab: function () {
      this.history_tab = false
      this.tab_init()
      this.getRestApiTabWindow(() => {
        this.update_data('パッケージログ結果(未確認)', this.pkg_error_header, this.pkg_error_data, ['datetime'])
        this.isLoading = false
      }, 'Err')
    },

    getRestApiTabWindow: function (proc, tab) {
      var url = '/machines/' + `${this.$route.params.id}`
      var paramFilter = ''
      if (tab === 'Dev') {
        url += '/contract'
      } else if (tab === 'Pkg') {
        url += '/packages'
      } else if (tab === 'His') {
        url += '/histories'
      } else if (tab === 'Err') {
        url += '/histories'
        paramFilter = 'required'
      } else {
        return false
      }
      // console.log(url)

      axios.get(url,
        {
          headers: { Authorization: this.$store.getters.user.token },
          params: { filter: paramFilter, org: this.$store.getters.user.orgid }
        }
      ).then((res) => {
        // console.log(res.data)
        if (tab === 'Dev') {
          this.related_device_data = res.data
          /*
          // 対象サーバ種別のみ(ホスト詳細->ゲスト一覧, ゲスト詳細->ホスト一覧)
          if (this.isHost) {
            this.related_device_data = this.related_device_data.filter(dev => {
              return dev.host_guest === '仮想マシン'
            })
          } else {
            this.related_device_data = this.related_device_data.filter(dev => {
              return dev.host_guest === 'ホスト装置'
            })
          }
          */
          /*
          this.related_device_data.forEach(dev => {
            if (dev.enable_info === 'HS-10') {
              dev.enable_info = '新規登録状態'
            } else if (dev.enable_info === 'HS-20') {
              dev.enable_info = '有効'
            } else if (dev.enable_info === 'HS-30') {
              dev.enable_info = '無効'
            } else {
              dev.enable_info = `${dev.enable_info}`
            }
          })
          */
        } else if (tab === 'Pkg') {
          this.pkg_data = res.data
        } else if (tab === 'His') {
          this.pkg_history_data = res.data
        } else if (tab === 'Err') {
          this.pkg_error_data = res.data
          // 未確認(警告/異常) T.B.D
          /*
          this.pkg_error_data = this.pkg_error_data.filter(elm => {
            return elm.is_confirmed === '未確認' && (elm.message === '異常' || elm.message === '警告')
          })
          */
        }
        // テーブル更新処理
        proc()
      }).catch((error) => {
        console.log(error)
        // alert(error)
        alert('情報の取得に失敗しました。')
        // this.error_message += '[GET /machines]: Failed' + '\n'

        // TODO delete for debug
        /*
        if (this.isHost) {
          this.related_device_data = this.related_device_data.filter(dev => {
            return dev.host_guest === '仮想マシン'
          })
        } else {
          this.related_device_data = this.related_device_data.filter(dev => {
            return dev.host_guest === 'ホスト装置'
          })
        }
        this.related_device_data.forEach(dev => {
          if (dev.enable_info === 'HS-10') {
            dev.enable_info = '新規登録状態'
          } else if (dev.enable_info === 'HS-20') {
            dev.enable_info = '有効'
          } else if (dev.enable_info === 'HS-30') {
            dev.enable_info = '無効'
          } else {
            dev.enable_info = '不明(' + `${dev.enable_info}` + ')'
          }
        })
        if (tab === 'Err') {
          // 未確認(警告/異常)
          this.pkg_error_data = this.pkg_error_data.filter(elm => {
            return elm.is_confirmed === '未確認' && (elm.message === '異常' || elm.message === '警告')
          })
        }
        proc()
        */
        // TODO delete for debug
      })
    }
  }
}
</script>
<style scoped>
.sublist_window th{ background-color:#dcdff1; }
.confirm_window table{ width:100%; }
.confirm_window th{ min-width:80px; max-width:80px; background-color:#dcdff1; border: 1px #808080 solid; }
.confirm_window td{ border: 1px #808080 solid; text-align:left; }
</style>
