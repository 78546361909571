<template>
  <div>
    <loading v-show="isLoading"></loading>
    <div v-show="!isLoading">
      <p>機器詳細を表示するには該当機器の行をクリック</p>
      <div id="id_table" class="package_device_list">
        <div id="card_title">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              dense
              >
            </v-text-field>
          </v-card-title>
        </div>
        <div id="data_table">
          <v-data-table
            :headers="t_headers"
            :items="selectedDevices"
            item-key="machine_id"
            :search="search"
            :sort-by="['contract_number']"
            @click:row="onClickDeviceCell"
            dense
            >
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Loading from '@/components/Loading'
import { getPfsInfo, getPksInfo } from '@/components/common/PackageUtility.js'

export default {
  name: 'PackageDevice',
  props: {
    base_parm_info: {}
  },
  data: () => ({
    search: '',
    t_headers: [
      { text: 'サーバ種別', value: 'host_guest', width: '100', align: 'left' },
      { text: '契約番号', value: 'contract_name', width: '80', align: 'left' },
      { text: '型番', value: 'model_number', width: '60', align: 'left' },
      { text: 'シリアル', value: 'serial_number', width: '60', align: 'left' },
      { text: 'ホスト名', value: 'hostname', width: '80', align: 'left' },
      { text: '死活ステータス', value: 'saf', width: '80', align: 'left' },
      { text: '転送ステータス', value: 'pfs_name', width: '80', align: 'left' },
      { text: '登録ステータス', value: 'pks_name', width: '80', align: 'left' },
      { text: '説明', value: 'exec_result', width: '80', align: 'left' }
    ],
    selectedDevices: [],
    parm_info: {},
    isLoading: true
  }),
  components: {
    Loading
  },
  watch: {
    // *************************************
    // 対象装置一覧の配列変数更新時
    // *************************************
    selectedDevices: {
      handler: function () {
        this.selectedDevices.forEach(rowDevices => {
          // ********** パッケージ転送ステータス名称検索
          var getPfsInfoRes = getPfsInfo(rowDevices.pfs) // PFS(パッケージ転送ステータス)情報検索
          const flagpfsExist = getPfsInfoRes.flagExist
          rowDevices.pfs_name = getPfsInfoRes.pfsInfo.action_status
          // 想定外のpfsか?
          if (flagpfsExist === false) {
            // 想定外のpfsの場合は、処理ステータスにpfsの値を表示
            rowDevices.pfs_name = rowDevices.pfs
          }
          // ********** パッケージ登録ステータス名称検索
          var getPksInfoRes = getPksInfo(rowDevices.pks) // PKS(パッケージ登録ステータス)情報検索
          const flagpksExist = getPksInfoRes.flagExist
          rowDevices.pks_name = getPksInfoRes.pksInfo.action_status
          // 想定外のpksか?
          if (flagpksExist === false) {
            // 想定外のpksの場合は、処理ステータスにpfsの値を表示
            rowDevices.pks_name = rowDevices.pks
          }
        })
      }
    }
  },
  methods: {
    // ********************************
    // 対象装置一覧 クリック時
    // ********************************
    onClickDeviceCell: function (data) {
      if (this.$route.name === 'Wsus_PackageDetail') {
        this.$router.push({ name: 'Wsus_DeviceDetail', params: { id: data.machine_id } })
      } else {
        this.$router.push({ name: 'DeviceDetail', params: { id: data.machine_id } })
      }
    },
    // ********************************
    // パッケージ対象装置一覧 取得処理
    // ********************************
    getRestApi: function () {
      // console.log('PackageDeviceList.vue getRestApi:')
      axios.get('/packages/' + this.parm_info.pkgid + '/machines',
        {
          params: { org: this.$store.getters.user.orgid },
          headers: { Authorization: this.$store.getters.user.token }
        }
      ).then((res) => {
        // 対象装置一覧を取得が成功した時
        // console.log('PackageDeviceList.vue getRestApi: then get OK')
        // undefinedリターン対応
        if (res.data.length === undefined) {
          // console.log('PackageDeviceList.vue res.data undefined 0件')
          this.selectedDevices = []
          this.isLoading = false
          return
        }
        this.selectedDevices = res.data
        this.isLoading = false
      }).catch((error) => {
        // 対象装置一覧を取得が失敗した時
        console.log('PackageDeviceList.vue getRestApi: ★catch error=' + error)
        this.isLoading = false
        alert('対象装置一覧取得に失敗\n通信エラーが起きました。リロードするか時間をおいてアクセスしてください。\n' + error)
      })
    }
  },
  // *******************************
  // 画面マウント時
  // *******************************
  mounted: function () {
    // console.log('PackageDeviceList.vue mounted:')
    this.parm_info = this.base_parm_info
    this.getRestApi()
  }
}
</script>

<style scoped>
.package_device_list th{ background-color:#dcdff1; border: 1px #808080 solid; }
.package_device_list td{ border: 1px #808080 solid; text-align:left; }
</style>
