<template>
  <div>

    <loading v-show="isLoading" />
    <div v-show="!isLoading">
      <div>
        <h1>{{ title }}</h1>
      </div>
      <div v-if="(type==='patch')">
        <v-simple-table id="contract_detail" dense>
        <tbody>
          <tr v-if='false'><th>契約ステータス</th><td> {{ contract_info.enable ? '有効':( contract_info.enable == false ? '無効' : '')}} </td></tr>
          <tr><th>契約番号</th><td> {{ contract_info.name }} </td></tr>
          <tr><th>契約種別</th><td> {{ type_name }} </td></tr> <!-- {{ contract_info.contract_type }}を入れる /-->
          <tr><th>エッジサーバ管理テナント(テナントID)</th><td> {{ update_host_organization_name_id }} </td></tr>
          <tr><th>仮想化基盤管理テナント(テナントID)</th><td> {{ update_guest_organization_name_id }} </td></tr>
          <tr><th>エッジサーバ通報先メールアドレス</th><td> {{ contract_info.host_email }} </td></tr>
          <tr><th>仮想化基盤通報先メールアドレス</th><td> {{ contract_info.guest_email }} </td></tr>
          <tr><th>更新日</th><td> {{ contract_info.update_datetime }} </td></tr>
          <tr><th>更新者</th><td> {{ update_user_name_id }} </td></tr>
        </tbody>
        </v-simple-table>
      </div>
      <div v-else>
        <v-simple-table id="contract_detail" dense>
        <tbody>
          <tr v-if='false'><th>契約ステータス</th><td> {{ contract_info.enable ? '有効':( contract_info.enable == false ? '無効' : '')}} </td></tr>
          <tr><th>契約番号</th><td> {{ contract_info.name }} </td></tr>
          <tr><th>契約種別</th><td> {{ type_name }} </td></tr> <!-- { contract_info.contract_type }}を入れる /-->
          <tr><th>管理テナント(テナントID)</th><td> {{ update_host_organization_name_id }} </td></tr>
          <tr><th>連絡先メールアドレス</th><td> {{ contract_info.host_email }} </td></tr>
          <tr><th>更新日</th><td> {{ contract_info.update_datetime }} </td></tr>
          <tr><th>更新者</th><td> {{ update_user_name_id }} </td></tr>
        </tbody>
        </v-simple-table>
      </div>
      <div>
        <v-btn v-show="btns.edit" color="primary" v-on:click="edit_">編集</v-btn>
        <a>&nbsp;</a>
        <v-btn v-show="btns.del" color="error" v-on:click="delete_">削除</v-btn>
        <a>&nbsp;</a>
        <v-btn v-show="btns.back" color="normal" v-on:click="back_">戻る</v-btn>
      </div>
      <br>
      <div>
        <ul class='tabs'>
          <li v-for="tab in tab_list"  v-bind:key="tab.id" class='active'
            v-bind:class="{'active': currentTabId === 'tab.id'}">
            {{ tab.label }}
          </li>
        </ul>
        <ul class='contents'>
          <li>
            <div>
              <div v-if='!device_registered && btns.edit'>
                <v-form ref="contract_device_form">
                <v-simple-table id="contract_edit" dense class="contract_detail_tbl" >
                <tbody>
                  <tr><th>型番</th><td>
                    <v-text-field
                      name="note"
                      v-model="input_form_model"
                      placeholder=""
                      label=""
                      :rules="[required, validate_model, max_50]"
                      />
                  </td></tr>
                  <tr><th>シリアル番号</th><td>
                    <v-text-field
                      name="note"
                      v-model="input_form_serial"
                      placeholder=""
                      label=""
                      :rules="[required, validate_serial, max_50]"
                      />
                  </td></tr>
                </tbody>
                </v-simple-table>
                </v-form>
              </div>
              <div v-else class="device_list">
                <v-data-table
                  dense
                  :headers="t_headers"
                  :items="devices"
                  hide-default-footer
                  disable-pagination
                ></v-data-table>
              </div>
              <div v-if="(type==='patch')">
                <v-btn v-if='!device_registered && btns.dev_reg' color="primary" v-on:click="reg_device(true)">機器登録</v-btn>
                <v-btn v-if='device_registered && btns.dev_del' color="error" v-on:click="reg_device(false)">削除</v-btn>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div>
        {{ error_message }}
      </div>
      <div class="text-center">
        <v-dialog v-model="dialog" persistent width="600">
          <v-card>
            <v-card-title class="headline grey lighten-2">
              アクティベーションが発行されました
            </v-card-title>
            <v-card-text style="margin: 1rem auto;">
              SSM Agentのインストールで設定してください<br><br>
               - アクティベーションID：{{ activation_id }} <br>
               - アクティベーションコード：{{ activation_code }} <br><br>
              ＊注意：機器登録時に１度だけ表示します。閉じる前に値を保存してください。
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <div>
                <vue-json-to-csv :json-data="activation_data" :csv-title="download">
                  <button class="text-body-2 primary--text">CSVダウンロード</button>
                </vue-json-to-csv>
              </div>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false">閉じる</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Loading from '@/components/Loading'
import VueJsonToCsv from 'vue-json-to-csv'

export default {
  components: {
    Loading,
    VueJsonToCsv
  },
  name: 'ContractDetail',
  data: () => ({
    activation_data: [{ id: 'xxxx', code: 'yyyy' }],
    title: '契約詳細',
    isLoading: true,
    permission: false,
    error_message: '',
    currentTabId: 1,
    tab_list: [
      { id: 1, label: '登録機器', content: '' }
      // { id: 2, label: 'ルータ一覧', content: ListNA }
    ],
    // API /contracts/{id}
    contract_info: {},
    // API /organizations
    organizations: [],
    // API /users/{id} :contract update user
    user_info: {},
    update_host_organization_name_id: '',
    update_guest_organization_name_id: '',
    update_user_name_id: '',
    btns: {
      back: true,
      edit: false,
      del: false,
      dev_reg: false,
      dev_del: false
    },

    // 登録機器の制御用
    t_headers: [
      // { text: '', value: '', align: '' },
      { text: 'サーバ種別', value: 'host_guest', align: 'start', filterable: true },
      { text: '型番', value: 'model' },
      { text: 'シリアル番号', value: 'serial' },
      { text: 'ホスト名', value: 'hostname' },
      { text: '死活ステータス', value: 'saf' },
      { text: '有効情報', value: 'enable_info' }
    ],
    // API /devices/{id} T.B.D
    devices: [
      { host_guest: '-', model: '-', serial: '-', hostname: '-', saf: '-', enable_info: '-' }
    ],
    required: value => !!value || '入力してください',
    max_30: value => (value || '').length <= 30 || '30文字まで',
    max_50: value => (value || '').length <= 50 || '50文字まで',
    validate_model: value => {
      const pattern = /^[A-Za-z0-9-]{1,}$/
      return pattern.test(value) || '入力が正しくありません'
    },
    validate_serial: value => {
      const pattern = /^[A-Za-z0-9-]{1,}$/
      return pattern.test(value) || '入力が正しくありません'
    },
    input_form_model: '',
    input_form_serial: '',
    device_registered: false,
    dialog: false,
    activation_id: '',
    activation_code: '',
    type: 'patch',
    type_name: 'シングルサイトマネジメント版'
  }),

  beforeCreate: function () {
  },

  created: function () {
    this.permission = (((this.$store.getters.user.role[0]) && (this.$store.getters.user.orgid.slice(0, 2) !== '80')) || this.$store.getters.user.role[1])
    if ((this.$store.getters.user.role[0]) && (this.$store.getters.user.orgid.slice(0, 2) !== '80')) {
    }
  },

  beforeMount: function () {
  },

  mounted: function () {
    if (!this.permission) {
      console.log('contract permission denied.')
      return
    }
    this.$nextTick(function () {
      this.getRestApiContract()
    })
  },

  beforeUpdate: function () {
  },

  updated: function () {
  },

  beforeDestroy: function () {
  },

  destroyed: function () {
  },

  watch: {
    contract_info: {
      handler: function () {
        // console.log('detail: watch contract_info')
        // console.log('detail: watch contract_info[model]:' + this.contract_info.model)
        // console.log('detail: watch contract_info[serial]:' + this.contract_info.serial)
        if (!this.organizations.length) {
          this.getRestApiOrganization()
        }
        if (!Object.keys(this.user_info).length) {
          this.getRestApiUser()
        }
        this.device_registered = !!((this.contract_info.model !== '' && this.contract_info.serial !== ''))
        if (this.device_registered) {
          this.devices[0].model = this.contract_info.model
          this.devices[0].serial = this.contract_info.serial
          // T.B.D this.getRestApiDeviceInfo()
          this.devices[0].host_guest = this.contract_info.host_guest
          this.devices[0].hostname = this.contract_info.hostname
          this.devices[0].saf = this.contract_info.saf
          this.devices[0].enable_info = this.contract_info.enable_info
        }

        // ホスト契約のみ編集可能とする
        if (this.$store.getters.user.role[0] &&
            (this.$store.getters.user.orgid === this.contract_info.host_organization_id)) {
          this.btns.edit = true
          this.btns.del = true
          this.btns.dev_reg = true
          this.btns.dev_del = true
        }
      },
      deep: true
    }
  },

  methods: {
    setContractType: function () {
      this.type = this.contract_info.contract_type
      if (this.contract_info.contract_type === 'patch') {
        this.type_name = 'シングルサイトマネジメント版'
      } else if (this.contract_info.contract_type === 'appliance') {
        this.type_name = 'マルチサイトマネジメント版'
      }
    },
    // 仮想アプラでは機器詳細を開いたときに型番/シリアルが設定されていなければ
    // 自動で設定してアクティベーションを発行するようにする。
    checkApplianceAndRegister: function (data) {
      if (data.contract_type === 'appliance') {
        // 機器登録(モデルシリアルが設定)されていない時はダミー入力して設定を呼ぶ
        if ((data.model === '') & (data.serial === '')) {
          this.input_form_model = '-'
          this.input_form_serial = '-'
          this.isLoading = true
          this.setRestApiContractDevice(true)
        }
      }
    },
    /* 契約詳細の表示 */
    getRestApiContract: function () {
      axios.get('/contracts/' + `${this.$route.params.id}`,
        {
          headers: { Authorization: this.$store.getters.user.token },
          params: { id: this.$store.getters.user.orgid }
        }
      ).then((res) => {
        this.contract_info = res.data
        // 契約情報取得後に、個別APIで組織名とユーザ名を付加する
        this.update_host_organization_name_id = this.contract_info.host_organization_id
        this.update_guest_organization_name_id = this.contract_info.guest_organization_id
        this.update_user_name_id = this.contract_info.update_user_id
        this.isLoading = false
        this.setContractType()
        this.checkApplianceAndRegister(res.data)
      }).catch((error) => {
        console.log(error)
        // alert(error)
        alert('契約情報の取得に失敗しました。')
        // this.error_message += '[GET /contracts/id]: Failed, ' + `${this.$route.params.id}` + '\n'
        this.isLoading = false
      })
    },
    getRestApiOrganization: function () {
      axios.get('/organizations',
        {
          headers: { Authorization: this.$store.getters.user.token }
        }
      ).then((res) => {
        this.organizations = res.data
        // 契約情報の組織番号に組織名を付加
        this.organizations.forEach(org => {
          if (this.contract_info.host_organization_id === org.organization_id) {
            this.update_host_organization_name_id = `${org.organization_name}(${this.contract_info.host_organization_id})`
          }
          if (this.contract_info.guest_organization_id === org.organization_id) {
            this.update_guest_organization_name_id = `${org.organization_name}(${this.contract_info.guest_organization_id})`
          }
        })
      }).catch((error) => {
        console.log(error)
        // alert(error)
        alert('組織情報の取得に失敗しました。')
        // this.error_message = '[GET /organizations]: Failed' + '\n'
      })
    },
    getRestApiUser: function () {
      // ユーザ情報はスーパーユーザのみ取得
      if (!this.$store.getters.user.role[0]) {
        return
      }
      axios.get('/users/' + `${this.contract_info.update_user_id}`,
        {
          headers: { Authorization: this.$store.getters.user.token }
        }
      ).then((res) => {
        this.user_info = res.data
        // 契約情報の更新者IDに名前を付加
        if (this.contract_info.update_user_id === this.user_info.user_id) {
          this.update_user_name_id = `${this.user_info.name}(${this.user_info.user_id})`
        }
      }).catch((error) => {
        console.log(error)
        // alert(error)
        alert('ユーザー情報の取得に失敗しました。')
        // this.error_message = '[GET /users/id]: Failed, ' + `${this.contract_info.update_user_id}` + '\n'
      })
    },

    /* 契約詳細の操作、戻る、編集、削除 */
    back_: function () {
      this.$router.push({ name: 'Contract' })
    },
    edit_: function () {
      this.$router.push({ name: 'ContractEdit', params: { id: this.$route.params.id } })
    },
    delete_: function () {
      // 仮想アプラでは機器登録を考慮しない。バックエンドで処理してもらえる。
      if (this.contract_info.contract_type !== 'appliance') {
        if (this.device_registered) {
          alert('機器を削除してください')
          return
        }
      }
      if (!confirm('削除しますか?')) {
        return
      }
      this.isLoading = true
      this.deleteRestApiContract()
    },
    deleteRestApiContract: function () {
      axios.delete('/contracts/' + `${this.$route.params.id}`,
        {
          headers: { Authorization: this.$store.getters.user.token },
          params: { id: this.$store.getters.user.orgid }
        }
      ).then((res) => {
        alert('契約を削除しました。')
        this.$router.push({ name: 'Contract' })
        this.isLoading = false
      }).catch((error) => {
        console.log(error)
        // alert(error)
        alert('契約の削除に失敗しました。')
        // this.error_message += '[GET /contracts/id]: Failed, ' + `${this.$route.params.id}` + '\n'
        this.isLoading = false
      })
    },

    /* 機器登録の操作 */
    reg_device: function (register) {
      if (register) {
        if (!this.$refs.contract_device_form.validate()) {
          return
        }
      }
      const msg = register ? '機器を登録しますか？' : '登録された機器を削除しますか？'
      if (!confirm(msg)) {
        return
      }
      this.isLoading = true
      this.setRestApiContractDevice(register)
    },
    setRestApiContractDevice: function (register) {
      var reg = Object.assign({}, this.contract_info)
      if (register) {
        reg.model = this.input_form_model
        reg.serial = this.input_form_serial
      } else {
        reg.model = ''
        reg.serial = ''
      }
      axios.put('/contracts/' + `${this.$route.params.id}`,
        reg,
        {
          headers: { Authorization: this.$store.getters.user.token }
        }
      ).then((res) => {
        // console.log(res)
        // this.contract_info.model = this.input_form_model
        // this.contract_info.serial = this.input_form_serial
        this.contract_info = res.data
        this.input_form_model = ''
        this.input_form_serial = ''
        this.isLoading = false
        if (res.data.ActivationId) {
          this.activation_id = res.data.ActivationId
          this.activation_code = res.data.ActivationCode
          this.activation_data = []
          this.activation_data.push({ id: res.data.ActivationId, code: res.data.ActivationCode })
          this.dialog = true
        }
      }).catch((error) => {
        console.log(error)
        // alert(error)
        if (register) {
          alert('機器の登録に失敗しました。')
        } else {
          alert('機器の削除に失敗しました。')
        }
        this.isLoading = false
      })
    }
    /* T.B.D
    getRestApiDeviceInfo: function () {
      // T.B.D RestAPI
      var TBD = ''
      axios.get('/devices/' + TBD,
        {
          headers: { Authorization: this.$store.getters.user.token },
          params: {
            model: this.contract_info.model,
            selrial: this.contract_info.serial
          }
        }
      ).then((res) => {
        this.device_info = res.data
      }).catch((error) => {
        console.log(error)
      })
    },
    */

  }

}
</script>

<style scoped>
#contract_detail table{ width:100%; }
#contract_detail th{ min-width:110px; max-width:120px; background-color:#dcdff1; border: 1px #808080 solid; }
#contract_detail td{ border: 1px #808080 solid; text-align:left;}

.contract_detail_tbl table{ width:100%; }
.contract_detail_tbl th{ min-width:80px; max-width:50px; background-color:#dcdff1; border: 1px #808080 solid; }
.contract_detail_tbl td{ border: 1px #808080 solid; text-align:left;}

ul{
    margin: 0;
    padding: 0;
  }
  li{
    list-style: none;
  }
  .tabs {
    overflow: hidden;
  }
  .tabs li,
  .tabs label {
    float: left;
    padding: 10px 20px;
    border: 1px solid #ccc;
    cursor: pointer;
    transition: .3s;
  }
  .tabs li:not(:first-child),
  .tabs label:not(:first-of-type) {
    border-left: none;
  }
  .tabs li.active,
  .tabs :checked + label {
    background-color: #000;
    border-color: #000;
    color: #fff;
    cursor: auto;
  }
  .contents{
    overflow: hidden;
    margin-top: -1px;
  }
  .contents li {
    /* width: 320px; */
    padding: 20px;
    border: 1px solid #ccc;
  }
</style>
