export default class validate {
  // static required (name) {
  //   return (v) => (v !== '' && v !== null) || `${name}は必須です`
  // }
  static required () {
    return (value) => !!value || '入力してください'
  }

  static limitLength (limit) {
    // if (typeof(limit) === 'number' && limit - limit === 0) {
    //   return value => 'Invalid Validation Parameter!!'
    // }
    return value =>
      (value || '').length <= limit || `${limit}文字以内で入力してください`
  }

  static emailAddress () {
    const pattern = /^((([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))(\s*[;]\s*((([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))){0,2}\s*[;]*\s*$/
    return value =>
      pattern.test(value) || '入力が正しくありません'
  }

  static modelNumber () {
    const pattern = /^[A-Za-z0-9-]{1,}$/
    return value =>
      pattern.test(value) || '入力が正しくありません'
  }

  static serialNumber () {
    const pattern = /^[A-Za-z0-9-]{1,}$/
    return value =>
      pattern.test(value) || '入力が正しくありません'
  }
}
