// ******************************************
// PKS(パッケージ登録ステータス)情報検索
// ******************************************
// *** 現行コード値参考 ***
// d_inst_host_register_st = {
//         'PKS-10': { 'name': '処理前_Host(PKS-10)',                'web_disp': '運用エージェントによる処理開始前'},
//        #'PKS-20': { 'name': '', 'web_disp': ''},
//         'PKS-30': { 'name': '処理済み_正常終了_Host(PKS-30)',     'web_disp': '運用エージェントで処理完了'},
//         'PKS-40': { 'name': '処理済み_異常終了_Host(PKS-40)',     'web_disp': '運用エージェントで処理失敗'},
//         'PKS-50': { 'name': '登録済み_有効状態_Host(PKS-50)',     'web_disp': 'タスク有効状態'},
//         'PKS-60': { 'name': '登録済み_一時無効状態_Host(PKS-60)', 'web_disp': 'タスク一時無効状態'},
//         'PKS-70': { 'name': '削除済み_Host(PKS-70)',              'web_disp': 'タスク削除済み'},
//         'PKS-XX': { 'name': '-',                                  'web_disp': '-'},
//         }
// d_inst_guest_register_st = {
//         'PKS-10': { 'name': '処理前_Guest(PKS-10)',                'web_disp': '運用エージェントによる処理開始前'},
//         #'PKS-20': { 'name': '', 'web_disp': ''},
//         'PKS-30': { 'name': '処理済み_正常終了_Guest(PKS-30)',     'web_disp': '運用エージェントで処理完了'},
//         'PKS-40': { 'name': '処理済み_異常終了_Guest(PKS-40)',     'web_disp': '運用エージェントで処理失敗'},
//         'PKS-50': { 'name': '登録済み_有効状態_Guest(PKS-50)',     'web_disp': 'タスク有効状態'},
//         'PKS-60': { 'name': '登録済み_一時無効状態_Guest(PKS-60)', 'web_disp': 'タスク一時無効状態'},
//         'PKS-70': { 'name': '削除済み_Guest(PKS-70)',              'web_disp': 'タスク削除済み'},
//         'PKS-XX': { 'name': '-',                                   'web_disp': '-'},
//         }
function getPksInfo (pksCode) {
  const packagePksTable = [
    { code: 'PKS-10', action_status: '登録前' },
    { code: 'PKS-20', action_status: 'タスク有効状態' },
    { code: 'PKS-21', action_status: 'タスク一時無効変更中' },
    { code: 'PKS-22', action_status: 'タスク一時無効状態' },
    { code: 'PKS-23', action_status: 'タスク有効変更中' },
    { code: 'PKS-30', action_status: '削除中' },
    { code: 'PKS-31', action_status: '削除済み' }
  ]
  let pksInfo = { code: '', action_status: '', active: false }
  let flagExist = false
  packagePksTable.forEach(pksTableInfo => {
    // pksCodeが一致したか?
    if (pksTableInfo.code === pksCode) {
      flagExist = true
      pksInfo = pksTableInfo
    }
  })
  return { pksInfo: pksInfo, flagExist: flagExist }
}
// ******************************************
// PFS(パッケージ転送ステータス)情報検索
// ******************************************
// *** 現行コード値参考 ***
// d_inst_host_transfer_st = {
//         'PFS-H10':  { 'name': '処理前_Host(PFS-H10)',                'web_disp': 'パッケージ転送開始前(PFS-H10)'},
//         'PFS-H20':  { 'name': '転送前_転送待ち_Host(PFS-H20)',       'web_disp': 'ホストサーバへパッケージ転送開始待ち(PFS-H20)'},
//         'PFS-H30':  { 'name': '転送開始処理中_Host(PFS-H30)',        'web_disp': 'ホストサーバへパッケージ転送開始処理中(PFS-H30)'},
//         'PFS-H40':  { 'name': '転送中_Host(PFS-H40)',                'web_disp': 'ホストサーバへパッケージ転送中(PFS-H40)'},
//         'PFS-H50':  { 'name': '転送終了_成功_Host(PFS-H50)',         'web_disp': 'ホストサーバへパッケージ転送成功(PFS-H50)'},
//         'PFS-H60':  { 'name': '転送終了_失敗_Host(PFS-H60)',         'web_disp': 'ホストサーバへパッケージ転送失敗(PFS-H60)'},
//         'PFS-H70':  { 'name': '中断済み_Host(PFS-H70)',              'web_disp': 'パッケージ転送中断済み(PFS-H70)'},
//         'PFS-H80':  { 'name': '転送前_失敗_Host(PFS-H80)',           'web_disp': 'ホストサーバへパッケージ転送開始失敗(PFS-H80)'},
//         'PFS-H90':  { 'name': 'エージェント受付完了_Host(PFS-H90)',  'web_disp': 'エージェント受付完了(PFS-H90)'},
//         'PFS-H100': { 'name': 'エージェント受付失敗_Host(PFS-H100)', 'web_disp': 'エージェント受付失敗(PFS-H100)'},
//         'PFS-XX':   { 'name': '-',                                   'web_disp': '-'},
//         }
// d_inst_guest_transfer_st = {
//         'PFS-G10':  { 'name': '処理前_Guest(PFS-G10)',                                               'web_disp': 'パッケージ転送開始前(PFS-G10)'},
//         'PFS-G20':  { 'name': '転送前_転送待ち_Guest(PFS-G20)',                                      'web_disp': 'ホストサーバへパッケージ転送開始待ち(PFS-G20)'},
//         'PFS-G30':  { 'name': 'ホストサーバへの転送開始処理中_Guest(PFS-G30)',                       'web_disp': 'ホストサーバへパッケージ転送開始処理中(PFS-G30)'},
//         'PFS-G40':  { 'name': 'ホストサーバへの転送中_Guest(PFS-G40)',                               'web_disp': 'ホストサーバへパッケージ転送中(PFS-G40)'},
//         'PFS-G50':  { 'name': 'ホストサーバへの転送終了_成功_仮想マシンへの転送待ち_Guest(PFS-G50)', 'web_disp': '仮想マシンへ転送開始待ち(PFS-G50)'},
//         'PFS-G60':  { 'name': '仮想マシンへの転送中_Guest(PFS-G60)',                                 'web_disp': '仮想マシンへパッケージ転送中(PFS-G60)'},
//         'PFS-G70':  { 'name': '仮想マシンへの転送終了_Guest(PFS-G70)',                               'web_disp': '仮想マシンへパッケージ転送成功(PFS-G70)'},
//         'PFS-G80':  { 'name': 'エージェント受付完了_Host(PFS-G80)',                                  'web_disp': 'エージェント受付完了(PFS-G80)'},
//         'PFS-G90':  { 'name': 'エージェント受付失敗_Host(PFS-G90)',                                  'web_disp': 'エージェント受付失敗(PFS-G90)'},
//         'PFS-G100': { 'name': '中断済み_Guest(PFS-G100)',                                            'web_disp': 'パッケージ転送中断済み(PFS-G100)'},
//         'PFS-G110': { 'name': '転送前_失敗_Guest(PFS-G110)',                                         'web_disp': 'ホストサーバへパッケージ転送開始失敗(PFS-G110)'},
//         'PFS-G120': { 'name': 'ホストサーバへの転送終了_失敗_Guest(PFS-G120)',                       'web_disp': 'ホストサーバへパッケージ転送失敗(PFS-G120)'},
//         'PFS-G130': { 'name': '仮想マシンへの転送終了_失敗_Guest(PFS-G130)',                         'web_disp': '仮想マシンへパッケージ転送失敗(PFS-G130)'},
//         'PFS-XX':   { 'name': '-',                                                                   'web_disp': '-'},
//         }
function getPfsInfo (pfsCode) {
  const packagePfsTable = [
    { code: 'PFS-10', action_status: '処理前' },
    { code: 'PFS-20', action_status: '転送待ち' },
    { code: 'PFS-30', action_status: '転送中' },
    { code: 'PFS-31', action_status: '転送中_ダウンロード中' },
    { code: 'PFS-32', action_status: '転送中_解凍中' },
    { code: 'PFS-33', action_status: '転送中_スケジュール登録中' },
    { code: 'PFS-40', action_status: '転送成功' },
    { code: 'PFS-50', action_status: '中断済み' },
    { code: 'PFS-60', action_status: '転送失敗' },
    { code: 'PFS-61', action_status: '転送失敗_ダウンロード失敗' },
    { code: 'PFS-62', action_status: '転送失敗_解凍失敗' },
    { code: 'PFS-63', action_status: '転送失敗_スケジュール登録失敗' },
    { code: 'PFS-64', action_status: '転送失敗_処理異常終了' }
  ]
  let pfsInfo = { code: '', action_status: '', active: false }
  let flagExist = false
  packagePfsTable.forEach(pfsTableInfo => {
    // pfsCodeが一致したか?
    if (pfsTableInfo.code === pfsCode) {
      flagExist = true
      pfsInfo = pfsTableInfo
    }
  })
  return { pfsInfo: pfsInfo, flagExist: flagExist }
}
// **************************************
// PHS(パッケージ全体ステータス)情報検索
// **************************************
// *** 現行コード値参考 ***
// d_inst_process_st = {
//         'PHS-10':  { 'name': '作成待ち(PHS-10)',                             'web_disp': 'パッケージ作成待ち(PHS-10)' },
//         'PHS-20':  { 'name': '作成中(PHS-20)',                               'web_disp': 'パッケージ作成中(PHS-20)' },
//         'PHS-30':  { 'name': '作成完了(PHS-30)',                             'web_disp': 'パッケージ作成完了(PHS-30)' },
//         'PHS-40':  { 'name': '承認フェーズ(PHS-40)',                         'web_disp': 'パッケージ承認処理中(PHS-40)' },
//         'PHS-50':  { 'name': '転送開始待ち(PHS-50)',                         'web_disp': 'パッケージ転送開始待ち(PHS-50)' },
//         'PHS-60':  { 'name': '1台以上で転送中(PHS-60)',                      'web_disp': 'パッケージ転送中(PHS-60)' },
//         'PHS-70':  { 'name': '全転送先へ転送終了_全装置で正常終了(PHS-70)',  'web_disp': 'パッケージ転送成功(PHS-70)' },
//         'PHS-80':  { 'name': '全転送先へ転送終了_1台以上で転送失敗(PHS-80)', 'web_disp': 'パッケージ転送失敗(PHS-80)' },
//         'PHS-90':  { 'name': '転送中断処理中_1台以上で未だ転送中(PHS-90)',   'web_disp': 'パッケージ転送中（中断処理中）(PHS-90)' },
//         'PHS-100': { 'name': '転送中断済み(PHS-100)',                        'web_disp': 'パッケージ転送中断済み(PHS-100)' },
//         'PHS-110': { 'name': '再転送準備待ち(PHS-110)',                      'web_disp': 'パッケージ再転送準備待ち(PHS-110)' },
//         'PHS-120': { 'name': '再転送準備中_PKG改版中(PHS-120)',              'web_disp': 'パッケージ再転送準備中(PHS-120)' },
//         'PHS-130': { 'name': '再転送準備失敗_PKG改版失敗(PHS-130)',          'web_disp': 'パッケージ再転送準備失敗(PHS-130)' },
//         'PHS-140': { 'name': '削除済み(PHS-140)',                            'web_disp': 'パッケージ削除済み(PHS-140)' },
//         'PHS-150': { 'name': '作成失敗(PHS-150)',                            'web_disp': 'パッケージ作成失敗(PHS-150)' },
//         'PHS-160': { 'name': '却下済み(PHS-160)',                            'web_disp': 'パッケージ却下済み(PHS-160)' },
//         'PHS-XX':  { 'name': '-',                                            'web_disp': '-' },
//         }
function getPhsInfo (phsCode) {
  const packagePhsTable = [
    { code: 'PHS-11', action_status: 'パッケージ作成中', active: true, del_btn_disable: true },
    { code: 'PHS-20', action_status: 'パッケージ作成完了', active: true, del_btn_disable: true },
    { code: 'PHS-30', action_status: 'パッケージ転送開始待ち', active: true, del_btn_disable: true },
    { code: 'PHS-40', action_status: 'パッケージ転送中', active: true, del_btn_disable: true },
    { code: 'PHS-51', action_status: 'パッケージ転送成功', active: false, del_btn_disable: false }, // 処理中一覧に表示しない
    { code: 'PHS-52', action_status: 'パッケージ転送失敗', active: true, del_btn_disable: false },
    { code: 'PHS-61', action_status: 'パッケージ転送中（中断処理中）', active: true, del_btn_disable: true },
    { code: 'PHS-62', action_status: 'パッケージ転送中断済み', active: true, del_btn_disable: true },
    { code: 'PHS-70', action_status: 'パッケージ削除済み', active: false, del_btn_disable: true }, // 処理中一覧に表示しない、APIから渡されれば全一覧に表示する
    { code: 'PHS-80', action_status: 'パッケージ却下済み', active: false, del_btn_disable: true } //  処理中一覧に表示しない
  ]
  let phsInfo = { code: '', action_status: '', active: false }
  let flagExist = false
  packagePhsTable.forEach(phsTableInfo => {
    // phsCodeが一致したか?
    if (phsTableInfo.code === phsCode) {
      flagExist = true
      phsInfo = phsTableInfo
    }
  })
  return { phsInfo: phsInfo, flagExist: flagExist }
}
// *******************************************************
// 終了コードJSONオブジェクト->終了コード一覧用配列変数化
// *******************************************************
function convertEndcodeJsonArray (endcodeJsonObj) {
  // console.log('PackageDeviceSelect.vue convertEndcodeJsonArray:')
  // *** 関数convertEndcodeJsonArray()の目的
  // *** 終了コードJSONオブジェクトが既に有る前提で
  // *** タスク終了コード一覧の画面表示に使用する配列変数を作成する。
  // ***
  // ********** JSONオブジェクトをJSON.stringify()関数でJSONテキスト化(改行なし)化する
  var endcodeJsonTextNoCR = JSON.stringify(endcodeJsonObj)
  // console.log('endcodeJsonTextNoCR')
  // console.log(endcodeJsonTextNoCR)
  var lenEndcodeJsonTextNoCR = endcodeJsonTextNoCR.length
  // console.log('lenEndcodeJsonTextNoCR=' + lenEndcodeJsonTextNoCR)
  // ********** JSONテキスト(改行なし)を終了コードの指定行単位に改行する
  // JSON.parse()にて作成したObjectがforやEachで順読みできなかった為
  // 終了コードの指定単位をArray配列で読み取り可能な形式に変換する作業をする
  // 例. [{ "0": { "lv": "info", "msg": "XXメッセージ"}, {...}, {...} ]
  // JSONテキスト化(改行なし)が中身がない状態ではないか? 例."{}"
  var strJarray = '['
  if (lenEndcodeJsonTextNoCR > 2) {
    // ********** strJsonArrayに終了コードの1行単位の情報を配列化して格納
    var strLine = ''
    var strJsonArray = []
    // JSONテキスト化(改行なし)を1文字ずつスキャンする
    for (let i = 0; i < lenEndcodeJsonTextNoCR; i++) {
      var strOneChar = endcodeJsonTextNoCR.slice(i, i + 1)
      var str1b = endcodeJsonTextNoCR.slice(i, i + 2)
      // 最初の終了コード設定情報(1行分)
      if (!(strLine === '' && strOneChar === '{')) {
        if ((str1b === '},') || (str1b === '}}')) {
          i++
          strLine += '}' // ここに制御が来た時、strLineは、「"123":{"lv":1,"msg":"正常"}」の様な状態になる
          var splitJsonString = strLine.split(':') // 1行の文字列を":"で分割、先頭の"123"の部分がsplitJsonString[0]に入る
          strLine += ', "ID":' + splitJsonString[0]
          strJsonArray.push(strLine)
          strLine = ''
        } else {
          strLine += strOneChar
        }
      }
    }
    // ********** 終了コード行単位の情報を右記の様に配列化する。
    // 例. [
    //       "123":{lv:1, msg:'xx', ID:"123"},
    //       "234":{lv:2, msg:'yy', ID:"234"},
    //       "other":{lv:4, msg:'zz', ID:"other"}
    //     ]
    // console.log('strJsonArray')
    // console.log(strJsonArray)
    strJsonArray.forEach(jline => {
      if (!(strJarray === '[')) {
        strJarray += ','
      }
      strJarray += '{' + jline + '}'
    })
  }
  strJarray += ']'
  // console.log('strJarray')
  // console.log(strJarray)
  // **********
  var endcodeJsonObj2 = {}
  try {
    endcodeJsonObj2 = JSON.parse(strJarray)
  } catch (err) {
    // ここに制御が来る場合はJSONテキスト成形ロジックがプログラムミスした時で通常あり得ない
    console.log('endcodeJsonTextNoCR')
    console.log(endcodeJsonTextNoCR)
    console.log('JSON.parseエラー\nerr=' + err + '\nJSONテキスト成形ロジックが不正です。')
    return []
  }
  // console.log('endcodeJsonObj2')
  // console.log(endcodeJsonObj2)
  var endcodeInfoListArray = []
  endcodeJsonObj2.forEach(JsonObj2row => {
    // console.log(JsonObj2row)
    var ID = JsonObj2row.ID //        # ID: 文字列
    var Lv = JsonObj2row[ID].lv //    # lv:  数値
    var Msg = JsonObj2row[ID].msg //  # msg: 文字列
    const addEnd = { ID: ID, important: Lv, message: Msg }
    endcodeInfoListArray.push(addEnd)
  })
  return endcodeInfoListArray // *** 終了コード一覧表示に使用できる配列変数を返す
}
// ********************************************************************
// 終了コードJSONテキストを正規化し、終了コード指定毎の¥に改行成形する
// ********************************************************************
function makeEndcodeJsonFormatText (endcodeListArray) {
  var strJsonLineText = '{\n'
  const strJsonLineTextInit = strJsonLineText
  var flagExistOther = false
  endcodeListArray.forEach(JsonArrayrow => {
    // 初回行以外か?
    if (!(strJsonLineText === strJsonLineTextInit)) {
      // 継続カンマと改行を追加
      strJsonLineText += ',\n'
    }
    strJsonLineText += '"' + JsonArrayrow.ID + '":{"lv":' + JsonArrayrow.important + ',"msg":"' + JsonArrayrow.message + '"}'
    if (JsonArrayrow.ID === 'other') {
      flagExistOther = true
    }
  })
  if (flagExistOther === false) {
    if (strJsonLineText === strJsonLineTextInit) {
      strJsonLineText = '{\n"other":{"lv":4,"msg":"-"}'
    } else {
      strJsonLineText += ',\n"other":{"lv":4,"msg":"-"}'
      // alert('終了コード"other"が無くなっている為、初期状態の"other"の行を追加します。')
    }
  }
  strJsonLineText += '\n}'
  return strJsonLineText
}
export {
  makeEndcodeJsonFormatText,
  convertEndcodeJsonArray,
  getPksInfo,
  getPfsInfo,
  getPhsInfo
}
