<template>
  <div>

    <loading v-show="isLoading" />
    <div v-show="!isLoading">

      <div>
        <h1>{{ title }}</h1>
      </div>

      <div v-show="HostGuest==='エッジサーバ'">
        <v-row>
          <v-col>
            <div>
              <v-simple-table id="device_l" dense>
              <tbody>
                <tr><th>サーバ種別</th><td>{{ device_info.host_guest }}</td></tr>
                <tr><th colspan="2">エッジサーバ情報</th></tr>
                <tr><th>型番</th><td> {{ device_info.model_number }} </td></tr>
                <tr><th>シリアル番号</th><td> {{ device_info.serial_number }} </td></tr>
              </tbody>
              </v-simple-table>
            </div>
          </v-col>
          <v-col>
            <div>
              <v-simple-table id="device_r" dense>
              <tbody>
                <tr><th colspan="2">管理機器情報</th></tr>
                <tr><th>ホスト名</th><td> {{ device_info.hostname }} </td></tr>
                <tr><th>有効情報</th>
                  <td>
                    <!--span v-if='device_info.enable_info=="HS-10"'>新規登録状態</span>
                    <span v-else-if='device_info.enable_info=="HS-20"'>有効</span>
                    <span v-else-if='device_info.enable_info=="HS-30"'>無効</span>
                    <span v-else>({{ device_info.enable_info }}</span-->
                    {{ device_info.enable_info }}
                  </td>
                </tr>
                <tr><th>死活ステータス</th><td>{{ device_info.saf }}</td></tr>
                <tr><th>定期通報受信日時</th><td> {{ device_info.latest_collect_info_datetime }} </td></tr>
                <tr><th>契約番号</th><td> {{ device_info.contract_name }} </td></tr>
                <tr><th>契約種別</th><td> {{ type_name }} </td></tr>
              </tbody>
              </v-simple-table>
            </div>
          </v-col>
        </v-row>
      </div>

      <div v-show="HostGuest==='仮想化基盤'">
        <v-row>
          <v-col>
            <div>
              <v-simple-table id="device_l" dense>
              <tbody>
                <tr><th>サーバ種別</th><td>{{ device_info.host_guest }}</td></tr>
                <tr><th colspan="2">仮想化基盤情報</th></tr>
                <tr><th>仮想マシン名</th><td> <span v-if='device_info.vm_info!==undefined'>{{ device_info.vm_info.vm_name }}</span> </td></tr>
                <tr><th>ゲストサーバ状態(State)</th><td> <span v-if='device_info.vm_info!==undefined'>{{ device_info.vm_info.guestserver_state }}</span> </td></tr>
                <tr><th>ゲストサーバ状態(Status)</th><td> <span v-if='device_info.vm_info!==undefined'>{{ device_info.vm_info.guestserver_status }}</span> </td></tr>
                <tr><th colspan="2">OS情報</th></tr>
                <tr><th>名称</th><td> <span v-if='device_info.vm_info!==undefined'>{{ device_info.vm_info.os_name }}</span> </td></tr>
                <tr><th>バージョン</th><td> <span v-if='device_info.vm_info!==undefined'>{{ device_info.vm_info.os_version}}</span> </td></tr>
                <tr><th>アーキテクチャ</th><td> <span v-if='device_info.vm_info!==undefined'>{{ device_info.vm_info.architecture }}</span> </td></tr>
                <!--tr><th>カーネルバージョン</th><td> <span v-if='device_info.vm_info!==undefined'>{{ device_info.vm_info.kernel_version}}</span> </td></tr-->
                <tr><th>サービスパックバージョン</th><td> <span v-if='device_info.vm_info!==undefined'>{{ device_info.vm_info.service_pack_version }}</span> </td></tr>
                <tr><th>ビルド番号</th><td> <span v-if='device_info.vm_info!==undefined'>{{ device_info.vm_info.build_number }}</span> </td></tr>
                <tr><th>プロダクト種別</th><td> <span v-if='device_info.vm_info!==undefined'>{{ device_info.vm_info.product_type }}</span> </td></tr>
                <tr><th>インストール日時</th><td> <span v-if='device_info.vm_info!==undefined'>{{ device_info.vm_info.installed_datetime }}</span> </td></tr>
              </tbody>
              </v-simple-table>
            </div>
          </v-col>
          <v-col>
            <div>
              <v-simple-table id="device_r" dense>
              <tbody>
                <tr><th colspan="2">管理機器情報</th></tr>
                <tr><th>ホスト名</th><td> {{ device_info.hostname }} </td></tr>
                <tr><th>有効情報</th>
                  <td>
                    <!--span v-if='device_info.enable_info=="HS-10"'>新規登録状態</span>
                    <span v-else-if='device_info.enable_info=="HS-20"'>有効</span>
                    <span v-else-if='device_info.enable_info=="HS-30"'>無効</span>
                    <span v-else>不明({{ device_info.enable_info }})</span-->
                    {{ device_info.enable_info }}
                  </td>
                </tr>
                <tr><th>死活ステータス</th><td>{{ device_info.saf }}</td></tr>
                <tr><th>定期通報受信日時</th><td> {{ device_info.latest_collect_info_datetime }} </td></tr>
                <tr><th>契約番号</th><td> {{ device_info.contract_name }} </td></tr>
                <tr><th>契約種別</th><td> {{ type_name }} </td></tr>
              </tbody>
              </v-simple-table>
            </div>
          </v-col>
        </v-row>
      </div>

      <div>
        <v-btn color="normal" v-on:click="back_">戻る</v-btn>
      </div>

      <!-- 下方向にマージン -->
      <v-col class="mb-1" />
      <div>
        <v-card class='contents-new'>
          <v-tabs v-model="tab" @change="log">
            <v-tab v-on:click='selectDevTab()'>関連機器</v-tab>
            <v-tab v-on:click='selectPkgTab()'>パッケージ</v-tab>
            <v-tab v-on:click='selectHisTab()'>パッケージログ</v-tab>
            <v-tab v-on:click='selectErrTab()'>パッケージログ結果(未確認)</v-tab>
          </v-tabs>
          <ul>
            <li>
              <TabWindow ref="sub_dev" v-show="tab === 0" />
              <TabWindow ref="sub_pkg" v-show="tab === 1" />
              <TabWindow ref="sub_his" v-show="tab === 2" />
              <TabWindow ref="sub_err" v-show="tab === 3" />
           </li>
          </ul>
        </v-card>
      </div>

    </div>

  </div>
</template>

<script>
import TabWindow from '@/components/device/DeviceDetail-Tab'
import axios from 'axios'
import Loading from '@/components/Loading'

export default {
  components: {
    Loading,
    // DeviceDetailRelated,
    // DeviceDetailPackage,
    // ListNA,
    TabWindow
  },
  name: 'DeviceDetailView',
  data: () => ({
    HostGuest: '',
    title: '機器詳細',
    permission: false,
    isLoading: true,
    error_message: '',

    tab: null,
    type_name: 'シングルサイトマネジメント版',
    device_info:
    {
      /*
      machine_id: 'HOST-0001',
      host_machine_id: '',
      host_guest: 'ホスト装置',
      model_number: 'xxxx',
      serial_number: 'yyyy',
      hostname: 'ホスト1',
      saf: '正常',
      enable_info: 'HS-20',
      contract_number: '1234',
      contract_name: '契約1',
      latest_collect_info_datetime: '2021/10/01T12:34:56',
      // 以下は仮想マシン(ゲスト)でのみ有効
      vm_info: {
        vm_name: 'vm name',
        guestserver_state: '正常',
        guestserver_status: '正常',
        os_name: 'Windows 2019',
        os_version: 'xxx',
        architecture: 'x64',
        kernel_version: '1.2.34.5',
        service_pack_version: 'xxx',
        build_number: 'build',
        product_type: 'type xxx',
        installed_datetime: '2021/10/01T12:34:56'
      }
      */
    }
  }),

  mounted: function () {
    // スーパーユーザー組織の場合は表示しない
    if (this.$store.getters.user.role[0] && (this.$store.getters.user.orgid.slice(0, 2) === '80')) {
      console.log('device permission denied.')
      return
    }
    this.$nextTick(function () {
      this.getRestApiDevice(null)
    })
  },

  watch: {
    tab: {
      handler: function () {
        // console.log('watch tab:' + this.tab)
      }
    }
  },

  methods: {
    setContractType: function () {
      this.type = this.device_info.contract_type
      if (this.device_info.contract_type === 'patch') {
        this.type_name = 'シングルサイトマネジメント版'
      } else if (this.device_info.contract_type === 'appliance') {
        this.type_name = 'マルチサイトマネジメント版'
      }
    },
    selectDevTab: function () {
      this.$refs.sub_dev.selectDevTab()
    },
    selectPkgTab: function () {
      this.$refs.sub_pkg.selectPkgTab()
    },
    selectHisTab: function () {
      this.$refs.sub_his.selectHisTab()
    },
    selectErrTab: function () {
      this.$refs.sub_err.selectErrTab()
    },

    log: function () {
      // console.log('tab:' + this.tab)
    },

    back_: function () {
      this.$router.push({ name: 'Device' })
    },

    getRestApiDevice: function () {
      axios.get('/machines/' + `${this.$route.params.id}`,
        {
          headers: { Authorization: this.$store.getters.user.token },
          params: { org: this.$store.getters.user.orgid }
        }
      ).then((res) => {
        // console.log(res)
        // console.log(res.data)
        this.device_info = res.data
        this.HostGuest = this.device_info.host_guest
        this.setContractType()

        this.isLoading = false
        // 初回読み込みでは関連機器タブ
        this.selectDevTab()
      }).catch((error) => {
        console.log(error)
        // alert(error)
        alert('情報の取得に失敗しました。')
        // this.error_message += '[GET /machines]: Failed' + '\n'
        this.isLoading = false
      })
    }
  }
}
</script>
<style scoped>
#device_l table{ width:100%; }
#device_r table{ width:100%; }
#device_l th{ min-width:80px; max-width:90px; background-color:#dcdff1; border: 1px #808080 solid; user-select: auto;}
#device_r th{ min-width:80px; max-width:90px; background-color:#dcdff1; border: 1px #808080 solid; user-select: auto;}
#device_l td{ border: 1px #808080 solid; background: #fff;}
#device_r td{ border: 1px #808080 solid; background: #fff;}

ul{
  margin: 0;
  padding: 0;
}
li{
  list-style: none;
}
.tabs {
  overflow: hidden;
}
.tabs li,
.tabs label {
  float: left;
  padding: 10px 20px;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: .3s;
}
.tabs li:not(:first-child),
.tabs label:not(:first-of-type) {
  border-left: none;
}
.tabs li.active,
.tabs :checked + label {
  background-color: #000;
  border-color: #000;
  color: #fff;
  cursor: auto;
}
.contents{
  overflow: hidden;
  margin-top: -1px;
}
.contents li {
  padding: 20px;
  border: 1px solid #ccc;
}
.contents-new {
  padding-left: 24px;
  padding-right: 24px;
}
</style>
