<template>
  <div>
    <loading v-show="isLoading"></loading>
    <div v-show="!isLoading">
      <div id="id_table" class="user_list">
        <div id="card_title">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              dense
              >
            </v-text-field>
          </v-card-title>
        </div>
        <div id="data_table">
          <v-data-table
            v-model="selectedDevices"
            :headers="t_headers"
            :items="tenantDevices"
            item-key="machine_id"
            :search="search"
            :sort-by="['contract_number']"
            show-select
            dense
            >
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Loading from '@/components/Loading'

export default {
  name: 'PackageDevice',
  props: {
    base_parm_info: {}
  },
  data: () => ({
    search: '',
    package_info: {},
    t_headers: [
      // { text: 'ID', value: 'machine_id' },
      { text: '契約番号', value: 'contract_name' },
      { text: '型番', value: 'model_number' },
      { text: 'シリアル', value: 'serial_number' },
      { text: 'ホスト名', value: 'hostname' }
    ],
    selectedDevices: [],
    tenantDevices: [],
    tenantDevices_enable: [],
    parm_info: {},
    isLoading: true
  }),
  components: {
    Loading
  },
  watch: {
    // *******************************
    // 変数selectedDevices更新時
    // *******************************
    // 機器の選択状態が変化した時、選択している選択装置一覧配列(this.selectedDevices)を親画面VUEに伝達する
    selectedDevices: {
      handler: function () {
        this.$emit('SelectDevice', this.selectedDevices)
      }
    }
  },
  // *******************************
  // モジュール生成時
  // *******************************
  created: function () {
    this.isLoading = true
    // console.log('PackageDeviceSelect.vue created:')
    this.parm_info = this.base_parm_info
    // console.log('this.parm_info')
    // console.log(this.parm_info)
  },
  // *******************************
  // 画面マウント時
  // *******************************
  mounted: function () {
    // console.log('PackageDeviceSelect.vue mounted:')
    this.getRestApi() // 対象装置一覧の取得、及び選択済の装置一覧の取得
  },
  methods: {
    // ***********************************************
    // 対象装置一覧の取得、及び選択済の装置一覧の取得
    // ***********************************************
    getRestApi: function () {
      // console.log('PackageDeviceSelect.vue getRestApi:')
      // ********** 装置関連APIの/machines GETメソッド 《テナント所属装置一覧を取得》
      axios.get('/machines',
        {
          params: {
            org: this.$store.getters.user.orgid //    # スーパーユーザは他テナントの情報を取得可能なため指定が・テナントIDを指定
          },
          headers: { Authorization: this.$store.getters.user.token }
        }
      ).then((res1) => {
        // テナント所属装置一覧を取得が成功した時
        // console.log('PackageDeviceSelect.vue getDeviceListApi(テナント所属装置一覧を取得): then get 〇success')
        // console.log(res1.data)
        this.tenantDevices_enable = []
        res1.data.forEach(tenantDevicesRow => { // ********** "有効"な装置のみを抽出("新規登録"を除外)
          if (tenantDevicesRow.enable_info === '有効') {
            this.tenantDevices_enable.push(tenantDevicesRow)
          }
        })
        // ********** パッケージ作成、流用パッケージ作成(削除パッケージ作成以外)の時は、
        // ********** 一覧データはテナントに属する"有効"状態の装置一覧に設定、削除パッケージ作成の時の場合は、後続処理で作成
        if (!(this.parm_info.actionType === 'delete')) {
          this.tenantDevices = this.tenantDevices_enable
          this.selectedDevices = []
        }
        // パッケージ作成か?
        if (this.parm_info.actionType === 'new') {
          this.isLoading = false
        } else {
          // *** 流用パッケージ作成や削除パッケージ作成時、選択対象装置一覧を取得
          // console.log('/packages/' + this.parm_info.pkgid + '/machines')
          // ********** パッケージ関連APIの/packages/{id}/machines GETメソッド 《パッケージ適用先一覧(対象装置一覧)》
          axios.get('/packages/' + this.parm_info.pkgid + '/machines',
            {
              params: { org: this.$store.getters.user.orgid },
              headers: { Authorization: this.$store.getters.user.token }
            }
          ).then((res2) => {
            // 選択対象装置一覧を取得が成功した時
            // console.log('PackageDeviceSelect.vue getRestApi(選択対象装置一覧): then get 〇success')
            // console.log(res2.data)
            var selectDevice = res2.data
            // ********** 削除パッケージ作成か?
            // パッケージ選択対象装置一覧を基準にテナントに属するマシンIDの一致する装置を一覧作成し全て選択状態にする
            if (this.parm_info.actionType === 'delete') {
              this.selectedDevices = []
              this.tenantDevices = []
              selectDevice.forEach(selectDeviceRow => { // パッケージ選択対象装置一覧でループ
                this.tenantDevices_enable.forEach(DeviceRow => { // テナントに属する有効なマシン装置一覧でループ
                  if (DeviceRow.machine_id === selectDeviceRow.machine_id) {
                    if (selectDeviceRow.enable_info === '有効') {
                      if (selectDeviceRow.pks === 'PKS-20') { // タスク登録済みの装置のみ
                        this.tenantDevices.push(DeviceRow)
                        this.selectedDevices.push(selectDeviceRow)
                      }
                    }
                  }
                })
              })
            // ********** 流用パッケージ作成か?
            // テナントに属するマシン装置を基準に一覧を作成しパッケージ適用装置とマシンIDが一致したもののみ選択状態にする
            } else {
              this.selectedDevices = []
              this.tenantDevices.forEach(DeviceRow => { // テナントに属するマシン装置一覧でループ
                selectDevice.forEach(selectDeviceRow => { // パッケージ選択対象装置一覧でループ
                  if (DeviceRow.machine_id === selectDeviceRow.machine_id) {
                    this.selectedDevices.push(DeviceRow)
                  }
                })
              })
            }
            this.isLoading = false
          }).catch((error) => {
            // 選択対象装置一覧を取得が失敗した時
            console.log('PackageDeviceSelect.vue getRestApi(選択対象装置一覧): ★catch error=' + error)
            this.isLoading = false
            alert('対象装置一覧取得に失敗\n通信エラーが起きました。リロードするか時間をおいてアクセスしてください。\n' + error)
          })
        }
      }).catch((error) => {
        // テナント所属装置一覧を取得が失敗した時
        console.log('PackageDeviceSelect.vue getRestApi(テナント所属装置一覧を取得: ★catch error=' + error)
        this.tenantDevices = []
        this.isLoading = false
        alert('対象装置一覧取得に失敗\n通信エラーが起きました。リロードするか時間をおいてアクセスしてください。\n' + error)
      })
    }
  }
}
</script>

<style scoped>
.user_list th{ background-color:#dcdff1; border: 1px #808080 solid;}
.user_list td{ border: 1px #808080 solid; text-align:left;}
</style>
