var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}]},[_c('div',[_c('h1',[_vm._v(_vm._s(_vm.page_title))])]),_c('div',[_c('div',[_c('v-simple-table',{attrs:{"id":"user_edit","dense":""}},[_c('tbody',[_c('tr',[_c('th',{attrs:{"width":"50px"}},[_vm._v("ユーザ名")]),_c('td',[_c('v-text-field',{attrs:{"name":"name","placeholder":"ユーザ名","label":"ユーザの姓名を入力","rules":[
                _vm.required,
                _vm.limit_length100
              ]},model:{value:(_vm.user_info.name),callback:function ($$v) {_vm.$set(_vm.user_info, "name", $$v)},expression:"user_info.name"}})],1)]),_c('tr',[_c('th',{attrs:{"width":"50px"}},[_vm._v("所属")]),_c('td',[_c('v-text-field',{attrs:{"name":"department","placeholder":"所属","label":"所属を入力","rules":[
                _vm.limit_length300
              ]},model:{value:(_vm.user_info.department),callback:function ($$v) {_vm.$set(_vm.user_info, "department", $$v)},expression:"user_info.department"}})],1)]),_c('tr',[_c('th',{attrs:{"width":"50px"}},[_vm._v("メールアドレス")]),_c('td',[_c('v-text-field',{attrs:{"name":"email","placeholder":"メールアドレス","label":"メールアドレスを入力","rules":[
                _vm.required,
                _vm.limit_length254,
                _vm.emailRules
              ]},model:{value:(_vm.user_info.email),callback:function ($$v) {_vm.$set(_vm.user_info, "email", $$v)},expression:"user_info.email"}})],1)]),(!_vm.is_superuser_orgid_80xxxx && !_vm.is_wsususer_orgid_7xxx2x)?_c('tr',[_c('th',{attrs:{"width":"50px"}},[_vm._v("ロール")]),_c('td',{attrs:{"width":"200px"}},[_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"label":"管理者"},model:{value:(_vm.user_info.admin),callback:function ($$v) {_vm.$set(_vm.user_info, "admin", $$v)},expression:"user_info.admin"}})],1),_c('v-col',[_c('v-checkbox',{attrs:{"label":"作業者"},model:{value:(_vm.user_info.operator),callback:function ($$v) {_vm.$set(_vm.user_info, "operator", $$v)},expression:"user_info.operator"}})],1),_c('v-col',{attrs:{"align-self":"center"}},[_vm._v("いずれかまたは"),_c('br'),_vm._v("両方にチェック")]),_c('v-col')],1)],1)]):_vm._e()])])],1),_c('div',[_c('br'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.add_}},[_vm._v("登録")]),_c('a',[_vm._v(" ")]),_c('v-btn',{attrs:{"color":"normal"},on:{"click":_vm.back_}},[_vm._v("戻る")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }