<template>
  <div>

    <loading v-show="isLoading" />
    <div v-show="!isLoading">
      <div>
        <h1>{{ title }}</h1>
      </div>
      <!--v-divider /-->
      <div class="device_list">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
          dense
          :headers="t_headers"
          :items="devices"
          :search="search"
          :sort-by="['contract_number']"
          @click:row="onClickDeviceCell"
        >
          <!--template v-slot:item.enable_info="{ item }">
            <span v-if='item.enable_info=="HS-10"'>新規登録状態</span>
            <span v-else-if='item.enable_info=="HS-20"'>有効</span>
            <span v-else-if='item.enable_info=="HS-30"'>無効</span>
            <span v-else>不明({{ item.enable_info }})</span>
          </template-->
        </v-data-table>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import Loading from '@/components/Loading'
import rmtlib from '@/plugins/class/rmtlib'

export default {
  components: {
    Loading
  },
  name: 'DeviceListView',
  data: () => ({
    title: '機器一覧',
    permission: false,
    isLoading: true,
    error_message: '',
    search: '',
    t_headers: [
      {
        text: 'サーバ種別',
        value: 'host_guest',
        align: 'start',
        filterable: true
      },
      { text: '契約番号', value: 'contract_name' },
      { text: '型番', value: 'model_number' },
      { text: 'シリアル番号', value: 'serial_number' },
      { text: 'ホスト名', value: 'hostname' },
      { text: '死活ステータス', value: 'saf' },
      { text: '有効情報', value: 'enable_info' }
    ],
    devices: [
      /*
      { machine_id: 'HOST-001', host_machine_id: '2', host_guest: 'ホスト装置', model_number: '000001', serial_number: '123456', hostname: 'ホスト1', saf: 'status1', enable_info: 'HS-10', contract_number: '12345' },
      { machine_id: 'HOST-002', host_machine_id: '2', host_guest: 'ホスト装置', model_number: '000001', serial_number: '123456', hostname: 'ホスト1', saf: 'status1', enable_info: 'HS-30', contract_number: '12345' },
      { machine_id: 'GUEST-001', host_machine_id: '2', host_guest: 'ゲスト装置', model_number: '000001', serial_number: '123456', hostname: 'ゲスト1', saf: 'status2', enable_info: 'HS-20', contract_number: '12345' },
      { machine_id: 'GUEST-002', host_machine_id: '2', host_guest: 'ゲスト装置', model_number: '000001', serial_number: '123456', hostname: 'ゲスト1', saf: 'status2', enable_info: 'HS-20', contract_number: '12345' }
      */
    ]
  }),

  beforeMount: function () {
  },

  mounted: function () {
    // WSUS組織では専用ページに移動させる
    if (rmtlib.isWsusOrg(this.$store.getters.user.orgid)) {
      this.$router.replace('/Wsus/Device')
      return
    }
    // スーパーユーザー組織の場合は表示しない
    if (this.$store.getters.user.role[0] && (this.$store.getters.user.orgid.slice(0, 2) === '80')) {
      console.log('device permission denied.')
      return
    }
    this.$nextTick(function () {
      this.getRestApiDevice()
    })
  },

  watch: {
    //
  },

  methods: {
    onClickDeviceCell: function (data) {
      // console.log('click onCell:' + data.number)
      this.$router.push({ name: 'DeviceDetail', params: { id: data.machine_id } })
    },
    getRestApiDevice: function () {
      axios.get('/machines',
        {
          headers: { Authorization: this.$store.getters.user.token },
          params: { org: this.$store.getters.user.orgid }
        }
      ).then((res) => {
        // console.log(res)
        // console.log(res.data)
        this.devices = res.data
        /*
        this.devices.forEach(dev => {
          if (dev.enable_info === 'HS-10') {
            dev.enable_info = '新規登録状態'
          } else if (dev.enable_info === 'HS-20') {
            dev.enable_info = '有効'
          } else if (dev.enable_info === 'HS-30') {
            dev.enable_info = '無効'
          } else {
            dev.enable_info = `${dev.enable_info}`
          }
        })
        */
        this.isLoading = false
      }).catch((error) => {
        console.log(error)
        // alert(error)
        alert('情報の取得に失敗しました。')
        // this.error_message += '[GET /machines]: Failed' + '\n'
        this.isLoading = false
      })
    }
  }
}
</script>
<style scoped>
.device_list th{ background-color:#dcdff1; }
</style>
