<template>
  <v-footer app inset>
    <div class="row clearfix">
      <div class="col-12 text-center">
        <p>{{ copyright }}</p>
      </div>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  data: () => ({
  }),
  watch: {
    //
  },
  computed: {
    copyright: function () {
      return 'Copyright (C) NEC Corporation ' + this.$store.getters.system.year + '. All rights reserved'
    }
  },
  methods: {
    //
  }
}
</script>
