import Vue from 'vue'
import VueRouter from 'vue-router'
import Top from '@/views/Top'
import Login from '@/views/Login'
import cognito from '@/components/cognito'
import store from '@/store'
import Device from '@/views/device/Device'
import DeviceDetail from '@/views/device/DeviceDetail'
import Package from '@/views/package/Package'
import PackageAdd from '@/views/package/PackageAdd'
import PackageDetail from '@/views/package/PackageDetail'
import User from '@/views/User'
import UserDetail from '@/views/UserDetail'
import AccountDetail from '@/views/AccountDetail'
import AccountEdit from '@/views/AccountEdit'
import UserEdit from '@/views/UserEdit'
import UserAdd from '@/views/UserAdd'
import OrganizationSelect from '@/views/OrganizationSelect'
import Contract from '@/views/contract/Contract'
import ContractDetail from '@/views/contract/ContractDetail'
import ContractEdit from '@/views/contract/ContractEdit'

// for wsus
import WsusSetting from '@/views/WsusSetting'
import WsusPackage from '@/views/package/Wsus_Package'
import WsusPackageAdd from '@/views/package/Wsus_PackageAdd'
import WsusPackageDetail from '@/views/package/Wsus_PackageDetail'
import WsusDevice from '@/views/wsus/WsusDevice'
import WsusDeviceDetail from '@/views/wsus/WsusDeviceDetail'

// for develop
// import About from '@/views/dev/About'

Vue.use(VueRouter)

const NotFound = { template: '<p>not found page</p>' }

// 認証が必要なページへの遷移は、cognitoの認証を確認して有効かを確認。
// 最初はセッションタイムアウト(アクセストークンのタイムアウト値で判断)を監視する。
const requireAuth = (to, from, next) => {
  // console.log('call requireAuth')

  if (cognito.isSettionTimeout()) {
    console.log('セッションタイムアウト')
    store.commit('reset')
    cognito.logout()
    next({ path: '/login' })
    return
  }

  cognito.isAuthenticated()
    .then(session => {
      // console.log(session)
      cognito.getAttribute()
        .then(result => {
          // ここまでがAWS SDK公式の認証確認。
          cognito.refreshSession(session)
            .then(result => {
              // console.log('認証確認に成功しました')
              // 更新されたのIDトークンを保存。
              // これらをREST API通信で使用する。
              store.commit('setToken', result.idToken.jwtToken)
            })
            .catch(session => {
              // F5更新を連続すると失敗する。タイミング？
              // 更新の流れでもう一度呼ばれるときは成功するため、失敗したときは何もせず、
              // 更新が成功したときにセッションを更新する。
              // console.log('認証確認に失敗しました3')
              // store.commit('reset')
              // logout(to, from, next)
            })
        })
        .catch(session => {
          // F5更新を連続すると失敗する。タイミング？
          // 更新の流れでもう一度呼ばれるときは成功するため、失敗したときは何もせず、
          // 更新が成功したときにセッションを更新する。
          // console.log('認証確認に失敗しました2')
          // store.commit('reset')
          // logout(to, from, next)
        })
      next()
    })
    .catch(error => {
      console.log('認証確認に失敗しました')
      console.log(error)
      store.commit('reset')
      cognito.logout()
      next({ path: '/login' })
    })
}

// ログアウト
const logout = (to, from, next) => {
  store.commit('reset')
  cognito.logout()
  next('/login')
}

const routes = [
  {
    path: '/',
    name: 'Top',
    beforeEnter: requireAuth,
    component: Top
  },
  {
    path: '/Device',
    name: 'Device',
    beforeEnter: requireAuth,
    component: Device
  },
  {
    path: '/Device/:id',
    name: 'DeviceDetail',
    beforeEnter: requireAuth,
    component: DeviceDetail
  },
  {
    path: '/Wsus/Device',
    name: 'Wsus_Device',
    beforeEnter: requireAuth,
    component: WsusDevice
  },
  {
    path: '/Wsus/Device/:id',
    name: 'Wsus_DeviceDetail',
    beforeEnter: requireAuth,
    component: WsusDeviceDetail
  },
  {
    path: '/Package',
    name: 'Package',
    beforeEnter: requireAuth,
    component: Package
  },
  {
    path: '/PackageAdd',
    name: 'PackageAdd',
    beforeEnter: requireAuth,
    component: PackageAdd // パッケージ作成と流用パッケージ作成のコンポーネントを共有化
  },
  {
    path: '/PackageDetail/:id',
    name: 'PackageDetail',
    beforeEnter: requireAuth,
    component: PackageDetail
  },
  {
    path: '/PackageEdit/:id/:actionType', // 流用パッケージ作成(actionType="reuse")、削除パッケージ作成(actionType="delete")
    name: 'PackageEdit',
    beforeEnter: requireAuth,
    component: PackageAdd
  },
  {
    path: '/Wsus/Package',
    name: 'Wsus_Package',
    beforeEnter: requireAuth,
    component: WsusPackage
  },
  {
    path: '/Wsus/PackageDetail/:id',
    name: 'Wsus_PackageDetail',
    beforeEnter: requireAuth,
    component: WsusPackageDetail
  },
  {
    path: '/Wsus/PackageEdit/:id/:actionType', // 流用パッケージ作成(actionType="reuse")、削除パッケージ作成(actionType="delete")
    name: 'Wsus_PackageEdit',
    beforeEnter: requireAuth,
    component: WsusPackageAdd
  },
  {
    path: '/User',
    name: 'User',
    beforeEnter: requireAuth,
    component: User
  },
  {
    path: '/User/:id',
    name: 'UserDetail',
    beforeEnter: requireAuth,
    component: UserDetail
  },
  {
    path: '/AccountDetail',
    name: 'AccountDetail',
    beforeEnter: requireAuth,
    component: AccountDetail
  },
  {
    path: '/AccountEdit',
    name: 'AccountEdit',
    beforeEnter: requireAuth,
    component: AccountEdit
  },
  {
    path: '/UserEdit/:id',
    name: 'UserEdit',
    beforeEnter: requireAuth,
    component: UserEdit
  },
  {
    path: '/UserAdd',
    name: 'UserAdd',
    beforeEnter: requireAuth,
    component: UserAdd
  },
  {
    path: '/OrganizationSelect',
    name: 'OrganizationSelect',
    beforeEnter: requireAuth,
    component: OrganizationSelect
  },
  {
    path: '/Contract',
    name: 'Contract',
    beforeEnter: requireAuth,
    component: Contract
  },
  {
    path: '/Contract/:id',
    name: 'ContractDetail',
    beforeEnter: requireAuth,
    component: ContractDetail
  },
  {
    path: '/Contract/:id/Edit',
    name: 'ContractEdit',
    beforeEnter: requireAuth,
    component: ContractEdit,
    props: { isEdit: true }
  },
  {
    path: '/ContractCreate',
    name: 'ContractCreate',
    beforeEnter: requireAuth,
    component: ContractEdit,
    props: { isEditPage: false }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter: logout
  },
  {
    path: '/Wsus',
    name: 'Wsus_Setting',
    beforeEnter: requireAuth,
    component: WsusSetting
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
