<template>
  <div>
    <loading v-show="isLoading"></loading>
    <div v-show="!isLoading">
      <div>
        <h1 id="id_title1">{{ title }}</h1>
      </div>
      <br>
      <div v-show="!($store.getters.user.role[0])">
        <!-- [転送開始]、[転送中止]、[転送再開]ボタン-->
        <v-btn color="info" @click="package_trans_action_" v-bind:disabled="flagTransActionDisable">
          {{ strTransActionCaption }}
        </v-btn>
        <a>&nbsp;</a>
        <v-btn color="primary" @click="add_reuse_package_" v-bind:disabled="flagMakeReusePackageDisable">流用パッケージ作成
        </v-btn>
        <a>&nbsp;</a>
        <v-btn color="primary" @click="add_delete_package_" v-bind:disabled="flagMakeDeletePackageDisable">削除パッケージ作成
        </v-btn>
        <a>&nbsp;</a>
        <v-btn color="normal" @click="back_">戻る</v-btn>
        &nbsp;&nbsp;
        <v-btn color="error" @click="delete_package_">パッケージ削除</v-btn>
      </div>
      <div v-show="($store.getters.user.role[0])">
        <v-btn color="normal" @click="back_">戻る</v-btn>
      </div>
      <br>
      <div>
        <div>
          <div>
            <v-simple-table id="package_table1">
              <tbody id="id_tbody_package1">
                <tr><th id="id_th_package_id">ID</th>
                  <td id="id_td_package_id"> <a v-on:click="package_id_click_">{{ package_info.pkg_id }}</a>
                  </td>
                </tr>
                <tr><th id="id_th_name">名称</th>
                  <td id="id_td_name"> {{ package_info.pkg_name }}
                  </td>
                </tr>
                <tr><th id="id_th_overview">概要</th>
                  <td id="id_td_overview"> {{ package_info.overview }}
                  </td>
                </tr>
                <tr><th id="id_th_detail">詳細</th>
                  <td id="id_td_detail">
                    <v-textarea
                      name="detail"
                      v-model="package_info.detail"
                      placeholder=""
                      label=""
                      no-resize
                      rows="3"
                      readonly
                    >
                    </v-textarea>
                  </td>
                </tr>
                <tr><th id="id_th_task">種別</th>
                  <td id="id_td_task"> {{ package_info.task }}
                  </td>
                </tr>
                <tr><th id="id_th_phs_web_disp">処理ステータス</th>
                  <td id="id_td_phs_web_disp"> {{ package_info.phs_web_disp }}
                  </td>
                </tr>
                <tr><th id="id_th_regdatetime">登録日</th>
                  <td id="id_td_regdatetime" > {{  package_info.registration_datetime}}
                  </td>
                </tr>
                <tr><th id="id_th_creator_id">作成者</th>
                  <td id="id_td_creator_id" > {{  package_info.creator_id}}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <div v-show="(package_info.task === 'タスク削除')">
            <br>
            <v-simple-table id="package_table2">
              <tbody id="id_tbody_package2">
                <tr><th id="id_th_base_package_name">ID(ベース)</th>
                  <td id="id_td_base_package_name" > {{  package_info.divert_pkg_id}}
                  </td>
                </tr>
                <tr><th id="id_th_base_package_name">名称(ベース)</th>
                  <td id="id_td_base_package_name" > {{  package_info_base_name }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <div v-show="!(package_info.task === 'タスク削除')">
            <br>
            <v-simple-table id="package_table3">
              <tbody id="id_tbody_package3">
                <tr>
                  <th width="50px">タスク終了コード</th>
                  <td>
                    <div>
                      <v-tabs v-model="tabidx" grow>
                        <v-tab
                          v-for="title in endcode_tab_titles"
                          :key="title.id"
                          @click="tab_click_"
                        >
                          {{ title.name }}
                        </v-tab>
                        <v-tab-item>
                          <v-card>
                            <v-card-text>
                              <!-- ************** タスク終了コード編集UI ************** -->
                              <!-- v-on:EndcodeTableは、子コンポーネントで更新した終了コードの情報を受信する指定 -->
                              <!-- :base_parm_infoは、子コンポーネントに親画面の特性を伝える指定 -->
                              <EndCodeList
                                v-on:EndcodeTable="receiveEndcodeTable"
                                :base_parm_info = "parm_info_for_endcode_list"
                                ref="EndCodeList"
                              >
                              </EndCodeList>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                        <v-tab-item>
                          <v-card>
                            <v-card-text>
                              <v-textarea
                                name="endcode_json"
                                v-model="package_info.task_exit_code_file"
                                placeholder="JSON"
                                label="終了コード(JSON)テキストを表示"
                                readonly
                                />
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                      </v-tabs>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th width="50px">実行処理</th>
                  <td><br>
                       スクリプトのファイル名: {{ package_info.exec.command }}<br>
                       スクリプトに与える引数: {{ package_info.exec.arguments }}<br>
                       停止するまでの時間(HH:MM):{{ package_info.exec.execution_time_limit }}<br>&nbsp;
                  </td>
                </tr>
                <tr>
                  <th width="50px">確認処理</th>
                  <td><br>
                       スクリプトのファイル名: {{ package_info.check.check_task_command }}<br>
                       スクリプトに与える引数: {{ package_info.check.check_task_arguments }}<br>
                       実行開始するまでの待ち時間(HH:MM):{{ package_info.check.estimated_execution_time }}<br>&nbsp;
                  </td>
                </tr>
                <tr><th id="id_th_schedule">起動条件</th>
                  <td id="id_td_schedule"><br>
                    {{ package_info.trigger_display }}<br>
                    <div v-show="(package_info.trigger.required_immediate_execution === 'True')">
                      スケジュール時刻にタスクを開始できなかった場合、すぐにタスクを実行<br>
                    </div>
                    <a>&nbsp;</a>
                  </td>
                </tr>
                <tr><th id="id_th_schedule">スケジュール</th>
                  <td id="id_td_schedule">
                    <br>
                    <div v-show="flagInterval">
                      間隔{{ package_info.interval_unit }}: {{ package_info.trigger.interval }}
                    </div>
                    <div v-show="flagDayofweek">
                      指定する曜日: {{ package_info.dayofweek_string }}
                    </div>
                    <div v-show="flagStartboundary">
                      開始日時: {{ package_info.trigger.start_boundary }}<br>
                    </div>
                    <div v-show="flagEndboundary">
                      有効期限日時: {{ package_info.trigger.end_boundary }}<br>
                    </div>
                    <br>
                  </td>
                </tr>
                <tr><th id="id_th_file_info">ファイル登録</th>
                  <td id="id_td_file_info">
                    <div v-show="(!(package_info.divert_pkg_id === ''))">
                      File: {{ package_info.upload_file_name }}&nbsp;&nbsp;流用元ID:{{ package_info.divert_pkg_id }}
                    </div>
                    <div v-show="(package_info.divert_pkg_id === '')">
                      File: {{ package_info.upload_file_name }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <br>
          <div>
            <div>
              <v-simple-table id="package_table3">
                <tbody id="id_tbody_package3">
                  <tr>
                    <!-- 対象装置一覧  -->
                    <div>
                      <div>
                        <h2 id="id_title2">対象装置一覧</h2>
                      </div>
                      <div id="id_device_list">
                        <PackageDeviceList :base_parm_info = "parm_info_for_device_list"></PackageDeviceList>
                      </div>
                    </div>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </div>
        </div>
      </div>
    </div><!-- div v-show -->
  </div>
</template>
<script>

import axios from 'axios'
import Loading from '@/components/Loading'
import PackageDeviceList from '@/components/package/PackageDeviceList'
import EndCodeList from '@/components/package/PackageEndcodeList'
import { convertEndcodeJsonArray, makeEndcodeJsonFormatText, getPhsInfo } from '@/components/common/PackageUtility.js'

export default {
  components: {
    EndCodeList,
    PackageDeviceList,
    Loading
  },
  name: 'PackageDetail',
  data: () => ({
    tabidx: 0,
    jsonError: false,
    endcode_tab_titles: [
      { id: 1, name: '終了コード一覧' },
      { id: 2, name: '終了コード(JSON)' }
    ],
    endcode_list: [],
    endcode_list_renew: [],
    title: 'パッケージ詳細',
    package_info: {},
    package_info_base_name: '', // 削除時、
    package_info_initialize: { // API実装後もこの初期化変数は残す
      // ********** DB項目と共通の項目
      pkg_id: '',
      pkg_name: '',
      overview: '',
      detail: '',
      task: '',
      status: '',
      registration_datetime: '',
      creator_id: '',
      phs: '',
      phs_web_disp: '',
      status_detail: '',
      execend: '',
      execcmd: '',
      execparm: '',
      execendtime: '',
      endcode_json: '',
      exec: {
        command: '',
        arguments: '',
        execution_time_limit: ''
      },
      check: {
        check_task_command: '',
        check_task_arguments: '',
        estimated_execution_time: ''
      },
      trigger: {
        trigger: '',
        required_immediate_execution: '',
        start_boundary: '',
        end_boundary: '',
        interval: '',
        day_of_week: []
      },
      upload_file_name: '',
      divert_pkg_id: '',
      // ********** 画面処理上の都合で使用する項目
      trigger_display: '', // 起動条件画面表示
      interval_unit: '',
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      dayofweek_string: ''
    },
    flagInterval: true, //      間隔を表示する時、true
    flagDayofweek: true, //     指定する曜日を表示する時、true
    flagStartboundary: true, // 開始日時を表示する時、true
    flagEndboundary: true, //   有効期限日時を表示する時、true
    parm_info_for_device_list: {
      pkgid: ''
    },
    parm_info_for_endcode_list: {
      gamen_type: 'Detail'
    },
    strTransActionCaption: '', // '転送開始 /中断    /再開を処理ステータスに応じて切り替える'
    strTransActionParm: '', //    'start',   'stop', 'resume'
    flagTransActionDisable: false, //       true:「転送開始」ボタン無効化           false:「転送開始」ボタン有効化
    flagMakeReusePackageDisable: false, //  true:「流用パッケージ作成」ボタン無効化 false:「流用パッケージ作成」ボタン有効化
    flagMakeDeletePackageDisable: false, // true:「削除パッケージ作成」ボタン無効化 false:「削除パッケージ作成」ボタン有効化
    isLoading: true
  }),
  watch: {
    // *************************************
    // パッケージ一覧の配列変数更新時
    // *************************************
    package_info: {
      handler: function () {
        // console.log('this.package_info.phs=' + this.package_info.phs)
        // ********** 「流用パッケージ作成」、「削除パッケージ作成」ボタン有効化、無効化の設定
        this.flagMakeReusePackageDisable = false
        this.flagMakeDeletePackageDisable = false
        if (this.package_info.task === 'タスク削除') {
          this.flagMakeReusePackageDisable = true
          this.flagMakeDeletePackageDisable = true
        }
        // ********** 「転送開始」ボタンの表示、非表示、キャプション、転送操作parmの切り替え
        this.strTransActionCaption = '転送開始'
        this.flagTransActionDisable = false
        // *** 転送処理ボタンの文字、APIのparm設定
        // SP5での実装可能仕様上 中断処理できない為、「転送中止」「転送再開」の処理条件は無い
        // PHS-20(パッケージ作成完了)
        // PHS-52(パッケージ転送失敗)の時
        if ((this.package_info.phs === 'PHS-20') ||
            (this.package_info.phs === 'PHS-52')) {
          this.strTransActionParm = 'start'
          this.flagTransActionDisable = false
        } else {
          // その他ケース
          // PHS-11(パッケージ作成中)               (現運用上発生しない)
          // PHS-30(パッケージ転送開始待ち)
          // PHS-40(パッケージ転送中)
          // PHS-51(パッケージ転送成功)
          // PHS-61(パッケージ転送中（中断処理中）) (現運用上発生しない)
          // PHS-62(パッケージ転送中断済み)         (現運用上発生しない)
          // PHS-70(パッケージ削除済み)
          // PHS-80(パッケージ却下済み)             (現運用上発生しない)
          // 以上の場合はボタンを表示しない
          this.strTransActionParm = ''
          this.flagTransActionDisable = true
        }
        // *** パッケージ全体ステータス
        var funcRet = getPhsInfo(this.package_info.phs)
        // 定義の存在するphsか?
        if (funcRet.flagExist) {
          this.package_info.phs_web_disp = funcRet.phsInfo.action_status
          // [削除パッケージ作成]ボタンを有効と判定していた時
          if (this.flagMakeDeletePackageDisable === false) {
            // 処理ステータスに応じた、[削除パッケージ作成]ボタン有効無効判定で無効すると定義されているか?
            if (funcRet.phsInfo.del_btn_disable === true) {
              this.flagMakeDeletePackageDisable = true
            }
          }
        } else {
          // 想定外のphsの場合は、処理ステータスにphsの値を表示、処理中には表示しないとする
          this.package_info.phs_web_disp = this.package_info.phs
        }
        // console.log('PackageDetail.vue watch:package_info')
        // ***
        if (!(this.package_info.trigger.day_of_week === '')) {
          this.package_info.trigger.day_of_week.forEach(dayOfWeekRow => {
            if (dayOfWeekRow === 'sunday') {
              this.package_info.sun = true
            } else if ((dayOfWeekRow === 'monday')) {
              this.package_info.mon = true
            } else if ((dayOfWeekRow === 'tuesday')) {
              this.package_info.tue = true
            } else if ((dayOfWeekRow === 'wednesday')) {
              this.package_info.wed = true
            } else if ((dayOfWeekRow === 'thursday')) {
              this.package_info.thu = true
            } else if ((dayOfWeekRow === 'friday')) {
              this.package_info.fri = true
            } else if ((dayOfWeekRow === 'saturday')) {
              this.package_info.sat = true
            }
          })
        }
        this.package_info.dayofweek_string = ''
        if (this.package_info.sun) {
          this.package_info.dayofweek_string += '(日) '
        }
        if (this.package_info.mon) {
          this.package_info.dayofweek_string += '(月) '
        }
        if (this.package_info.tue) {
          this.package_info.dayofweek_string += '(火) '
        }
        if (this.package_info.wed) {
          this.package_info.dayofweek_string += '(水) '
        }
        if (this.package_info.thu) {
          this.package_info.dayofweek_string += '(木) '
        }
        if (this.package_info.fri) {
          this.package_info.dayofweek_string += '(金) '
        }
        if (this.package_info.sat) {
          this.package_info.dayofweek_string += '(土) '
        }
        // ***
        const trigger = this.package_info.trigger.trigger
        // console.log('trigger=<' + trigger + '>')
        if (trigger === 'one_time') {
          this.package_info.trigger_display = '１回'
          this.flagInterval = false //                  (非表示) 間隔
          this.package_info.interval_unit = '' //       (非表示) 間隔の単位
          this.flagDayofweek = false //                 (非表示) 指定する曜日
          this.flagStartboundary = true //              開始日時（１回限り）
          this.flagEndboundary = true //                有効期限日時（１回限り）
        } else if (trigger === 'every_day') {
          this.package_info.trigger_display = '毎日'
          this.flagInterval = true //                   間隔（日）
          this.package_info.interval_unit = '（日）' // 間隔の単位
          this.flagDayofweek = false //                 (非表示) 指定する曜日
          this.flagStartboundary = true //              開始日時（毎日）
          this.flagEndboundary = true //                有効期限日時（毎日）
        } else if (trigger === 'every_week') {
          this.package_info.trigger_display = '毎週'
          this.flagInterval = true //                   間隔（週）
          this.package_info.interval_unit = '（週）' // 間隔の単位
          this.flagDayofweek = true //                  指定する曜日
          this.flagStartboundary = true //              開始日時（毎週）
          this.flagEndboundary = true //                有効期限日時（毎週）
        } else if (trigger === 'immediately') {
          this.package_info.trigger_display = '即時実行'
          this.flagInterval = false //                  (非表示) 間隔
          this.package_info.interval_unit = '' //       (非表示) 間隔の単位
          this.flagDayofweek = false //                 (非表示) 指定する曜日
          this.flagStartboundary = false //             (非表示) 開始日時（１回限り）
          this.flagEndboundary = true //                有効期限日時
        } else {
          this.package_info.trigger_display = trigger // 予期しないtrigger(起動条件)の場合はそのまま表示
          this.flagInterval = true //                   間隔
          this.package_info.interval_unit = '' //       間隔の単位
          this.flagDayofweek = true //                  指定する曜日
          this.flagStartboundary = true //              開始日時
          this.flagEndboundary = true //                有効期限日時
        }
      },
      deep: true
    }
  },
  methods: {
    // ***************************
    // IDクリック時
    // ***************************
    package_id_click_: function () {
      // console.log('PackageDetail.vue package_id_click_: ★将来パッケージファイルのダウンロードを入れる。')
    },
    // *********************************************************
    // 終了コードJSONテキストを終了コードの一覧用配列変数に変換
    // *********************************************************
    convertEndcodeListArray: function () {
      // console.log('PackageDetail.vue convertEndcodeListArray:')
      // console.log('this.package_info.task_exit_code_file')
      // console.log(this.package_info.task_exit_code_file)
      let endcodeJsonObj = {}
      try {
        endcodeJsonObj = JSON.parse(this.package_info.task_exit_code_file)
      } catch (err) {
        console.log(this.package_info.task_exit_code_file)
        console.log('JSON.parseエラー\nerr=' + err)
        // console.log(err)
        alert('取得したパッケージの終了コードJSONテキストが不正です。\n終了コード一覧の表示はできません。\n' + err)
        this.jsonError = true
        return
      }
      // ********** 終了コードJSONオブジェクト->終了コード一覧用配列化
      // console.log('endcodeJsonObj')
      // console.log(endcodeJsonObj)
      this.endcode_list_renew = convertEndcodeJsonArray(endcodeJsonObj)
      // console.log('this.endcode_list_renew')
      // console.log(this.endcode_list_renew)
      // ********** 子画面のJSON一覧配列変数に送信
      if (this.endcode_list_renew === []) {
        this.jsonError = false
        alert('取得したパッケージの終了コードJSONテキストが不正の為、^n終了コード一覧を更新できません。')
      } else {
        this.$refs.EndCodeList.renewEndcodeInfo(this.endcode_list_renew)
      }
    },
    // *******************************
    // 終了コード一覧更新受信時処理
    // *******************************
    receiveEndcodeTable: function (value) {
      // console.log('receiveEndcodeTable')
      this.endcode_list = value
      // console.log(this.endcode_list)
    },
    // *****************************
    // [戻る]ボタンの処理
    // *****************************
    back_: function () {
      // console.log('PackageDetail.vue methods: back_')
      this.$router.push({ name: 'Package', params: {} }) // パッケージ一覧へ遷移
    },
    // *****************************
    // [パッケージ削除]ボタンの処理
    // *****************************
    delete_package_: function () {
      // console.log('PackageDetail.vue methods: delete_')
      if (!confirm('パッケージを削除しますか？')) {
        // console.log('canceled.')
        return
      }
      this.delRestApi() // パッケージ情報の削除処理
    },
    // *********************************
    // [流用パッケージ作成]ボタンの処理
    // *********************************
    add_reuse_package_: function () {
      // console.log('PackageDetail.vue methods: add_reuse_package_')
      this.$router.push({ name: 'PackageEdit', params: { id: this.package_info.pkg_id, actionType: 'reuse' } }) // 流用パッケージ作成画面へ遷移
    },
    // *********************************
    // [削除パッケージ作成]ボタンの処理
    // *********************************
    add_delete_package_: function () {
      // console.log('PackageDetail.vue methods: add_delete_package_')
      this.$router.push({ name: 'PackageEdit', params: { id: this.package_info.pkg_id, actionType: 'delete' } }) // 削除パッケージ作成画面へ遷移
    },
    // ***********************************************
    // [転送開始]、[転送中止]、[転送再開]ボタンの処理
    // ***********************************************
    package_trans_action_: function () {
      // console.log('PackageDetail.vue methods: package_trans_action_')
      if (!confirm('パッケージを' + this.strTransActionCaption + 'しますか？')) {
        // console.log('canceled.')
        return
      }
      this.postRestApi() // パッケージ転送、中止、再開処理
    },
    // ***********************
    // 終了コードTABクリック時
    // ***********************
    tab_click_: function () {
      // console.log('PackageDetail.vue tab_click_:')
      // ********** 親画面のJSONテキスト枠に終了コード一覧から生成したテキストを生成
      this.package_info.task_exit_code_file = makeEndcodeJsonFormatText(this.endcode_list_renew)
    },
    // *************************
    // パッケージ情報の取得処理
    // *************************
    getRestApi: function () {
      axios.get('/packages/' + this.parm_info_for_device_list.pkgid, // *** 詳細で表示対象のIDで取得
        {
          params: { org: this.$store.getters.user.orgid },
          headers: { Authorization: this.$store.getters.user.token }
        }
      ).then((res) => {
        // console.log('PackageDetail.vue getRestApi: then 〇success')
        // console.log(res.data)
        this.package_info = res.data
        // パッケージの種別が"タスク削除"か?
        if (this.package_info.task === 'タスク削除') {
          axios.get('/packages/' + this.package_info.divert_pkg_id, // *** 削除時、Baseの名称取得目的でBaseのIDで取得
            {
              params: { org: this.$store.getters.user.orgid },
              headers: { Authorization: this.$store.getters.user.token }
            }
          ).then((res2) => {
            // console.log('PackageDetail.vue getRestApi get2回目: then 〇success')
            // console.log(res2.data)
            this.package_info_base_name = res2.data.pkg_name
            this.isLoading = false
          }).catch(() => {
            // console.log('PackageDetail.vue getRestApi get2回目: ★catch error=' + error)
            this.package_info_base_name = '-'
            this.isLoading = false
            // alert('ベースパッケージ情報取得に失敗\n通信エラーが起きました。リロードするか時間をおいてアクセスしてください。\n' + error)
            return false
          })
        } else {
          // 終了コードJSONテキストを終了コード一覧用の配列に変換
          this.convertEndcodeListArray()
          // 終了コードJSONテキスト、が不正の時は、終了コードJSONのタブを表示
          if (this.jsonError) {
            this.tabidx = 1
          }
        }
        this.isLoading = false
      }).catch((error) => {
        console.log('PackageDetail.vue getRestApi: ★catch error=' + error)
        this.isLoading = false
        alert('パッケージ情報取得に失敗\n通信エラーが起きました。リロードするか時間をおいてアクセスしてください。\n' + error)
        this.$router.push({ name: 'Package', params: {} }) // パッケージ一覧へ遷移
        return false
      })
    },
    // *******************************
    // パッケージ転送、中止、再開処理
    // *******************************
    postRestApi: function () {
      this.flagTransActionDisable = true
      // console.log('axios.post /packages/' + this.parm_info_for_device_list.pkgid + ' action=' + this.strTransActionParm)
      axios.post('/packages/' + this.parm_info_for_device_list.pkgid,
        {},
        {
          params: { action: this.strTransActionParm },
          headers: { Authorization: this.$store.getters.user.token }
        }
      ).then((res) => {
        // console.log('PackageDetail.vue postRestApi: then 〇success')
        // console.log(res.data)
        this.package_info = res.data
        this.flagTransActionDisable = false
        alert('パッケージ' + this.strTransActionCaption + 'に成功しました。')
      }).catch((error) => {
        console.log('PackageDetail.vue postRestApi: ★catch error=' + error)
        this.flagTransActionDisable = false
        alert('パッケージ転送、中止、再開処理に失敗\n通信エラーが起きました。リロードするか時間をおいてアクセスしてください。\n' + error)
        return false
      })
    },
    // *************************
    // パッケージ情報の削除処理
    // *************************
    delRestApi: function () {
      // console.log('PackageDetail.vue methods: delRestApi:')
      axios.delete('/packages/' + this.package_info.pkg_id,
        { headers: { Authorization: this.$store.getters.user.token } }
      ).then((res) => {
        // パッケージ削除が成功した時
        // console.log('PackageDetail.vue delRestApi: then 〇success')
        this.isLoading = false
        alert('ID=' + this.package_info.pkg_id + 'を削除しました。')
        this.$router.push({ name: 'Package', params: {} }) // 削除成功時、パッケージ一覧へ遷移
      }).catch((error) => {
        // パッケージ削除が失敗した時
        // console.log('PackageDetail.vue delRestApi: ★catch')
        this.isLoading = false
        alert('パッケージ削除に失敗\n通信エラーが起きました。リロードするか時間をおいてアクセスしてください。\n' + error)
      })
    }
  },
  // *******************************
  // モジュール生成時
  // *******************************
  created: function () {
    // console.log('PackageDetail.vue created:')
    this.package_info = this.package_info_initialize // 必要なObjectの初期化を明示的に行う
    this.parm_info_for_device_list.pkgid = this.$route.params.id
  },
  // *******************************
  // 画面マウント時
  // *******************************
  mounted: function () {
    // console.log('PackageDetail.vue mounted:')
    this.getRestApi()
  }
}
</script>
<style scoped>
#package_table1 table{ width:100%;}
#package_table1 th{ min-width:20%; max-width:100px; background-color:#dcdff1; border: 1px #808080 solid; user-select: auto;}
#package_table1 td{ min-width:300px; border: 1px #808080 solid; text-align:left;}
#package_table2 table{ width:100%;}
#package_table2 th{ min-width:20%; max-width:100px; background-color:#dcdff1; border: 1px #808080 solid; user-select: auto;}
#package_table2 td{ min-width:300px; border: 1px #808080 solid; text-align:left;}
#package_table3 table{ width:100%; }
#package_table3 th{ min-width:200px; max-width:200px; background-color:#dcdff1; border: 1px #808080 solid; user-select: auto;}
#package_table3 td{ min-width:100px; border: 1px #808080 solid; text-align:left;}
#id_device_list { min-width:750px; }
ul{
    margin: 0;
    padding: 0;
  }
  li{
    list-style: none;
  }
  .tabs {
    overflow: hidden;
  }
  .tabs li,
  .tabs label {
    float: left;
    padding: 5px 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    transition: .3s;
  }
  .tabs li:not(:first-child),
  .tabs label:not(:first-of-type) {
    border-left: none;
  }
  .tabs li.active,
  .tabs :checked + label {
    background-color: #000;
    border-color: #000;
    color: #fff;
    cursor: auto;
  }
  .contents{
    overflow: hidden;
    margin-top: -1px;
  }
  .contents li {
    padding: 10px;
    border: 1px solid #ccc;
  }
</style>
