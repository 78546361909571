import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
  CognitoRefreshToken
} from 'amazon-cognito-identity-js'
import { Config, CognitoIdentityCredentials } from 'aws-sdk'

export default class Cognito {
  configure (config) {
    // console.log('cognito.configure')
    if (config.userPool) {
      this.userPool = config.userPool
    } else {
      this.userPool = new CognitoUserPool({
        UserPoolId: config.UserPoolId,
        ClientId: config.ClientId,
        Storage: sessionStorage
      })
    }
    Config.region = config.region
    Config.credentials = new CognitoIdentityCredentials({
      IdentityPoolId: config.IdentityPoolId
    })
    this.options = config
    this.currentUser = false
  }

  static install = (Vue, options) => {
    Object.defineProperty(Vue.prototype, '$cognito', {
      get () { return this.$root._cognito }
    })

    Vue.mixin({
      beforeCreate () {
        if (this.$options.cognito) {
          this._cognito = this.$options.cognito
          this._cognito.configure(options)
        }
      }
    })
  }

  /**
   * TODO:調査時のみ。削除予定。
   * username, passwordでサインアップ
   * username = emailとしてUserAttributeにも登録
   */
  signUp (username, email, password) {
    const dataEmail = { Name: 'email', Value: email }
    const attributeList = []
    attributeList.push(new CognitoUserAttribute(dataEmail))
    return new Promise((resolve, reject) => {
      this.userPool.signUp(username, password, attributeList, null, (err, result) => {
        if (err) {
          reject(err)
        } else {
          resolve(result)
        }
      })
    })
  }

  /**
   * TODO:調査時のみ。削除予定。
   * 確認コードからユーザーを有効化する
   */
  confirmation (username, confirmationCode) {
    const userData = { Username: username, Pool: this.userPool, Storage: sessionStorage }
    const cognitoUser = new CognitoUser(userData)
    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
        if (err) {
          reject(err)
        } else {
          resolve(result)
        }
      })
    })
  }

  /**
   * username, passwordでログイン
   */
  login (username, password, newpass) {
    const userData = { Username: username, Pool: this.userPool, Storage: sessionStorage }
    const cognitoUser = new CognitoUser(userData)
    const authenticationData = { Username: username, Password: password }
    const authenticationDetails = new AuthenticationDetails(authenticationData)
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          sessionStorage.setItem('exp', result.accessToken.payload.exp)
          // console.log(result)
          resolve(result)
        },
        onFailure: (err) => {
          // console.log(err)
          reject(err)
        },
        newPasswordRequired (userAttributes, requiredAttributes) {
          // console.log('newPasswordRequired()')
          if (newpass === null) {
            const err = { code: 'newPasswordRequired', message: 'New password required.' }
            reject(err)
          } else {
            cognitoUser.completeNewPasswordChallenge(newpass, {}, {
              onFailure: (err) => {
                // console.log('Failuer:' + err)
                const error = { code: 'newPasswordError', message: err }
                reject(error)
              },
              onSuccess: (result) => {
                // console.log('Success:' + result)
                resolve(result)
              }
            })
          }
        }
      })
    })
  }

  /**
   * ログアウト
   */
  logout () {
    if (this.userPool.getCurrentUser()) {
      sessionStorage.removeItem('exp')
      this.userPool.getCurrentUser().signOut()
    }
  }

  /**
   * ログインしているかの判定
   */
  isAuthenticated () {
    this.currentUser = this.userPool.getCurrentUser()
    return new Promise((resolve, reject) => {
      if (this.currentUser === null) { reject(this.currentUser) }
      this.currentUser.getSession((err, session) => {
        if (err) {
          reject(err)
        } else {
          if (!session.isValid()) {
            reject(session)
          } else {
            resolve(session)
          }
        }
      })
    })
  }

  isSettionTimeout () {
    var exp = sessionStorage.getItem('exp')
    var now = new Date().getTime()
    // console.log(exp + ' ' + now)

    if (exp === null) {
      console.log('not Auth')
      return true
    }
    if (now >= (exp * 1000)) {
      // console.log('timeout')
      return true
    }
    return false
  }

  /**
   * 更新トークンを利用してIDトークンとアクセストークンを更新する。
   * 及びセッションタイムアウト用の'exp'を更新する。
   */
  refreshSession (inputSession) {
    this.currentUser = this.userPool.getCurrentUser()

    // トークン情報更新
    return new Promise((resolve, reject) => {
      if (this.currentUser === null) { reject(this.currentUser) }
      var token = inputSession.refreshToken.token

      // 更新トークンの設定
      var refreshToken = new CognitoRefreshToken({ RefreshToken: token })

      this.currentUser.refreshSession(refreshToken, (err, session) => {
        if (err) {
          reject(err)
        }
        if (session.isValid()) {
          sessionStorage.setItem('exp', session.accessToken.payload.exp)
          resolve(session)
        } else {
          reject(session)
        }
      })
    })
  }

  // ユーザ名取得
  getUserName () {
    this.currentUser = this.userPool.getCurrentUser()
    return this.currentUser.getUsername()
  }

  // 属性の取得
  getAttribute () {
    return new Promise((resolve, reject) => {
      this.currentUser.getUserAttributes((err, result) => {
        if (err) {
          reject(err)
        } else {
          resolve(result)
        }
      })
    })
  }

  // パスワードリセット
  // 認証コードと新パスワードを設定する。
  confirmPassword (username, verificationCode, password) {
    const userData = { Username: username, Pool: this.userPool, Storage: sessionStorage }
    const cognitoUser = new CognitoUser(userData)

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, password, {
        onSuccess: () => {
          console.log('Password confirmed!')
          resolve()
        },
        onFailure: (err) => {
          console.log('Password not confirmed!')
          // console.log(err)
          reject(err)
        }
      })
    })
  }
}
