<template>
  <v-app>
    <v-app-bar app>
      <h2>{{ title }}</h2>
      <v-spacer></v-spacer>
      <img src="@/assets/img/logo_nec.png" alt="NEC">
    </v-app-bar>
    <v-main>
      <loading v-show="isLoading"></loading>
      <div v-show="!isLoading">
        <template v-if="type === 1">
          <v-card width="400px" class="mx-auto mt-5">
            <v-card-title>
              <div class="headline">初回パスワード登録</div>
            </v-card-title>
            <v-card-text>
            <div>新しいパスワードを登録してください</div>
              <v-form>
                <v-text-field id="new_userid" prepend-icon="mdi-account-circle" label="ユーザID" v-model.trim="username" />
                <v-text-field id="new_valicode"
                  v-bind:type="showPassword ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  label="仮パスワード"
                  @click:append="showPassword = !showPassword"
                  v-model.trim="password"
                />
                <v-text-field id="new_password"
                  v-bind:type="showNewPassword ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  v-bind:append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  label="新しいパスワード"
                  @click:append="showNewPassword = !showNewPassword"
                  v-model.trim="newpassword"
                />
                <v-alert v-model="alert" :type="alerttype" dismissible> {{ alerttext }} </v-alert>
                <v-card-actions v-if="!alert">
                  <v-btn id="new_btn"
                  :loading="btnloading"
                  :disabled="btnloading"
                  class="info"
                  @click="submit1"
                >パスワードを登録</v-btn>
                </v-card-actions>
                <a href="#" v-on:click="login">ログイン</a>
              </v-form>
            </v-card-text>
          </v-card>
        </template>
        <template  v-else-if="type === 2">
          <v-card width="400px" class="mx-auto mt-5">
            <v-card-title>
              <div class="headline">パスワードリセット</div>
            </v-card-title>
            <v-card-text>
              <div> 新しいパスワードを登録してください。</div>
              <v-form>
                <v-text-field id="reset_userid" prepend-icon="mdi-account-circle" label="ユーザID" v-model.trim="username" />
                <v-text-field id="reset_valicode" prepend-icon="mdi-shield-check" label="検証コード" v-model.trim="password" />
                <v-text-field id="reset_password"
                  v-bind:type="showNewPassword ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  v-bind:append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  label="新しいパスワード"
                  @click:append="showNewPassword = !showNewPassword"
                  v-model.trim="newpassword" />
                <v-alert v-model="alert" :type="alerttype" dismissible>{{ alerttext }}</v-alert>
                <v-card-actions v-if="!alert">
                  <v-btn id="reset_btn"
                  :loading="btnloading"
                  :disabled="btnloading"
                  class="info"
                  @click="submit2"
                >パスワードを登録</v-btn>
                </v-card-actions>
                <a href="#" v-on:click="login">ログイン</a>
              </v-form>
            </v-card-text>
          </v-card>
        </template>
        <template v-else>
          <v-card width="400px" class="mx-auto mt-5">
            <v-card-title>
              <div class="headline">ログイン</div>
            </v-card-title>
            <v-card-text>
              <v-form>
                <v-text-field id="login_userid" prepend-icon="mdi-account-circle" label="ユーザID" v-model.trim="username" />
                <v-text-field id="login_password"
                  v-bind:type="showPassword ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  label="パスワード"
                  @click:append="showPassword = !showPassword"
                  v-model.trim="password"
                />
                <v-alert v-model="alert" :type="alerttype" dismissible>{{ alerttext }}</v-alert>
                <v-card-actions v-if="!alert">
                  <v-btn id="login_btn"
                    :loading="btnloading"
                    :disabled="btnloading"
                    class="info"
                    @click="submit"
                  >ログイン</v-btn>
                </v-card-actions>
                初回パスワード登録(招待メールを受け取った方)は<a href="#" v-on:click="newlogin">こちら</a>
                パスワードリセット(検証コードを受け取った方)は<a href="#" v-on:click="passreset">こちら</a>
              </v-form>
            </v-card-text>
          </v-card>
        </template>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Loading from '@/components/Loading'
import axios from 'axios'
import rmtlib from '@/plugins/class/rmtlib'

export default {
  name: 'Login',
  data () {
    return {
      showPassword: false,
      showNewPassword: false,
      username: '',
      password: '',
      newpassword: '',
      alert: false,
      alerttext: '',
      // v-alertのtype success、info、warning もしくは error
      alerttype: 'error',
      type: 0,
      btnloading: false,
      title: '',
      isLoading: false
    }
  },
  components: {
    Loading
  },
  created: function () {
    this.title = this.$store.getters.system.name // タイトルにシステム名を設定する
  },
  mounted () {
    //
  },
  watch: {
    // v-alertを閉じたときにはアラートを情報を初期化する。
    alert: {
      handler: function () {
        if (this.alert === false) {
          this.alerttext = ''
          this.alerttype = 'error'
        }
      }
    },
    // フォームを移動時に入力情報やアラートは全てクリアする
    type: {
      handler: function () {
        this.alert = false
        this.clear()
      }
    }
  },
  methods: {
    clear () {
      this.username = ''
      this.password = ''
      this.newpassword = ''
      this.showPassword = false
      this.showNewPassword = false
      this.btnloading = false
    },
    submit () {
      this.btnloading = true
      this.$cognito.login(this.username, this.password, null)
        .then(result => {
          // console.log('login success')
          const userid = this.username
          this.clear()
          this.login_proc(userid, result.idToken.jwtToken)
        })
        .catch(err => {
          this.clear()
          console.log('login error:' + err.code + ' ' + err.message)

          if (err.code === 'newPasswordRequired') {
            this.alerttype = 'info'
            this.alerttext = '仮パスワードになっています。　　初回パスワード登録のフォームから新しいパスワードを登録してください。'
            this.alert = true
          } else {
            this.alerttype = 'error'
            this.alerttext = '正しいユーザIDとパスワードを入力してください。'
            this.alert = true
          }
        })
    },
    // for 初回パスワード登録
    // username=ユーザID、password=仮パスワード、newpassword=新しいパスワード
    submit1 () {
      this.btnloading = true

      this.$cognito.login(this.username, this.password, this.newpassword)
        .then(result => {
          this.clear()
          // console.log('login success')
          this.alerttype = 'info'
          this.alerttext = '新しいパスワードが設定されました。ログインフォームよりログインしてください。5秒後に自動でログインに移動します。'
          this.alert = true
          setTimeout(() => (this.type = 0), 5000)
        })
        .catch(err => {
          this.clear()
          console.log('login error:' + err.code + ' ' + err.message)
          if (err.code === 'newPasswordError') {
            this.alerttype = 'error'
            this.alerttext = '新しいパスワードの強度が不足しています。'
          } else {
            this.alerttype = 'error'
            this.alerttext = '正しいユーザIDとパスワードを入力してください。'
          }
          this.alert = true
        })
    },
    // for パスワードリセット
    // username=ユーザID、password=検証コード、newpassword=新しいパスワード
    submit2 () {
      this.btnloading = true
      this.$cognito.confirmPassword(this.username, this.password, this.newpassword)
        .then(result => {
          this.clear()
          this.alerttype = 'info'
          this.alerttext = '新しいパスワードが設定されました。ログインフォームよりログインしてください。5秒後に自動でログインに移動します。'
          this.alert = true
          setTimeout(() => (this.type = 0), 5000)
        })
        .catch(err => {
          this.clear()
          console.log('login error:' + err.code + ' ' + err.message)
          if (err.code === 'CodeMismatchException') {
            this.alerttype = 'error'
            this.alerttext = '検証コードが正しくありません。'
          } else if (err.code === 'ExpiredCodeException') {
            this.alerttype = 'error'
            this.alerttext = '検証コードの有効期限が切れました。'
          } else {
            this.alerttype = 'error'
            this.alerttext = '正しいユーザIDとパスワードを入力してください。'
          }
          this.alert = true
        })
    },
    newlogin () {
      console.log('newlogin')
      this.type = 1
    },
    passreset () {
      console.log('passreset')
      this.type = 2
    },
    login () {
      console.log('login')
      this.type = 0
    },
    set_orgdata: function (user) {
      // console.log('set_orgdata')
      axios.get('/organizations/' + user.orgid,
        {
          headers: { Authorization: user.token }
        }
      ).then((res) => {
        // console.log(res.data)
        user.org = res.data.organization_name
        this.$store.commit('setUser', user)
        this.isLoading = false
        if (rmtlib.isWsusOrg(user.orgid)) {
          this.$router.replace('/Wsus')
        } else {
          this.$router.replace('/')
        }
      }).catch((error) => {
        console.log(error)
        this.isLoading = false
        confirm('ログイン処理に失敗しました。ログイン画面に戻ります。')
        this.$store.commit('reset')
        this.$cognito.logout()
        this.$router.replace('/login')
      })
    },
    set_userdata: function (user) {
      // console.log('set_userdata')
      axios.get('/users/me',
        {
          headers: { Authorization: user.token }
        }
      ).then((res) => {
        // console.log(res.data)
        var role = [false, false, false]
        // if (res.data.superuser === true) { role[0] = true }
        if (res.data.admin === true) { role[1] = true }
        if (res.data.operator === true) { role[2] = true }
        user.role = role
        user.name = res.data.name
        this.set_orgdata(user)
      }).catch((error) => {
        console.log(error)
        this.isLoading = false
        confirm('ログイン処理に失敗しました。ログイン画面に戻ります。')
        this.$store.commit('reset')
        this.$cognito.logout()
        this.$router.replace('/login')
      })
    },
    login_proc: function (userid, idToken) {
      // console.log('login_proc=' + userid)
      var user = {}
      user.token = idToken
      user.userid = userid
      user.role = [false, false, false]
      // スーパーユーザの判定
      if (userid.slice(0, 2) === '80') {
        user.role[0] = true
        user.name = 'スーパーユーザ'
      }
      if (user.role[0] !== true) {
        // スーパーユーザ以外は、名前や組織情報をLoginで取得する。
        user.orgid = userid.slice(0, 6)
        this.isLoading = true
        this.set_userdata(user)
      } else {
        // console.log('superuser')
        // TODO：SUはDBに情報が入っていない場合があるため、まずは見ないようにする。
        // 組織情報は組織選択画面で取得だが、(メニューの切り替えで見ているので)自組織IDで仮登録する。
        user.orgid = userid.slice(0, 6)
        this.$store.commit('setUser', user)
        this.$router.replace('/OrganizationSelect')
      }
    }
  }
}
</script>
